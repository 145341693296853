import DashboardStatCard from "components/Common/BookingReservation/DashboardStatCard";
import ActionButtonContainer from "components/Common/ui/ActionButtonContainer";
import Container from "components/Common/ui/Container";
import IconButton from "components/Common/ui/IconButton";
import SingleSelect from "components/Common/ui/SingleSelect";
import DataTable from "components/Common/ui/Table";
import TableContainer from "components/Common/ui/TableContainer";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  useGetParentsQuery,
  useCreateParentMutation,
  useDashboardStatQuery,
  usePetParentStatQuery,
} from "store/features/pet_parents/petParentApiSlice";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Modal from "components/Common/ui/Modal";
import Input from "components/Common/ui/Input";
import { useFormik } from "formik";
import Button from "components/Common/ui/Button";
import toast from "react-hot-toast";
import { setmenu } from "store/StateSlice/layoutSlice";
import { useDispatch } from "react-redux";
const initialState = {
  state: false,
  edit_id: null,
  data: {
    first_name: "",
    last_name: "",
    email: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    phone_emergency: "",
  },
};

const validationSchema = {
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().optional(),
  email: Yup.string().email().required("Email is required"),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  pincode: Yup.string().optional(),
  phone: Yup.string().required("Phone is required"),
  phone_emergency: Yup.string().optional(),
};

const PetManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setmenu("petparent"));
  }, []);
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(initialState);

  const { isLoading } = useGetParentsQuery();

  const { data: petParentData } = useSelector((state) => state?.petParent);
  const { data: satData, isLoading: statLoading } = usePetParentStatQuery();

  const [
    createParent,
    {
      isLoading: createParentLoading,
      isSuccess: parentCreateSuccess,
      isError: parentCreateError,
    },
  ] = useCreateParentMutation();

  useEffect(() => {
    if (parentCreateSuccess) {
      toast.success("Parent created successfully!");
      setModalState(initialState);
    }

    if (parentCreateError) {
      toast.error("Failed to create parent!");
    }
  }, [parentCreateSuccess, parentCreateError]);

  const { role, pet_write_access } = useSelector((state) => state.auth.user);

  const columns = () => [
    {
      accessor: (d) => d?.parentid,
      Header: "Parent ID",
    },
    {
      accessor: (row) => `${row?.first_name} ${row?.last_name}`,
      Header: "Name",
    },
    {
      accessor: (row) => row?.pets?.length || 0,
      Header: "Pets Associated",
    },
    {
      accessor: (row) => row?.state || "-",
      Header: "Location",
    },
    {
      accessor: "phone",
      Header: "Mobile Number",
    },
    {
      accessor: (row) => 0,
      Header: "No. of Bookings",
    },
    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton
            className={"disabled:opacity-50 disabled:cursor-not-allowed"}
            disabled={role === "PET_HANDLER" && !pet_write_access}
            onClick={() => navigate(`/pet-parent-management/profile/${d?.id}`)}
          >
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          {/* <IconButton
          //onClick={() => handleEdit(d?.id)}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          //onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
        </div>
      ),
      Header: "Action",
    },
  ];

  const { handleBlur, handleSubmit, handleChange, errors, touched, values } =
    useFormik({
      initialValues: modalState.data,
      enableReinitialize: true,
      validationSchema: Yup.object(validationSchema),
      onSubmit: async (values, action) => {
        if (modalState.edit_id) {
          // update
          //await updateParent({ id: modalState.edit_id, ...values });
        } else {
          // create
          await createParent(values);
        }
        action.resetForm();
      },
    });

  const parentModal = () => {
    const { edit_id, state } = modalState;

    return (
      <Modal state={state} handleState={() => setModalState(initialState)}>
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {edit_id ? "Update " : "Add "}pet parent
          </p>

          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="flex flex-row space-x-2">
              <Input
                id={"first_name"}
                name="first_name"
                label={"First Name"}
                placeholder="Enter first name"
                value={values?.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.first_name && touched?.first_name}
                errorText={errors?.first_name}
              />

              <Input
                id={"last_name"}
                name="last_name"
                label={"Last Name"}
                placeholder="Enter last name"
                value={values?.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.last_name && touched?.last_name}
                errorText={errors?.last_name}
              />
            </div>

            <Input
              id={"email"}
              name="email"
              label={"Email Address"}
              placeholder="Enter email address"
              type="email"
              value={values?.email}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.email && touched?.email}
              errorText={errors?.email}
            />

            <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
              <Input
                id={"city"}
                name="city"
                label={"City"}
                placeholder="City"
                value={values?.city}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.city && touched?.city}
                errorText={errors?.city}
              />

              <Input
                id={"state"}
                name="state"
                label={"State"}
                placeholder="State"
                value={values?.state}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.state && touched?.state}
                errorText={errors?.state}
              />

              <Input
                id={"pincode"}
                name="pincode"
                label={"Pin Code"}
                placeholder="Zip Code"
                value={values?.pincode}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.pincode && touched?.pincode}
                errorText={errors?.pincode}
              />
            </div>

            <div className="flex flex-row space-x-2">
              <Input
                id={"phone"}
                name="phone"
                label={"Mobile Number"}
                placeholder="Enter mobile number"
                value={values?.mobile_number}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.phone && touched?.phone}
                errorText={errors?.phone}
              />

              <Input
                id={"phone_emergency"}
                name="phone_emergency"
                label={"Emergency Number"}
                placeholder="Enter emergency number"
                value={values?.phone_emergency}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.phone_emergency && touched?.phone_emergency}
                errorText={errors?.phone_emergency}
              />
            </div>

            <div className="flex flex-col space-y-2">
              <Button type="submit">
                {createParentLoading
                  ? "Loading..."
                  : edit_id
                    ? "Update"
                    : "Add"}
              </Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() =>
                  setModalState((prev) => ({ ...prev, state: false }))
                }
              >
                Go Back
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  return (
    <Container title={"Pet Parent Management"}>
      <div className="grid grid-cols-3 gap-4 mb-8">
        <DashboardStatCard
          data={{ title: "Total Pet Parents", count: statLoading ? "loading..." : satData?.data?.totalRegisteredParents }}
        />
        <DashboardStatCard
          data={{ title: "Total Parents Associated with Pet", count: statLoading ? "loading..." : satData?.data?.totalRegisteredParentsWithPets }}
        />

      </div>
      {parentModal()}
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="flex flex-col space-y-16">
          <TableContainer
            title="Pet Parent details"
            actionButtons={
              <Button
                className={"disabled:opacity-50 disabled:cursor-not-allowed"}
                disabled={role === "PET_HANDLER" && !pet_write_access}
                onClick={() => setModalState({ ...initialState, state: true })}
              >
                Add
              </Button>
            }
          >
            <DataTable
              columns={columns()}
              data={petParentData}
              filterArea={
                <TableFilterContainer>
                  <SingleSelect
                    showLabel={false}
                    className="!bg-white"
                    label={"Type"}
                    options={[]}
                    id={"type"}
                    name="type"
                    onChange={(e) => { }}
                  />

                  <SingleSelect
                    showLabel={false}
                    className="!bg-white"
                    label={"Age"}
                    options={[]}
                    id={"age"}
                    name="age"
                    onChange={(e) => { }}
                  />
                </TableFilterContainer>
              }
            />
          </TableContainer>
        </div>
      )}
    </Container>
  );
};

export default PetManagement;
