import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import StatsCard from "../../components/Cards/Dashboard/StatsCard";
import StatsPercentage from "../../components/Cards/Dashboard/StatsPercentage";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { IoIosSearch } from "react-icons/io";
import AddaBooking from "../../components/Modal/AddaBooking";
import { Dateservices } from "../../helpers/Dashboard/DateServices";
import DateModal from "../../components/Modal/DateModal";
import Container from "components/Common/ui/Container";
import Modal from "components/Common/ui/Modal";
import { useFormik } from "formik";
import ColumnContainer from "components/Common/ui/ColumnContainer";
import ReactSelect from "react-select";
import Input from "components/Common/ui/Input";
import Button from "components/Common/ui/Button";
import "./calanderStyles.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useGetOneServiceQuery } from "store/features/auth/authApiSlice";
import { useGetParentsQuery } from "store/features/pet_parents/petParentApiSlice";
import {
  useCreateAppointmentMutation,
  useDashboardStatQuery,
  useFilterAppointmentQuery,
  useServiceProvidedAppointment2Query,
} from "store/features/appointment/appointmentApiSlice";
import toast from "react-hot-toast";
import ServiceCard from "../../components/Cards/Dashboard/ServiceCard";
import { setmenu } from "store/StateSlice/layoutSlice";
import { useDispatch } from "react-redux";
const initialState = {
  state: false,
  data: {
    mobile_number: "",
    pet_type: "",
    service_type: "",
    email: "",
    start_time: "",
    end_time: "",
    date: "",
    pet_id: "",
    parent_id: "",
    cost: 0,
    task_status: "UPCOMING",
  },
};

const AppointmentCard = ({ title, amount, parent, pet, status }) => {
  return (
    <div className="h-24 p-4 bg-white rounded-lg shadow-md flex flex-col justify-start items-start gap-2">
      <div className="flex justify-between items-center w-full">
        <div>
          <div className="text-black text-xl font-semibold">{title}</div>
          <div className="flex items-center gap-2 text-gray-500 text-[10px]">
            <div>
              Pet Parent - <span className="font-bold">{parent}</span>
            </div>
            <div className="w-1 h-1 bg-gray-300 rounded-full mx-2"></div>
            <div>
              Pet - <span className="font-bold">{pet}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1">
          ₹<div className="text-neutral-800 text-sm font-bold">{amount}</div>
        </div>
      </div>
      <div className="w-20 h-5 flex justify-center items-center relative">
        <div className="w-full h-full absolute opacity-20 bg-yellow-500 rounded-md"></div>
        <div className="text-center text-yellow-500 text-xs p-1 font-bold">
          {status}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setmenu("dashboard"));
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [currentPets, setCurrentPets] = useState([]);
  const [appointments, setAppointments] = useState([]);

  // useGetOneServiceQuery();
  // useGetParentsQuery();

  const [createAppointment, { isLoading, isError, isSuccess }] =
    useCreateAppointmentMutation();
  const { data: dashboardStat, isLoading: statLoading } = useDashboardStatQuery();

  const {
    isLoading: appointmentLoading,
    data: appointmentData,
    isSuccess: sucessFetchingAppointment,
  } = useFilterAppointmentQuery();

  const { isLoading: serviceAppointmentLoading, data: serviceAppointmentData } =
    useServiceProvidedAppointment2Query();
  const { service_info } = useSelector((state) => state?.auth);
  const { data: petParentData } = useSelector((state) => state?.petParent);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Appointment booked successfully!");
      setModalState(initialState);
    }

    if (isError) {
      toast.error("Failed to book appointment!");
    }
  }, [isSuccess, isError]);

  const [modalState, setModalState] = useState(initialState);
  const [showAppointmentModal, setShowAppointmentModal] = useState({
    date: "",
    state: false,
  });

  useEffect(() => {
    if (sucessFetchingAppointment) {
      const allDates = [...new Set(appointmentData?.data?.map((e) => e?.date))];
      const final_data = [];
      allDates?.forEach((date) => {
        const currDateAppointment = appointmentData?.data?.filter(
          (ele) => ele?.date === date,
        );
        const conditionCount =
          currDateAppointment?.length >= 3
            ? 2
            : currDateAppointment?.length - 1;
        for (let i = 0; i <= conditionCount; i++) {
          final_data.push({
            title: currDateAppointment[i]?.pet?.name,
            date: date,
            backgroundColor: "#FFCD92",
            borderColor: "#FFCD92",
            textColor: "#000000",
          });
        }
        final_data.push({
          title: "Show all",
          date: date,
          backgroundColor: "#91FF91",
          borderColor: "#91FF91",
          textColor: "#000000",
        });
      });

      setAppointments(final_data);
    }
  }, [sucessFetchingAppointment, appointmentData]);

  const handleDateClick = (arg) => {
    setModalState((prev) => ({
      ...prev,
      data: { ...prev.data, date: arg.dateStr },
      state: true,
    }));
    //setIsDateModalOpen(true);
    setCurrentDate(arg.dateStr);
    setValues((prev) => ({ ...prev, date: arg.dateStr }));
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsDateModalOpen(false);
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: modalState.data,
    onSubmit: async (values) => {
      const appointment_cost = values?.service_provided?.reduce((acc, e) => {
        const fCost = calculateAppointmentCost(e?.service_provided_id);
        acc += fCost;
        return acc;
      }, 0);
      await createAppointment({ ...values, cost: appointment_cost }).unwrap();
    },
  });

  const handleFilterPets = (e) => {
    const { value } = e;
    const pets = petParentData
      ?.find((e) => e?.id === value)
      ?.pets?.map((p) => ({ value: p?.id, label: p?.name }));
    setCurrentPets(pets);
  };

  const calculateAppointmentCost = (service_provided_id) => {
    return service_info?.service_provideds?.find(
      (e) => e?.id === service_provided_id,
    )?.service_cost;
  };

  const addAppointment = () => {
    const { state } = modalState;

    const service_provided = service_info?.service_provideds?.map((e) => ({
      value: e?.id,
      label: e?.service_name,
    }));

    const petParents = petParentData?.map((e) => ({
      value: e?.id,
      label: `${e?.first_name} ${e?.last_name}`,
    }));

    return (
      <Modal
        state={state}
        handleState={() => setModalState(initialState)}
        width={750}
      >
        <ColumnContainer xSpace={0}>
          <p className="font-poppins font-semibold text-[28px] capitalize">
            Add Appointment - {currentDate}
          </p>
          <form onSubmit={handleSubmit}>
            <ColumnContainer xSpace={0}>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                <span className="flex flex-col space-y-1 w-full">
                  <p className="font-poppins text-base font-semibold text-[#1C2135]">
                    Pet Parent
                  </p>
                  <ReactSelect
                    options={petParents ? petParents : []}
                    onChange={(e) => {
                      handleFilterPets(e);
                      setValues((prev) => ({ ...prev, parent_id: e?.value }));
                    }}
                  />
                </span>

                <span className="flex flex-col space-y-1 w-full">
                  <p className="font-poppins text-base font-semibold text-[#1C2135]">
                    Pet
                  </p>
                  <ReactSelect
                    options={currentPets}
                    isDisabled={!currentPets?.length}
                    onChange={(e) => {
                      setValues((prev) => ({ ...prev, pet_id: e?.value }));
                    }}
                  />
                </span>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                <Input
                  id={"mobile_number"}
                  name="mobile_number"
                  label={"Mobile Number"}
                  placeholder="Enter Mobile Number"
                  value={values?.mobile_number}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.mobile_number && touched?.mobile_number}
                  errorText={errors?.mobile_number}
                />

                <span className="flex flex-col space-y-1 w-full mt-1">
                  <p className="font-poppins text-base font-semibold text-[#1C2135]">
                    Pet Type
                  </p>
                  <ReactSelect
                    options={[
                      { value: "Dog", label: "Dog" },
                      { value: "Cat", label: "Cat" },
                    ]}
                    onChange={(e) =>
                      setValues((prev) => ({ ...prev, pet_type: e?.value }))
                    }
                  />
                </span>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                <span className="flex flex-col space-y-1 w-full mt-1">
                  <p className="font-poppins text-base font-semibold text-[#1C2135]">
                    Service Type
                  </p>
                  <ReactSelect
                    options={[{ value: "Growming", label: "Growming" }]}
                    onChange={(e) =>
                      setValues((prev) => ({ ...prev, service_type: e?.value }))
                    }
                  />
                </span>

                <Input
                  id={"email"}
                  name="email"
                  label={"Email"}
                  placeholder="Enter Email"
                  type="email"
                  value={values?.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.email && touched?.email}
                  errorText={errors?.email}
                />
              </div>

              <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                <Input
                  id={"start_time"}
                  name="start_time"
                  label={"Start Time"}
                  placeholder="Enter Start Time"
                  type="time"
                  value={values?.start_time}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.start_time && touched?.start_time}
                  errorText={errors?.start_time}
                />

                <Input
                  id={"end_time"}
                  name="end_time"
                  label={"End Time"}
                  placeholder="Enter End Time"
                  type="time"
                  value={values?.end_time}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.end_time && touched?.end_time}
                  errorText={errors?.end_time}
                />

                <Input
                  id={"date"}
                  name="date"
                  label={"Date"}
                  placeholder="Enter date"
                  type="date"
                  value={values?.date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.date && touched?.date}
                  errorText={errors?.date}
                />
              </div>
              <span className="flex flex-col space-y-1 w-full mt-1">
                <p className="font-poppins text-base font-semibold text-[#1C2135]">
                  Service Type
                </p>
                <ReactSelect
                  isMulti
                  options={service_provided ? service_provided : []}
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev,
                      service_provided: e?.map((e) => ({
                        service_provided_id: e?.value,
                      })),
                    }));
                  }}
                />
              </span>

              <div className="flex flex-col space-y-2">
                <Button type="submit">
                  {isLoading ? "Loading..." : "Add"}
                </Button>
                <div
                  className="text-center underline text-gray-400 text-sm cursor-pointer"
                  onClick={() =>
                    setModalState((prev) => ({ ...prev, state: false }))
                  }
                >
                  Go Back
                </div>
              </div>
            </ColumnContainer>
          </form>
        </ColumnContainer>
      </Modal>
    );
  };

  const showAppointment = () => {
    const { date, state } = showAppointmentModal;

    const date_wise_service =
      date !== "ALL"
        ? appointmentData?.data?.filter((e) => e?.date === date)
        : appointmentData?.data;

    return (
      <Modal
        state={state}
        handleState={() => setShowAppointmentModal({ date: "", state: false })}
        width={"70%"}
      >
        <ColumnContainer xSpace={0}>
          <p className="font-poppins font-semibold text-[28px] capitalize">
            Appointments - {date}
          </p>
          {!date_wise_service ? (
            "Loading..."
          ) : (
            <div className="grid grid-cols-4 gap-3">
              {console.log(date_wise_service)}

              {date_wise_service?.map((e) => (
                <ServiceCard
                  title={e?.service?.service_type}
                  petName={e?.pet?.pet?.name}
                  price={e?.cost || 0}
                  status={e?.status || "UPCOMING"}
                  time={`${moment(e?.start_time, "HH:mm").format("hh:mm a")} - ${moment(e?.end_time, "HH:mm").format("hh:mm a")}`}
                />
              ))}
            </div>
          )}
        </ColumnContainer>
      </Modal>
    );
  };

  return (
    <>
      <Container className="flex flex-col gap-6" title={"Dashbaord"}>
        <div className="flex flex-col text-[36px]">
          <span>
            Hey <strong className="text-orangeBg"> Service Provider </strong>
          </span>
          <span className="text-lg -mt-[8px] ml-[2px]">
            Here is an overview of your dashboard
          </span>
        </div>
        {addAppointment()}
        {showAppointment()}
        <div className="flex flex-col gap-4">
          <span className="font-semibold">
            Daily Overview
            <div className="border-b-2 border-orangeBg flex-grow w-20"></div>
          </span>

          <div className="flex flex-row space-x-8">
            <StatsCard
              bgColor="bg-orange-200"
              heading="Your Pets"
              number={statLoading ? "Loading..." : dashboardStat?.data?.totalPetsRegistered}
            />
            <StatsCard
              bgColor="bg-green-200"
              heading="New Bookings"
              number={statLoading ? "Loading..." : dashboardStat?.data?.newBookings}
            />
            <StatsCard
              bgColor="bg-yellow-200"
              heading="Total Bookings"
              number={statLoading ? "Loading..." : dashboardStat?.data?.totalBookings}
            />

          </div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="w-[70%]">
            <div className="flex flex-row justify-between w-[45%]">
              <span className="font-semibold">
                Appointments
                <div className="border-b-2 border-orangeBg flex-grow w-12"></div>
              </span>
              <button
                className="bg-orangeBg px-5 py-1 text-white text-sm rounded-lg"
                onClick={() =>
                  setShowAppointmentModal((prev) => ({
                    ...prev,
                    date: "ALL",
                    state: true,
                  }))
                }
              >
                View All
              </button>
            </div>
            {serviceAppointmentLoading ? (
              "Loading..."
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-3">
                {serviceAppointmentData?.data?.filter((e) => moment(e?.createdAt).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))?.length ? (
                  serviceAppointmentData?.data
                    ?.filter((e) => moment(e?.createdAt).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
                    ?.slice(0, 6)

                    ?.map((appointment, index) => (
                      <AppointmentCard
                        key={index}
                        title={appointment?.service?.service_name}
                        amount={appointment?.cost || 0}
                        parent={
                          appointment?.service_appointment_master?.petparent
                            ?.first_name
                        }
                        pet={appointment?.pet?.pet?.name}
                        status={
                          appointment?.status ||
                          "UPCOMING"
                        }
                      />
                    ))
                ) : (
                  <div className="text-lg w-full text-center h-full">
                    No New Appointments for today
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="w-[30%]">
            <div className="flex flex-row justify-between ">
              <span className="font-semibold ">
                Order Statistics
                <div className="border-b-2 border-orangeBg flex-grow w-20 "></div>
              </span>
              <span className="flex flex-row">
                <span className="text-sm font-semibold"> Monthly</span>{" "}
                <RiArrowDropDownLine className="mt-1" />
              </span>
            </div>
            <div className="bg-white rounded">
              <div className="grid grid-cols-2 gap-x-6 mt-4 py-3 pl-10">
                <StatsPercentage
                  percentage={75}
                  color="#4CAF50"
                  label="Completed Tasks"
                />
                <StatsPercentage
                  percentage={45}
                  color="#FFC107"
                  label="Pending Tasks"
                />
                <StatsPercentage
                  percentage={90}
                  color="#2196F3"
                  label="Ongoing Tasks"
                />
                <StatsPercentage
                  percentage={90}
                  color="#2196F3"
                  label="Ongoing Tasks"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <div className="text-lg font-semibold">
              Your Schedule
              <div className="border-b-2 border-orangeBg flex-grow w-16"></div>
            </div>
            <div className="flex flex-row gap-8">
              <div className="rounded font-semibold mt-2">Filter By</div>

              <div className="bg-white flex flex-row rounded-lg gap-12 p-2 items-center justify-center font-semibold">
                <button className="flex flex-row items-center flex-grow">
                  Date{" "}
                  <RiArrowDropDownLine
                    className="ml-[2px] mt-[2px]"
                    size={25}
                  />
                </button>
                <div className="border-r-2 border-black h-full opacity-10 "></div>
                <button className="flex flex-row items-center flex-grow">
                  Service Type{" "}
                  <RiArrowDropDownLine
                    className="ml-[2px] mt-[2px]"
                    size={25}
                  />
                </button>
                <div className="border-l-2 border-black h-full opacity-10"></div>
                <button className="flex flex-row items-center flex-grow">
                  Service Status{" "}
                  <RiArrowDropDownLine
                    className="ml-[2px] mt-[2px]"
                    size={25}
                  />
                </button>
              </div>

              <div className="flex items-center shadow-slate-300 shadow-2xl rounded-xl overflow-hidden">
                <button className="flex items-center justify-center bg-white py-3 px-4">
                  <IoIosSearch size={24} />
                </button>
                <input
                  type="search"
                  className="px-10 py-3 w-full text-black focus:outline-none"
                  placeholder="Search here ..."
                />
              </div>

              <div>
                <button className="bg-orangeBg rounded-lg px-12 py-[11px] font-semibold text-white">
                  Search
                </button>
              </div>
            </div>
          </div>

          {appointmentLoading && sucessFetchingAppointment ? (
            "Loading..."
          ) : (
            <div>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                displayEventTime={true}
                events={appointments?.length ? appointments : []}
                eventClick={(args) => {
                  if (args?.event?.title === "Show all") {
                    setShowAppointmentModal({
                      date: moment(args?.event?.start).format("YYYY-MM-DD"),
                      state: true,
                    });
                  }
                }}
                dateClick={handleDateClick}
                headerToolbar={{
                  start: "today",
                  center: "title",
                  end: "prev,next",
                }}
              />
              {isOpen && (
                <div
                  className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
                  onClick={handleCloseModal}
                >
                  <div
                    className="bg-white p-4 rounded shadow-lg relative z-60  ml-44"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <AddaBooking setIsOpen={setIsOpen} />
                  </div>
                </div>
              )}
              {isDateModalOpen && (
                <div
                  className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
                  onClick={handleCloseModal}
                >
                  <div
                    className="bg-white p-4 rounded shadow-lg relative z-60 ml-auto mr-20"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <DateModal DateServices={Dateservices} date={currentDate} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
