const InputField = ({ label, placeholder }) => (
    <div className="flex flex-col">
        <label className="text-gray-700 text-sm font-bold mb-1">{label}</label>
        <input
            type="text"
            className="w-[300px] md:w-[350px] p-2 bg-zinc-100 rounded-lg  "
            placeholder={placeholder}
        />
    </div>
);

export default InputField