import React, { useEffect, useState } from "react";
import Input from "components/Common/ui/Input";
import FileUploadField from "components/Common/AuthPages/FileUploadField";
import DeckGL from "@deck.gl/react";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import toast from "react-hot-toast";
import UploadedFilePlaceholder from "components/Common/ui/UploadedFilePlaceholder";
import * as Yup from "yup";
import { useFormik } from "formik";
import Autocomplete from "react-google-autocomplete";
import { Button } from "@mui/material";

const BoardingCenterData = ({ myformik, handleNext, handleBack }) => {
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = myformik;

  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();
  const BoardingCenterSchema = Yup.object().shape({
    business_email: Yup.string().email("Invalid email format").optional(),
    phone_number: Yup.string()
      .when("business_email", {
        is: (email) => !!email,
        then: () => Yup.string().required("Business phone number is required "),
      })
      .optional(),
    city: Yup.string()
      .when("business_email", {
        is: (email) => !!email,
        then: () => Yup.string().required("City is required "),
      })
      .optional(),
    state: Yup.string()
      .when("business_email", {
        is: (email) => !!email,
        then: () => Yup.string().required("State is required "),
      })
      .optional(),
    landline_number: Yup.string().optional(),
    longitude_latitude: Yup.string()
      .when("business_email", {
        is: (email) => !!email,
        then: () =>
          Yup.string().required("Longitude and Latitude are required "),
      })
      .optional(),
    location_address: Yup.string()
      .when("business_email", {
        is: (email) => !!email,
        then: () => Yup.string().required("Location/Address is required "),
      })
      .optional(),
    eatablishment_date: Yup.date()
      .when("business_email", {
        is: (email) => !!email,
        then: () => Yup.date().required("Established date is required "),
      })
      .optional(),
    // Additional optional fields with validation
    total_boarding_center_area: Yup.number().optional(),
    staff_to_pet_ratio: Yup.number().optional(),
    area_per_pet: Yup.number().optional(),
    amenities: Yup.string().optional(),
    max_pet_capacity: Yup.number().optional(),
  });
  const formik = useFormik({
    initialValues: {
      email: values?.email,
      phone_number: values?.phone_number,
      city: values?.city,
      state: values?.state,
      landline_number: values?.landline_number,
      longitude_latitude: values?.longitude_latitude,
      location_address: values?.location_address,
      eatablishment_date: values?.eatablishment_date,
      total_boarding_center_area: values?.total_boarding_center_area,
      staff_to_pet_ratio: values?.staff_to_pet_ratio,
      area_per_pet: values?.area_per_pet,
      amenities: values?.amenities,
      max_pet_capacity: values?.max_pet_capacity,
    },
    validationSchema: BoardingCenterSchema,
    onSubmit: (values) => {
      setValues((prev) => ({ ...prev, ...values }));
      handleNext();

      // handle form submission
    },
  });
  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
      data: deletedobj,
    },
  ] = useDeleteFileMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      setValues((prev) => ({ ...prev, center_photo_id: data?.data }));
    }

    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }

    if (deleteSuccess) {
      toast.success("File deleted successfully");
      setValues((prev) => ({
        ...prev,
        center_photo_id: values?.center_photo_id?.filter(
          (rec) => rec?.id != deletedobj?.data?.id,
        ),
      }));
    }
  }, [deleteError, deleteSuccess]);
  const handleFileSelect = async (event) => {
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    await uploadFiles(formData);
  };

  //map
  const [currentPosition, setCurrentPosition] = useState(null);

  const [selectedPosition, setSelectedPosition] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);

  useEffect(() => {
    // Function to get the user's current location
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentPosition({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error getting location", error);
            // Fallback to default location if error occurs
            setCurrentPosition({ lat: 37.7749, lng: -122.4194 }); // Default to San Francisco
          },
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        // Fallback to default location if geolocation is not supported
        setCurrentPosition({ lat: 37.7749, lng: -122.4194 }); // Default to San Francisco
      }
    };

    getCurrentLocation();
  }, []);
  useEffect(() => {
    if (
      formik?.values?.total_boarding_center_area &&
      formik?.values?.max_pet_capacity
    ) {
      formik?.setFieldValue(
        "area_per_pet",
        parseInt(
          formik?.values?.total_boarding_center_area /
            formik?.values?.max_pet_capacity,
        ),
      );
    }
  }, [formik?.values]);
  const handleMapClick = async (event) => {
    const position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setSelectedPosition(position);

    // Fetching the details using Geocoding API
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=AIzaSyAQ3UQ8J8cu9LNJ4kvwO5J5nbCFKJmET3Q`,
    );
    const data = await response.json();

    if (data.results.length > 0) {
      const locationInfo = {
        latitude: position.lat,
        longitude: position.lng,
        address: data.results[0].formatted_address,
        city: "",
        state: "",
        country: "",
      };

      // Extract city, state, and country from address components
      data.results[0].address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          locationInfo.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          locationInfo.state = component.long_name;
        }
        if (component.types.includes("country")) {
          locationInfo.country = component.long_name;
        }
      });
      formik?.setFieldValue(
        "longitude_latitude",
        JSON.stringify({
          longitude: locationInfo.longitude,
          latitude: locationInfo.latitude,
        }),
      );
      formik?.setFieldValue("city", locationInfo?.city);

      formik?.setFieldValue("location_address", locationInfo?.address);
      formik?.setFieldValue("state", locationInfo?.state);
      setLocationDetails(locationInfo);
    }
  };
  return (
    <div className="container mx-auto p-4 space-y-6">
      <div className="space-y-4">
        <h2 className="text-orangeBg text-xl font-bold">
          Add Boarding Center Details
        </h2>
        <div className="w-[23%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>
        <div className="w-full flex items-center justify-center flex-col">
          <label className="text-bold text-black w-full tex-start font-bold mb-2">
            Select Location
          </label>
          <LoadScript googleMapsApiKey="AIzaSyAQ3UQ8J8cu9LNJ4kvwO5J5nbCFKJmET3Q">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "400px" }}
              center={currentPosition || { lat: 37.7749, lng: -122.4194 }} // Fallback to default location
              zoom={12}
              onClick={handleMapClick}
            >
              {selectedPosition && <Marker position={selectedPosition} />}
            </GoogleMap>
          </LoadScript>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* {locationDetails && (
              <div style={{ marginTop: "20px" }}>
                <h3>Location Details:</h3>
                <p>
                  <strong>Address:</strong> {locationDetails.address}
                </p>
                <p>
                  <strong>City:</strong> {locationDetails.city}
                </p>
                <p>
                  <strong>State:</strong> {locationDetails.state}
                </p>
                <p>
                  <strong>Country:</strong> {locationDetails.country}
                </p>
                <p>
                  <strong>Latitude:</strong> {locationDetails.latitude}
                </p>
                <p>
                  <strong>Longitude:</strong> {locationDetails.longitude}
                </p>
              </div>
            )} */}
          <Input
            id={"business_email"}
            name="business_email"
            label={"Business Email"}
            placeholder="Enter email"
            type="email"
            value={formik?.values?.business_email}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.business_email && formik?.touched?.business_email
            }
            errorText={formik?.errors?.business_email}
          />
          <Input
            id={"phone_number"}
            name="phone_number"
            label={"Business Phone number"}
            placeholder="Enter phone number"
            value={formik?.values?.phone_number}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.phone_number && formik?.touched?.phone_number
            }
            errorText={formik?.errors?.phone_number}
          />
          <Input
            id={"city"}
            name="city"
            label={"City"}
            placeholder="Enter City"
            value={formik?.values?.city}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={formik?.errors?.city && formik?.touched?.city}
            errorText={formik?.errors?.city}
          />
          <Input
            id={"state"}
            name="state"
            label={"State"}
            placeholder="Enter State"
            value={formik?.values?.state}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={formik?.errors?.state && formik?.touched?.state}
            errorText={formik?.errors?.state}
          />
          <Input
            id={"landline_number"}
            name="landline_number"
            label={"Landline number"}
            placeholder="Enter landline number"
            value={formik?.values?.landline_number}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.landline_number &&
              formik?.touched?.landline_number
            }
            errorText={formik?.errors?.landline_number}
          />

          <Input
            id={"longitude_latitude"}
            name="longitude_latitude"
            label={"Longitude and Latitude"}
            disabled={true}
            placeholder="Enter longitude and latitude"
            value={formik?.values?.longitude_latitude}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.longitude_latitude &&
              formik?.touched?.longitude_latitude
            }
            errorText={formik?.errors?.longitude_latitude}
          />
          <Input
            id={"location_address"}
            name="location_address"
            label={"Location/Address"}
            placeholder="Enter address"
            value={formik?.values?.location_address}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.location_address &&
              formik?.touched?.location_address
            }
            errorText={formik?.errors?.location_address}
          />
          <Input
            id={"eatablishment_date"}
            name="eatablishment_date"
            label={"Established date"}
            placeholder="Enter established date (DD-MM-YYYY)"
            value={formik?.values?.eatablishment_date}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.eatablishment_date &&
              formik?.touched?.eatablishment_date
            }
            errorText={formik?.errors?.eatablishment_date}
            type="date"
          />
          <div className="flex flex-col space-x-2 space-y-2 w-full col-span-3">
            {isLoading && "Uploading..."}
            {deleteLoading && "Deleting..."}
            <FileUploadField
              label="Center photo"
              multiple={true}
              placeholder="No file selected"
              handleChange={handleFileSelect}
            />
            <div className="flex flex-wrap w-full gap-3">
              {values?.center_photo_id &&
                values?.center_photo_id?.map((rec) => (
                  <UploadedFilePlaceholder
                    name={rec?.key}
                    link={rec?.url}
                    onRemove={() =>
                      deleteFile(new URLSearchParams({ id: rec?.id }))
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h2 className="text-orangeBg text-xl font-bold">
          Add Service Details (Optional)
        </h2>
        <div className="w-[23%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Input
            id={"total_boarding_center_area"}
            name="total_boarding_center_area"
            label={"Total Boarding Center Area (in SFT)"}
            placeholder="Enter Total Boarding Center Area (in SFT)"
            value={formik?.values?.total_boarding_center_area}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.total_boarding_center_area &&
              formik?.touched?.total_boarding_center_area
            }
            errorText={formik?.errors?.total_boarding_center_area}
          />

          <Input
            id={"staff_to_pet_ratio"}
            name="staff_to_pet_ratio"
            label={"Enter Total Staff"}
            placeholder="Enter Total Staff"
            value={formik?.values?.staff_to_pet_ratio}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.staff_to_pet_ratio &&
              formik?.touched?.staff_to_pet_ratio
            }
            errorText={formik?.errors?.staff_to_pet_ratio}
          />
          <Input
            id={"area_per_pet"}
            name="area_per_pet"
            label={"Area Per Pet (in SFT)"}
            placeholder="Enter Area Per Pet (in SFT)"
            disabled={true}
            value={formik?.values?.area_per_pet}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.area_per_pet && formik?.touched?.area_per_pet
            }
            errorText={formik?.errors?.area_per_pet}
          />

          <Input
            id={"amenities"}
            name="amenities"
            label={"Amenities"}
            placeholder="Enter Amenities"
            value={formik?.values?.amenities}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={formik?.errors?.amenities && formik?.touched?.amenities}
            errorText={formik?.errors?.amenities}
          />

          <Input
            id={"max_pet_capacity"}
            name="max_pet_capacity"
            label={"Maximum Pet Capacity"}
            placeholder="Enter Maximum Pet Capacity"
            value={formik?.values?.max_pet_capacity}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.max_pet_capacity &&
              formik?.touched?.max_pet_capacity
            }
            errorText={formik?.errors?.max_pet_capacity}
          />
        </div>
      </div>
      <div className="w-fit float-right flex flex-row gap-5 mt-5">
        <Button
          onClick={handleBack}
          sx={{
            textTransform: "none",
          }}
          type="button"
        >
          <div className="px-4 py-2 md:px-7 md:py-2 bg-[#FFEEDE] rounded-xl">
            <span className="text-orangeBg text-sm md:text-md">Previous</span>
          </div>
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          sx={{
            textTransform: "none",
          }}
        >
          <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
            <span className="text-white text-sm md:text-md">Next</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default BoardingCenterData;
