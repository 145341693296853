import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/apiSlice";
import authSlice from "./features/auth/authSlice";
import petParentSlice from "./features/pet_parents/petParentSlice";
import pet_profileSlice from "./features/pet_profile/pet_profileSlice";
import appointmentSlice from "./features/appointment/appointmentSlice";
import layoutreducer from "./StateSlice/layoutSlice";
import stepformreducer from "./features/stepform/stepform";
const store = configureStore({
  reducer: {
    petParent: petParentSlice,
    petProfile: pet_profileSlice,
    auth: authSlice,
    appointment: appointmentSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    ...layoutreducer,
    ...stepformreducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
