import { Box } from "@mui/material";
import MuiModal from "@mui/material/Modal";
import PropTypes from "prop-types";
import Button from "./Button";
import { FiAlertTriangle } from "react-icons/fi";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const ConfirmationModal = ({ width = 600, padding = 3, state, handleState, children, onDelete, loading = false }) => {
    return (
        <MuiModal
            open={state}
            onClose={handleState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style, width, p: padding }}>
                <div
                    className="flex items-center space-x-4"
                >
                    <span
                        className="text-red-500 text-2xl p-2 flex items-center justify-center bg-red-100 rounded-full self-start"
                    >
                        <FiAlertTriangle />
                    </span>
                    <div
                        className="flex flex-col gap-2"
                    >
                        <h2
                            className="text-lg font-semibold"
                        >
                            Are you sure ?
                        </h2>
                        <p
                            className="text-sm"
                        >
                            Are you sure you want to delete this data?
                        </p>
                    </div>
                </div>
                <div
                    className="flex justify-end mt-4 space-x-4"
                >
                    <Button
                        disabled={loading}
                        className="bg-gray-300 text-gray-800 w-max"
                        onClick={handleState}>Cancel</Button>
                    <Button
                        disabled={loading}
                        className="w-max"
                        onClick={onDelete}>
                        {loading ? "Deleting..." : "Delete"}
                    </Button>
                </div>
            </Box>
        </MuiModal>
    );
};

ConfirmationModal.propTypes = {
    state: PropTypes.bool.isRequired,
    handleState: PropTypes.func.isRequired,
};

export default ConfirmationModal;