import React, { useEffect, useState } from "react";
import InputField from "../../Common/AuthPages/InputField";
import FileUploadField from "../../Common/AuthPages/FileUploadField";
import CloseIcon from "@mui/icons-material/Close";
import Input from "components/Common/ui/Input";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import Dropdown from "components/Common/ui/Dropdown";
import Select from "react-select";
import { MdDeleteOutline } from "react-icons/md";
import { Button } from "@mui/material";

import toast from "react-hot-toast";
import UploadedFilePlaceholder from "components/Common/ui/UploadedFilePlaceholder";
import { Label } from "@mui/icons-material";
import { ServiceEditForm } from "pages/ServiceManagement/ServiceEdit";
// function ServicePrivderForm({
//   index,
//   values,
//   formik,
//   setValues,
//   service,
//   handleChangeValue,
//   handleFileSelect,
//   setCurrentIndex,
//   deleteFile,
//   handlepricechange,
//   handleBlur,
//   isLoading,
//   deleteLoading,
// }) {
//   const [pricingmodel, setpricingmodel] = useState({
//     h24: false,
//     checkincheckout: false,
//     hourly: false,
//   });
//   const [extra, setextra] = useState(false);
//   const options = [
//     { value: "monday", label: "Monday" },
//     { value: "tuesday", label: "Tuesday" },
//     { value: "wednesday", label: "Wednesday" },
//     { value: "thursday", label: "Thursday" },
//     { value: "friday", label: "Friday" },
//     { value: "saturday", label: "Saturday" },
//     { value: "sunday", label: "Sunday" },
//   ];

//   return (
//     <div className="mt-5" key={index}>
//       <div className="flex flex-col justify-start items-start gap-6">
//         <div className="text-orangeBg w-full text-lg font-bold flex flex-row items-center">
//           <p>Add Service Type</p>
//           <CloseIcon
//             style={{ color: "red", height: 25, width: 25 }}
//             className="cursor-pointer ml-3"
//             onClick={() => {
//               const newServices = values.services_provided.filter(
//                 (_service, i) => i !== index,
//               );
//               setValues((prev) => ({
//                 ...prev,
//                 services_provided: newServices,
//               }));
//             }}
//           />
//         </div>
//       </div>
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10 w-full mt-4">
//         <Dropdown
//           formik={formik}
//           placeholder={"Service Type"}
//           name="service_type"
//           myvalue={service?.service_type}
//           options={[
//             { id: "Boarding" },
//             { id: "Grooming" },
//             { id: "Training" },
//             { id: "Day care" },
//             { id: "Pet taxi" },
//             { id: "Vets" },
//           ]}
//           label="Service Type"
//           variable="id"
//           onChange={(e) => {
//             setpricingmodel({
//               h24: false,
//               checkincheckout: false,
//               hourly: false,
//             });

//             handleChangeValue("service_type", e, index);
//           }}
//         />
//         <Input
//           id={"service_name"}
//           name="service_name"
//           label={"Service Name"}
//           placeholder="Enter service name"
//           value={service?.service_name}
//           onChange={(e) =>
//             handleChangeValue("service_name", e.target.value, index)
//           }
//         />

//         {service?.service_type != "Boarding" && (
//           <Input
//             id={"duratiom"}
//             name="duratiom"
//             label={"Service Duaration"}
//             placeholder="Enter Service Duaration"
//             value={service?.duration}
//             onChange={(e) =>
//               handleChangeValue("duration", e.target.value, index)
//             }
//           />
//         )}
//         {service?.service_type == "Boarding" && (
//           <Dropdown
//             formik={formik}
//             placeholder={"Select Accommodation"}
//             name="accomodation_temp_id"
//             myvalue={service?.accomodation_temp_id}
//             // options={[
//             //   { id: "Garden" },
//             //   { id: "Grooming" },
//             //   { id: "Training" },
//             //   { id: "Day care" },
//             //   { id: "Pet taxi" },
//             //   { id: "Vets" },
//             // ]}
//             options={values?.accomodations?.map((rec) => ({
//               id: rec?.accomodation_room_type_name,
//               value: rec?.temp_id,
//             }))}
//             label="Select Accommodation"
//             variable="id"
//             onChange={(e) =>
//               handleChangeValue("accomodation_temp_id", e, index)
//             }
//           />
//         )}
//       </div>
//       <div className="flex flex-row gap-10">
//         <div className="mt-8 w-[21.5rem]">
//           <Dropdown
//             formik={formik}
//             placeholder={"Inbound/Outbound"}
//             name="inbound_outbound"
//             myvalue={service?.inbound_outbound}
//             options={[{ id: "Inbound" }, { id: "Outbound" }]}
//             label="Inbound/Outbound"
//             variable="id"
//             onChange={(value) =>
//               handleChangeValue("inbound_outbound", value, index)
//             }
//           />
//         </div>
//         {service?.service_type != "Boarding" && (
//           <div className="mt-8 w-[21.5rem]">
//             <Input
//               id={"price"}
//               name="price"
//               label={"Price"}
//               placeholder="Enter Price"
//               value={service?.pricing[3]?.price}
//               onChange={(e) => handlepricechange(3, e.target.value, index)}
//             />
//           </div>
//         )}
//         <div className="flex flex-col mt-8 ">
//           {service?.service_type === "Boarding" && (
//             <>
//               {" "}
//               <label className="text-[1rem] font-bold">Pricing Model</label>
//               <div className="w-full flex flex-col mt-2">
//                 {service?.pricing?.map((rec, index) => {
//                   if (rec?.price_modal != "Fixed")
//                     return (
//                       <div key={index} className="w-full flex items-center">
//                         <input
//                           type="checkbox"
//                           checked={
//                             rec?.price_modal == "24h"
//                               ? pricingmodel.h24
//                               : rec?.price_modal == "Hourly"
//                                 ? pricingmodel.hourly
//                                 : rec?.price_modal == "Checkin-Checkout"
//                                   ? pricingmodel.checkincheckout
//                                   : ""
//                           }
//                           id={`checkbox-${index}`}
//                           onChange={(e) => {
//                             if (rec?.price_modal == "24h")
//                               setpricingmodel((prev) => ({
//                                 ...prev,
//                                 h24: e.target.checked,
//                               }));
//                             if (rec?.price_modal == "Hourly")
//                               setpricingmodel((prev) => ({
//                                 ...prev,
//                                 hourly: e.target.checked,
//                               }));
//                             if (rec?.price_modal == "Checkin-Checkout")
//                               setpricingmodel((prev) => ({
//                                 ...prev,
//                                 checkincheckout: e.target.checked,
//                               }));
//                           }}
//                         />
//                         <label
//                           htmlFor={`checkbox-${index}`}
//                           className="text-black ml-2"
//                         >
//                           {rec?.price_modal}
//                         </label>
//                       </div>
//                     );
//                 })}
//               </div>
//             </>
//           )}
//         </div>
//         <div key={index} className="mt-[53px] flex items-center">
//           <input
//             type="checkbox"
//             id={`checkbox-extra`}
//             onChange={(e) => {
//               setextra(e.target.checked);
//             }}
//           />
//           <label htmlFor={`checkbox-extra`} className="text-black ml-2">
//             Add Extra Hour Charges
//           </label>
//         </div>
//       </div>
//       <div className="mt-8 flex flex-row gap-10">
//         {extra && (
//           <Input
//             id={"exyra_charges"}
//             name="hourly_chanrges"
//             label={"Extra Hour Charges ₹ (1 Hour)"}
//             placeholder="Enter Extra Hour Charges"
//             value={service?.pricing[1]?.price}
//             onBlur={handleBlur}
//             onChange={(e) =>
//               handleChangeValue("exceed_time_charge", e.target.value, index)
//             }
//           />
//         )}
//         {pricingmodel?.h24 && (
//           <Input
//             id={"24h"}
//             name="24h"
//             label={"24H Charges  ₹"}
//             placeholder="Enter 24H Charges ₹"
//             value={service?.pricing[0]?.price}
//             onBlur={handleBlur}
//             onChange={(e) => handlepricechange(0, e.target.value, index)}
//           />
//         )}
//         {pricingmodel?.hourly && (
//           <Input
//             id={"hourly_chanrges"}
//             name="hourly_chanrges"
//             label={"Hourly Charges ₹ (1 Hour)"}
//             placeholder="Enter Hourly Charges"
//             value={service?.pricing[1]?.price}
//             onBlur={handleBlur}
//             onChange={(e) => handlepricechange(1, e.target.value, index)}
//           />
//         )}
//       </div>
//       <div className=" mt-8 w-full flex flex-row gap-10">
//         {pricingmodel?.checkincheckout && (
//           <Input
//             id={"checkincharges"}
//             name="checkincharges"
//             label={"CheckIn-CheckOut Charges₹"}
//             placeholder="Enter CheckIn CheckOut Charges ₹"
//             value={service?.pricing[2]?.price}
//             onBlur={handleBlur}
//             onChange={(e) => handlepricechange(2, e.target.value, index)}
//           />
//         )}
//         {pricingmodel?.checkincheckout && (
//           <Input
//             id={"checkintime"}
//             name="checkincharges"
//             label={"CheckIn Time"}
//             type={"time"}
//             placeholder="CheckIn Time"
//             value={service?.pricing[2]?.checkin_time}
//             onBlur={handleBlur}
//             onChange={(e) => {
//               const newServices = [...values.services_provided];
//               newServices[index].pricing[2].checkin_time = e.target.value;
//               setValues({ ...values, services_provided: newServices });
//             }}
//           />
//         )}
//         {pricingmodel?.checkincheckout && (
//           <Input
//             id={"checkouttime"}
//             name="checkincharges"
//             label={"CheckOut Time"}
//             type={"time"}
//             placeholder="CheckOut Time"
//             value={service?.pricing[2]?.checkout_time}
//             onBlur={handleBlur}
//             onChange={(e) => {
//               const newServices = [...values.services_provided];
//               newServices[index].pricing[2].checkout_time = e.target.value;
//               setValues({ ...values, services_provided: newServices });
//             }}
//           />
//         )}
//       </div>
//       <div className="mt-8 ">
//         <div className="text-gray-700 text-sm font-bold font-poppins">
//           Service Description
//         </div>
//         <div className="bg-white px-0 py-2 rounded-lg mt-2">
//           <textarea
//             className="w-full h-36 text-sm text-gray-700 bg-gray-100 font-normal font-poppins resize-none rounded-md p-2 focus:outline-none border-none"
//             placeholder="Enter service description"
//             handleChange={(e) =>
//               handleChangeValue("service_description", e.target.value, index)
//             }
//           />
//         </div>
//       </div>
//       <div className=" mt-8 w-full flex flex-row gap-10">
//         <div className="flex flex-col w-[50%]">
//           <label className="font-bold text-[1rem]">
//             Select Week Days Service is Available
//           </label>
//           <Select
//             className="mt-2 "
//             placeholder={"select Days"}
//             isMulti={true}
//             // value={selectedOption}
//             onChange={(val) => {
//               let start_time =
//                 service?.availibilies?.length > 0 &&
//                 service?.availibilies[0]?.start_time;
//               let end_time =
//                 service?.availibilies?.length > 0 &&
//                 service?.availibilies[0]?.start_time;

//               let currentvalues = values;

//               currentvalues.services_provided[index].availibilies = val.map(
//                 (rec) => ({
//                   ...rec,
//                   start_time,
//                   end_time,
//                 }),
//               );

//               // setValues({ ...values, services_provided: currentvalues });
//             }}
//             options={options}
//           />
//         </div>
//         <Input
//           id={"from"}
//           name="checkincharges"
//           label={"From"}
//           type={"time"}
//           placeholder="From"
//           value={service?.from_time}
//           onBlur={handleBlur}
//           onChange={(e) =>
//             handleChangeValue("from_time", e.target.value, index)
//           }
//         />

//         <Input
//           id={"to"}
//           name="checkincharges"
//           label={"To"}
//           type={"time"}
//           placeholder="To"
//           value={service?.to_time}
//           onBlur={handleBlur}
//           onChange={(e) => handleChangeValue("to_time", e.target.value, index)}
//         />
//       </div>

//       <div className="flex flex-col md:flex-row justify-center items-center mt-8">
//         <div className=" w-full rounded-lg">
//           <div className="bg-white p-4 rounded-md flex w-full items-center justify-between">
//             <div className="flex flex-col space-x-2 space-y-2">
//               {isLoading && "Uploading..."}
//               {deleteLoading && "Deleting..."}
//               <FileUploadField
//                 multiple={true}
//                 label="Center photo"
//                 placeholder="No file selected"
//                 handleChange={(e) => {
//                   handleFileSelect(e);
//                   setCurrentIndex(index);
//                 }}
//               />
//               <div className="flex flex-row w-full">
//                 {service?.servicePhotos &&
//                   service?.servicePhotos?.map((rec) => (
//                     <UploadedFilePlaceholder
//                       name={rec?.key}
//                       link={rec?.url}
//                       onRemove={() =>
//                         deleteFile(
//                           new URLSearchParams({
//                             id: rec?.id,
//                           }),
//                         )
//                       }
//                     />
//                   ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
function ServiceToProvide({ myformik, handleregister, handleBack }) {
  const { values, handleBlur, setValues } = myformik;

  const [currentIndex, setCurrentIndex] = useState(null);
  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();

  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
    },
  ] = useDeleteFileMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      handleChangeValue(
        "servicePhotos",
        [
          ...values?.services_provided[currentIndex]?.servicePhotos,
          ...data?.data,
        ],
        currentIndex,
      );
    }
    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }

    if (deleteSuccess) {
      toast.success("File deleted successfully");
      handleChangeValue("picture_id", "", currentIndex);
    }
  }, [deleteError, deleteSuccess]);

  const handleChangeValue = (fieldname, value, index) => {
    const newServices = [...values.services_provided];
    newServices[index][fieldname] = value;
    setValues({ ...values, services_provided: newServices });
  };
  const handlepricechange = (priceindex, value, index) => {
    const newServices = [...values.services_provided];
    newServices[index].pricing[priceindex].price = value;
    setValues({ ...values, services_provided: newServices });
  };
  const handleFileSelect = (event) => {
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    uploadFiles(formData);
  };
  return (
    <div className="w-full  px-4 py-8">
      {values?.services_provided?.map((service, index) => {
        return (
          <>
            <div className="mt-5 bg-white h-full mb-5">
              <ServiceEditForm
                stepform={service}
                tempaccommdata={values?.accomodations}
                formsubmit={(val) => {
                  const newServices = [...values.services_provided];
                  newServices[index] = {
                    ...val,
                    pricing: val?.service_pricings,
                    servicePhotos: val?.services_photos,
                    exceed_time_charge: val?.exceed_time_charge,
                    accomodation_temp_id: val?.accomodation_id,
                  };
                  setValues({ ...values, services_provided: newServices });
                }}
                deleteservice={() => {
                  const newServices = values.services_provided.filter(
                    (_service, i) => i !== index,
                  );
                  setValues((prev) => ({
                    ...prev,
                    services_provided: newServices,
                  }));
                }}
              />{" "}
            </div>
            <hr />
          </>
        );
      })}
      <div
        className="w-[294px] h-[55px] px-2 ml-2 py-[1px] items-center justify-center bg-orange-100 rounded-md flex-col gap-2.5 inline-flex cursor-pointer"
        onClick={() => {
          setValues((prev) => ({
            ...prev,
            services_provided: [
              ...prev.services_provided,
              {
                service_name: "",
                service_category: "",
                service_type: "",
                service_description: "",
                inbound_outbound: "",
                duration: "",
                exceed_time_charge: 0,
                from_time: "",
                to_time: "",
                accomodation_temp_id: "",
                availibilies: [],
                servicePhotos: [],

                pricing: [
                  {
                    price_modal: "24h",
                    checkin_time: "",
                    checkout_time: "",
                    price: null,
                  },
                  {
                    price_modal: "Hourly",
                    checkin_time: "",
                    checkout_time: "",
                    price: null,
                  },
                  {
                    price_modal: "Checkin-Checkout",
                    checkin_time: "",
                    checkout_time: "",
                    price: null,
                  },
                ],
              },
            ],
          }));
        }}
      >
        <div className="justify-start items-center gap-[15px] inline-flex">
          <div className="text-orange-400 text-3xl font-semibold ">+</div>
          <div className="text-orange-400 text-sm font-bold cursor-pointer">
            Add service
          </div>
        </div>
      </div>
      <div className="w-fit float-right flex flex-row gap-5 mt-5">
        <Button
          onClick={handleBack}
          sx={{
            textTransform: "none",
          }}
          type="button"
        >
          <div className="px-4 py-2 md:px-7 md:py-2 bg-[#FFEEDE] rounded-xl">
            <span className="text-orangeBg text-sm md:text-md">Previous</span>
          </div>
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleregister();
          }}
          sx={{
            textTransform: "none",
          }}
        >
          <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
            <span className="text-white text-sm md:text-md">Register</span>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default ServiceToProvide;
