import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@mui/material";

import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import toast from "react-hot-toast";
import { AccommodationEditForm } from "pages/AccommodationManagement/AccommodationEdit";
const AccommodationDetails = ({ myformik, handleBack, handleNext }) => {
  const { values, handleBlur, handleChange, errors, touched, setValues } =
    myformik;
  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleChangeValue = (fieldname, value, index) => {
    const newServices = [...values.accomodations];
    newServices[index][fieldname] = value;

    setValues({ ...values, accomodations: newServices });
  };

  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
    },
  ] = useDeleteFileMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      handleChangeValue(
        "picture_id",
        [...values?.accomodations[currentIndex].picture_id, ...data?.data],
        currentIndex,
      );
    }
    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }

    if (deleteSuccess) {
      toast.success("File deleted successfully");
      setValues((prev) => ({
        ...prev,
        accomodations: { ...prev.accomodations, picture_id: "" },
      }));
    }
  }, [deleteError, deleteSuccess]);

  const handleFileSelect = async (event) => {
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    await uploadFiles(formData);
  };

  return (
    <div className="flex flex-col w-full justify-start items-start gap-5">
      {values?.accomodations?.map((record, index) => {
        return (
          <AccommodationEditForm
            allaccom={values?.accomodations}
            stepform={record}
            formsubmit={(value) => {
              const newServices = [...values.accomodations];
              newServices[index] = {
                ...value,
                accomodationPhotos: value.service_accomodation_photos?.map(
                  (rec) => ({ file_id: rec?.id }),
                ),
              };
              setValues({ ...values, accomodations: newServices });
            }}
            deleteaccomm={() => {
              setValues((prev) => ({
                ...prev,
                accomodations: values.accomodations.filter(
                  (rec) => rec.temp_id != record?.temp_id,
                ),
              }));
            }}
          />
        );
      })}
      <button
        className="w-[294px] h-[55px] px-2 ml-2 py-[1px] items-center justify-center bg-orange-100 rounded-md flex-col gap-2.5 inline-flex cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          setValues((prev) => ({
            ...prev,
            accomodations: [
              ...prev?.accomodations,
              {
                temp_id: uuidv4(),

                accomodation_room_type_name: "",
                accomodation_number_of_rooms: 0,
                accomodation_max_capacity_room: 0,
                accomodation_price: "",
                disable_booking_inventory_full: false,
                accomodationPhotos: [],
                accomodation_sft: "",
                accomodation_sft_per_pet: "",
                accomodation_amenities_list: "",
                accomodation_description: "",
                outdoor_or_indoor: "Indoor",
                ac_or_nonAc: "AC",
                single_or_group: "Single",
                special_medical_care: "Standard",
              },
            ],
          }));
        }}
      >
        <div className="justify-start items-center gap-[15px] inline-flex">
          <div className="text-orange-400 text-3xl font-semibold ">+</div>
          <div className="text-orange-400 text-sm font-bold cursor-pointer">
            Add Accommodation
          </div>
        </div>
      </button>
      <div className="w-fit float-right flex flex-row gap-5 mt-5">
        <Button
          onClick={handleBack}
          sx={{
            textTransform: "none",
          }}
          type="button"
        >
          <div className="px-4 py-2 md:px-7 md:py-2 bg-[#FFEEDE] rounded-xl">
            <span className="text-orangeBg text-sm md:text-md">Previous</span>
          </div>
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleNext();
          }}
          sx={{
            textTransform: "none",
          }}
        >
          <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
            <span className="text-white text-sm md:text-md">Next</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default AccommodationDetails;
