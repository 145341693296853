import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/AuthPages/LoginPage";
import LaptopViewSignUp from "./pages/AuthPages/LaptopViewSignUp";
import DashBoardSideBar from "layout/DashBoardSideBar";
import Dashboard from "./pages/Dashoard/Dashboard";
import BookingReservation from "./pages/BookingReservation";
import ViewAppointment from "pages/BookingReservation/ViewAppointment";
import PetManagement from "pages/PetManagement";
import ViewPetInfo from "pages/PetManagement/ViewPetInfo";
import PetParentManagement from "pages/PetParentManagement";
import ViewPetParentInfo from "pages/PetParentManagement/ViewPetParentInfo";
import StaffManagementMain from "./pages/StaffManagement/StaffManagementMain";
import ViewStaffDetails from "./pages/StaffManagement/ViewStaffDetails";
import RelatedBooking from "./pages/StaffManagement/RelatedBooking";
import StaffLogs from "./pages/StaffManagement/StaffLogs";
import AccommodationMangement from "pages/AccommodationManagement/AccommodationMangement";
import { AccommodationEdit } from "pages/AccommodationManagement/AccommodationEdit";
import ServiceManagement from "pages/ServiceManagement/ServiceManagement";
import { ServiceEdit } from "pages/ServiceManagement/ServiceEdit";
import PrivateRoute from "layout/ProtectedRoutes";
import RoleProtectedRoutes from "layout/RoleProctedRoutes";
import AssignedBookings from "pages/assignedBooking";

//Roles Enums

const USER_ROLES = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  PET_HANDLER: "PET_HANDLER",
}


function App() {
  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "font-poppins text-base rounded",
          success: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
          error: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
        }}
        position="top-center"
      />

      <div className="w-full h-full">
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<LaptopViewSignUp />} />
            <Route
              path={"/"}

              element={
                <PrivateRoute>
                  <DashBoardSideBar />
                </PrivateRoute>
              }
            >
              <Route element={<RoleProtectedRoutes allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.PET_HANDLER]} />}>
                <Route
                  path="/assigned-Booking"
                  element={<AssignedBookings />}
                />
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" />}
                />

                <Route path="/pet-management" element={<PetManagement />} />
                <Route
                  path="/pet-management/profile/:id"
                  element={<ViewPetInfo />}
                />
                <Route
                  path="/pet-parent-management"
                  element={<PetParentManagement />}
                />
                <Route
                  path="/pet-parent-management/profile/:id"
                  element={<ViewPetParentInfo />}
                />


              </Route>

              <Route element={<RoleProtectedRoutes allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.MANAGER]} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/booking-reservation"
                  element={<BookingReservation />}
                />

                <Route
                  path="/booking-reservation/appointment/:id"
                  element={<ViewAppointment />}
                />
              </Route>
              <Route element={<RoleProtectedRoutes allowedRoles={[USER_ROLES.ADMIN]} />}>
                <Route
                  path="/staff-management"
                  element={<StaffManagementMain />}
                />

                <Route
                  path="/staff-management/profile/:id"
                  element={<ViewStaffDetails />}
                />
                <Route
                  path="/staff-management/profile/booking/:id"
                  element={<RelatedBooking />}
                />
                <Route
                  path="/staff-management/profile/logs/:id"
                  element={<StaffLogs />}
                />
                <Route
                  path="/accomadation-management"
                  element={<AccommodationMangement />}
                />
                <Route
                  path="/service-management"
                  element={<ServiceManagement />}
                />
                <Route
                  path="/accomadation-management/:id"
                  element={<AccommodationEdit />}
                />
                <Route path="/service-management/:id" element={<ServiceEdit />} />
              </Route>





            </Route>
          </Routes>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
