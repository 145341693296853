import React, { useEffect, useState } from "react";
import profilePic from "../../assets/images/profile_pic.png";
import ViewProfileFields from "../../components/Common/ui/ViewProfileFields";
import ViewProfileInfo from "../../components/Common/ui/ViewProfileInfo";
import PetCard from "../../components/Common/ui/PetCard";
import { useNavigate, useParams } from "react-router-dom";
/* import {
  useDeletePetMutation,
  useGetOnePetQuery,
} from "../../redux/features/pet_profile/petProfileApiSlice"; */
import { LinkOffOutlined } from "@mui/icons-material";
import Button from "../../components/Common/ui/Button";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import toast from "react-hot-toast";
import Modal from "components/Common/ui/Modal";
import Input from "components/Common/ui/Input";
import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import { useGetOnePetQuery } from "store/features/pet_profile/petProfileApiSlice";
import { useAddPetAndParentMutation } from "store/features/pet_parents/petParentApiSlice";

const ViewPetInfo = () => {
  const navigate = useNavigate();
  const [deleteAltert, setDeleteAlert] = useState({
    state: false,
    id: null,
  });
  const { id } = useParams();

  const [addPetParentModal, setAddPetParentModal] = useState(false);

  const { isLoading, data: petDetails, refetch } = useGetOnePetQuery(id);

  const data = petDetails?.data?.data;
  const relatedParents = petDetails?.data?.relatedParents;

  const [addPetAndParent, { isLoading: addLoading, isError, isSuccess }] =
    useAddPetAndParentMutation();

  /*   const [
    deletePet,
    { isSuccess: deleteSuccess, isError: isDeleteError, error: deleteErrors },
  ] = useDeletePetMutation(); */

  /* useEffect(() => {
    if (isSuccess) {
      refetch();
      toast.success("Parent added successfully");
      setAddPetParentModal(false);
    }

    if (isError) {
      toast.error("Failed to add Parent");
    }
  }, [isSuccess, isError, refetch]); */

  /*  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Pet deleted successfully!");
      setDeleteAlert({ id: null, state: false });
      navigate("/pet-profile");
    }

    if (isDeleteError) {
      toast.error("Failed to delete Pet!");
    }
  }, [deleteSuccess, isDeleteError, deleteErrors, navigate]); */

  useEffect(() => {
    refetch();
  }, [refetch]);

  const addPetToParent = () => {
    return (
      <Modal
        state={addPetParentModal}
        width={500}
        handleState={() => setAddPetParentModal((prevState) => !prevState)}
      >
        <p className="font-poppins font-semibold text-[28px] capitalize">
          Add Parent to Pet
        </p>
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            const payload = {
              parent_id: e.target.parent_id.value,
              pet_id: id,
            };
            addPetAndParent(payload);
          }}
        >
          <div className="flex flex-col space-y-2">
            <Input
              id={"parent_id"}
              name="parent_id"
              label={"Parent ID"}
              placeholder="Enter Parent ID..."
            />

            <div className="flex flex-col space-y-2">
              <Button type="submit">{"Add"}</Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() => setAddPetParentModal(false)}
              >
                Go Back
              </div>
            </div>
          </div>
        </form>
      </Modal>
    );
  };

  return (
    <Container title={"Pet Management"}>
      <InfoPageHeader
        title={"Pet Details"}
        backBtnFunction={() => navigate(-1)}
      />
      {addPetToParent()}

      {/*  <Altert
        title={"Delete"}
        content={"Are you sure to delete this?"}
        state={deleteAltert.state}
        handleState={() =>
          setDeleteAlert((prev) => ({ ...prev, state: !deleteAltert.state }))
        }
        onConfirm={() => deletePet(deleteAltert.id)}
      /> */}

      {isLoading ? (
        "Loading..."
      ) : (
        <>
          {/* FIRST SECTION WITH PROFILE PIC AND OTHER INFO */}
          <div className="grid grid-cols-4">
            <div>
              <img
                src={profilePic}
                alt="profile_pic"
                className="h-[240px] w-[240px] "
              />
            </div>
            <div className="col-span-3">
              <div className="flex flex-col space-y-2">
                <div className="grid grid-cols-3 gap-3">
                  <ViewProfileFields title="Pet Name" value={data?.pet?.name} />
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <ViewProfileFields title="Type" value={data?.pet?.type} />
                  <ViewProfileFields title="Breed" value={data?.pet?.breed} />
                  <ViewProfileFields
                    title="Age (in years)"
                    value={data?.pet?.age}
                  />
                  <ViewProfileFields title="Documents" value={"-"} />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4 mt-5">
            <p className="font-semibold text-[#D99149]">Vaccination Details</p>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
              {data?.pet?.vaccination_records?.map((record, index) => (
                <ViewProfileInfo
                  title={`${record?.vaccine_name}`}
                  contents={
                    <div className="flex flex-col justify-between items-center">
                      <div className="flex flex-col space-x-1">
                        <div className="text-sm font-normal flex flex-col space-y-1">
                          <span>Doctor: {record?.consult_doctor}</span>
                          <span>
                            Site: {record?.administration_site} on{" "}
                            {record?.administered_date.split("T")[0]}
                          </span>
                          <span>
                            Next Due Date:{" "}
                            {record?.next_vaccine_due_date?.split("T")[0]}
                          </span>
                        </div>
                        <div className="flex justify-start items-start space-x-3 mt-2">
                          <a
                            rel="noreferrer"
                            href={record?.document?.url}
                            target="_blank"
                            className="w-full h-[37.023px] flex items-center bg-[#D99149] rounded-[8.048px] text-center text-white font-semibold text-sm justify-center focus:outline-none cursor-pointe"
                          >
                            Document
                          </a>
                          <a
                            rel="noreferrer"
                            href={record?.photo?.url}
                            target="_blank"
                            className="w-full h-[37.023px] flex items-center bg-[#D99149] rounded-[8.048px] text-center text-white font-semibold text-sm justify-center focus:outline-none cursor-pointe"
                          >
                            Photo
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <p className="font-semibold text-[#D99149]">
              Blood Donation Details
            </p>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
              <ViewProfileFields title="Parvo" value={"data?.data?.phone"} />
              <ViewProfileFields
                title="Blood Type"
                value={"data?.data?.phone_emergency"}
              />
              <ViewProfileFields title="Last Blood Donation Date" value={0} />{" "}
              {/* TODO: make this dynamic */}
              <ViewProfileFields
                title="Next Eligible Donation Date"
                value={0}
              />
              <ViewProfileFields title="Contact Person" value={0} />
              <ViewProfileFields title="Contact Number" value={0} />
            </div>
          </div>

          <div className="grid md:grid-cols-3 grid-cols-1 gap-2 mt-5">
            <ViewProfileInfo
              title={"Related Parents"}
              contents={
                <div className="flex flex-row space-x-4">
                  {relatedParents?.map((element, index) => (
                    <PetCard
                      name={`${element?.service_pet_parent?.petparent?.first_name} ${element?.service_pet_parent?.petparent?.last_name}`}
                    />
                  ))}
                </div>
              }
              topActionButton={
                <button
                  className="bg-faintOrangeBg cursor-pointer text-darkOrangeBg rounded-full flex items-center justify-center h-[30px] w-[30px]"
                  onClick={() => setAddPetParentModal(true)}
                >
                  <LinkOffOutlined style={{ height: 15, width: 15 }} />
                </button>
              }
            />
          </div>

          <div className="grid md:grid-cols-4 grid-cols-1 gap-2">
            {data?.pet?.vaccination_records?.map((report, index) => (
              <ViewProfileInfo
                title={"Document"}
                contents={
                  <div className="flex flex-row justify-between items-center">
                    <p className="font-normal text-base mr-auto">
                      Vaccination Record
                    </p>
                    <a
                      rel="noreferrer"
                      href={report?.document?.url}
                      target="_blank"
                      className="w-full h-[37.023px] flex items-center ml-24 bg-[#D99149] rounded-[8.048px] text-center text-white font-semibold text-sm justify-center focus:outline-none cursor-pointe"
                    >
                      View
                    </a>
                  </div>
                }
              />
            ))}
          </div>
        </>
      )}

      <div className="flex flex-row space-x-2 md:mt-8 mt-5">
        <span className="">
          <button className="bg-[#D99149]/20 text-[#D99149] rounded-lg w-full h-[37.023px] flex items-center justify-center px-10">
            View Related Bookings
          </button>
        </span>
        {/* <span className="md:w-16">
          <Button>Edit</Button>
        </span> */}
      </div>
    </Container>
  );
};

export default ViewPetInfo;
