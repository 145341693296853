import React, { useEffect } from "react";
import boop from "../assets/images/boop.png";
import { IoIosLogOut } from "react-icons/io";
import { logout } from "store/features/auth/authSlice";
import { useDispatch } from "react-redux";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import toast from "react-hot-toast";
import moment from "moment";
import { Settings } from "@mui/icons-material";
import config from "config/APIEndpoints";
import { Box, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";


const ModalWrapper = ({ open, handleClose, children, p = 4, width = 600 }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: p,
    borderRadius: "20px",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};



const NotificationModal = ({ closeModal }) => {
  const [tab, setTab] = React.useState("all");
  const [notify, setNotify] = React.useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("boop_service_user"));
  useEffect(() => {
    (async () => {
      try {
        const api = config.getEndpoint();
        const user = JSON.parse(localStorage.getItem("boop_service_user"));
        const res = await fetch(`${api}/api/v1/notifications`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${user?.token}`
          }
        });
        const data = await res.json();
        setNotify(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);


  return (
    <>
      <div className="w-full ">
        <div
          className="flex justify-between items-center"
        >
          <span
            className="text-2xl font-poppins font-bold "
          >Notification</span>
          <span
            onClick={async () => {
              try {
                const api = config.getEndpoint();
                const user = JSON.parse(localStorage.getItem("boop_service_user"));
                const res = await fetch(`${api}/api/v1/notifications/read`, {
                  method: "PUT",
                  headers: {
                    "Authorization": `Bearer ${user?.token}`,
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({ id: notify?.map((n) => n?.id) })
                });
                await res.json();

                toast.success("All notifications are marked as read");

                setNotify(notify.map((not) => ({ ...not, isRead: true })));
              } catch (error) {
                console.log(error);
              }
            }}
            className="underline cursor-pointer"
          >Mark all as read</span>
        </div>
        {/* Tabs */}
        <div className="flex items-center justify-between">
          <div className="flex justify-start items-center w-max my-4 
           
          ">
            <button
              onClick={() => setTab("all")}
              className={`${tab === "all"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              All
            </button>
            <button
              onClick={() => setTab("new")}
              className={`${tab === "new"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              New
            </button>
            <button
              onClick={() => setTab("previous")}
              className={`${tab === "previous"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              Previous
            </button>

          </div>
          <span
            className="flex items-center justify-center cursor-pointer"
          >
            <Settings />
          </span>
        </div>
        <div className="flex-col flex gap-y-2  pb-8 justify-around   space-y-4">
          {notify.length > 0 && notify
            ?.filter((n) => tab === "all" ? n : tab === "new" ? !n?.isRead : n?.isRead)
            ?.map((n) => (
              <>
                <div

                  onClick={async () => {
                    try {
                      const api = config.getEndpoint();
                      const user = JSON.parse(localStorage.getItem("boop_service_user"));
                      const res = await fetch(`${api}/api/v1/notifications/read`, {
                        method: "PUT",
                        headers: {
                          "Authorization": `Bearer ${user?.token}`,
                          "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ id: [n?.id] })
                      });
                      await res.json();

                      toast.success("Notification marked as read");

                      setNotify(notify.map((not) => {
                        if (not?.id === n?.id) {
                          return { ...not, isRead: true };
                        }
                        return not;
                      }));

                      if (n?.type === "APPOINTMENT" && user.user?.role !== "PET_HANDLER") {
                        navigate("/booking-reservation?query=appointment");
                      } else if (n?.type === "CHECKIN" && user.user?.role !== "PET_HANDLER") {
                        navigate("/booking-reservation?query=checkin");
                      } else if (n?.type === "CHECKOUT" && user.user?.role !== "PET_HANDLER") {
                        navigate("/booking-reservation?query=checkout");
                      } else if (user.user?.role == "PET_HANDLER" && n?.type === "APPOINTMENT" || n?.type === "CHECKIN" || n?.type === "CHECKOUT") {
                        navigate("/assigned-Booking");
                      }
                    } catch (error) {
                      console.log(error);
                    } finally {
                      closeModal();
                    }
                  }}

                  className="flex flex-row cursor-pointer items-center justify-between gap-4">
                  <img
                    className="w-auto h-6 self-center"
                    src="/notity.png" alt="notify-logo" />
                  <span className="w-full">
                    <h2 className={`font-bold ${n?.isRead ? `text-[#838383]` : "text-black"} `}>
                      {n?.title}
                    </h2>
                    <h3
                      className="flex items-center justify-start gap-2"
                    >
                      <p
                        className={`w-max ${!n?.isRead && `text-[#FF6B01]`}`}
                      >
                        {moment(n.createdAt).fromNow()}
                      </p>
                      <p>
                        | {n?.message}
                      </p>
                    </h3>
                  </span>
                  {!n?.isRead && <span
                    className="self-end "

                  >
                    <div className="w-6 h-6 cursor-pointer rounded-full bg-[#FF6B01]"></div>

                  </span>}
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};



const DashHeader = ({ title }) => {
  const dispatch = useDispatch();
  const [notificationModalOpen, setnotificationModalOpen] =
    React.useState(false);
  const handleNotificationModalClose = () => setnotificationModalOpen(false);
  const handleNotificationModalOpen = () => setnotificationModalOpen(true);
  const handleLogout = () => {
    dispatch(logout());
    window.location.replace("/login");
  };
  return (
    <nav className="flex items-center justify-between bg-white px-4 shadow-sm w-full sticky top-0 z-50">
      <div className="flex items-center">
        <img
          src={boop}
          alt="logo"
          className="w-10 h-12 md:w-[90px] md:h-[70px]"
        />
        <div className="flex md:ml-40">
          <span className="ml-4 text-lg font-semibold ">{title}</span>
        </div>
      </div>

      <div className="flex items-center gap-6">
        <div
          onClick={handleNotificationModalOpen}
          className="w-[56px] h-[56px] bg-[#FFEEDE] flex justify-center cursor-pointer items-center rounded-xl"
        >
          <NotificationsNoneOutlinedIcon className="text-[#DA9145]  " />
        </div>
        <div className="border-l-2 border-gray-400 h-16 opacity-25"></div>

        <div
          className=" flex p-2 rounded items-center justify-center bg-red-200 cursor-pointer"
          onClick={handleLogout}
        >
          <IoIosLogOut size={20} className="text-red-500" />
        </div>
        {/* <img
          src="https://s3-alpha-sig.figma.com/img/4e2a/1bed/07f0ab3e74fc2695293b7acded44329e?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cJcGaSVfbWSTTEz06YAMQPHYGC2nNcheg7LIiegn1gjzX141devLCYZ6UVk0fUgL-Q66M8RC2ZscgKA0T7vYvbMsDiGvy3t5JRp9g3DdbD4UYX0cPcLreGPjevFhhSgpVuaMLhQRSw2mBQVTDVIuLmqvWsSzQ1lqEKXpJgiAkDSUf~D535meKGFaKXZteEz5UWWfSfLJ0XUnrqDlxBmD-yUcQqx30YMvs8VEQiceBo3v15SoB~y8JXpUouLkyEJ395bZjll5vyPSgo-4V9wk2xVkDHmzglke9enG3OIP-XjwfIPakphfKK4M2TWfCi0dusNA86vLPCh~BhmvJ5V-mw__"
          alt="profile"
          className="w-10 h-8 rounded-full"
        /> */}

        <ModalWrapper
          p={2}
          width={600}
          open={notificationModalOpen}
          handleClose={() => setnotificationModalOpen(false)}
        >
          <NotificationModal closeModal={() => setnotificationModalOpen(false)} />
        </ModalWrapper>
      </div>
    </nav>
  );
};

export default DashHeader;
