import React, { useEffect, useState } from "react";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";

function DropOption({ label, value, selected, onClick }) {
  return (
    <label
      onClick={() => onClick(value)}
      className={`hover:bg-[var(--color-20)] hover:text-white hover:bg-orangeBg w-full p-2 rounded-sm cursor-pointer
        transition ease-in-out text-[var(--color-20)] font-semibold
        ${selected && "bg-[var(--color-20)] text-black"}`}
      value={value}
    >
      {label}
    </label>
  );
}
export default function Dropdown({
  name,
  label,
  myvalue,
  options,
  variable,
  placeholder,
  onChange,
  isError,
  errorText,
  mess,
  mess2,
}) {
  const [show, setshow] = useState(false);
  const [value, setvalue] = useState(null);
  const [mylabel, setlabel] = useState(`Select ${label}`);

  useEffect(() => {
    setlabel(
      (prev) =>
        options?.filter((rec) => rec?.id == value)[0]?.[variable] ||
        `${label ? label : ""}`,
    );
  }, [value]);
  useEffect(() => {
    setvalue(myvalue);
  }, [myvalue]);
  return (
    <div className="flex flex-col">
      <div className=" relative w-full" onMouseLeave={() => setshow(false)}>
        <label
          className="md:text-[1rem] max-sm:text-[0.9rem] font-bold select-none text-black"
          for={name}
        >
          {label}
        </label>
        <div
          name={name}
          className="w-full rounded-lg text-[1rem] font-semibold   bg-gray-100 p-2 text-black
      hover:bg-gray-100 transition-opacity  flex flex-col mt-2"
          onClick={() => setshow((prev) => !prev)}
        >
          <div className="flex items-center justify-between">
            {value ? (
              <label className="select-none text-black">
                {
                  options?.filter(
                    (rec) => rec?.[mess ? mess : variable] == value,
                  )[0]?.[mess2 ? mess2 : "id"]
                }
              </label>
            ) : (
              <label className="select-none text-gray-300">
                {placeholder ? placeholder : mylabel}
              </label>
            )}
            {show ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
          </div>
        </div>

        {show && (
          <div
            className="absolute  flex flex-col bg-gray-100 rounded-lg w-full items-start
        justify-start z-10 text-black"
          >
            {options?.map((rec) => {
              return (
                <DropOption
                  onClick={(value) => {
                    onChange(value);
                    setshow(false);
                  }}
                  selected={rec?.id == value}
                  value={rec.id}
                  label={rec[variable]}
                />
              );
            })}
          </div>
        )}
      </div>
      {isError && (
        <p className="ml-1 font-normal text-sm text-red-500">{errorText} </p>
      )}
    </div>
  );
}
