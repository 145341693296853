import React, { useEffect, useState } from "react";
import boop from "../../assets/images/boop.png";
import { Button } from "@mui/material";
import CustomBreadcrumbs from "../../components/AuthPages/CustomBreadcrumbs";
import DocumentsAndPermissions from "../../components/AuthPages/LaptopViewSignUp/DocumentsAndPermissions";
import admin from "../../assets/images/admin.svg";
import BoardingCenterTypes from "../../components/AuthPages/LaptopViewSignUp/BoardingCenterTypes";
import BoardingCenterData from "../../components/AuthPages/LaptopViewSignUp/BoardingCenterData";
import AccomodationTypes from "../../components/AuthPages/LaptopViewSignUp/AccomodationTypes";
import ServiceToProvide from "../../components/AuthPages/LaptopViewSignUp/ServiceToProvide";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRegisterMutation } from "store/features/auth/authApiSlice";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import config from "config/APIEndpoints";
const initialState = {
  service_name: "",
  service_phone_number: "",
  service_location: "",
  classification_1: "",
  classification_2: "",
  email: "",
  business_email: "",

  phone_number: "",
  landline_number: "",
  longitude_latitude: "",
  location_address: "",
  eatablishment_date: "",
  total_boarding_center_area: "",
  staff_to_pet_ratio: "",
  area_per_pet: "",
  amenities: "",
  city: "",
  state: "",

  max_pet_capacity: "",
  center_photo_id: [],
  accomodations: [],

  municipals_docs: [],
  company_incorporation_docs: [],
  legal_declaration_docs: [],
  additional_docs: [],
  services_provided: [],
};

const validationSchema = Yup.object({
  // service_name: Yup.string().required("Service Name is required"),
  // service_phone_number: Yup.string().required("Service phone is required"),
  // service_location: Yup.string().required("Service location is required"),
  // classification_1: Yup.string().optional(),
  // classification_2: Yup.string().optional(),
  // email: Yup.string()
  //   .email("Invalid email format")
  //   .required("Email is required"),
  // phone_number: Yup.string().optional(),
  // landline_number: Yup.string().optional(),
  // longitude_latitude: Yup.string().optional(),
  // location_address: Yup.string().optional(),
  // eatablishment_date: Yup.string().optional(),
  // total_boarding_center_area: Yup.string().optional(),
  // staff_to_pet_ratio: Yup.string().optional(),
  // area_per_pet: Yup.string().optional(),
  // amenities: Yup.string().optional(),
  // max_pet_capacity: Yup.string().optional(),
  // // center_photo_id: Yup.object().optional(),
  // accomodations: Yup.object().shape({
  //   accomodation_room_type_name: Yup.string().optional(),
  //   accomodation_number_of_rooms: Yup.string().optional(),
  //   accomodation_max_capacity_room: Yup.string().optional(),
  //   accomodation_price: Yup.string().optional(),
  //   disable_booking_inventory_full: Yup.boolean().optional(),
  //   picture_id: Yup.object().optional(),
  // }),
  // municipals_docs: Yup.array().optional(),
  // company_incorporation_docs: Yup.array().optional(),
  // legal_declaration_docs: Yup.array().optional(),
  // additional_docs: Yup.array().optional(),
  // services_provided: Yup.array().optional(),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const LaptopViewSignup = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const source = query.get("source");

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Add Documents & Permissions",
    "Select Type of Boarding Centre",
    "Add Boarding Center Data",
    "Add Accommodation Type",
    "Add Services to be Provided",
  ];

  const BreadcrumbSteps = [
    ["Documents &", "Permissions"],
    ["Select Type of", "Boarding Centre"],
    ["Boarding", "Center Data"],
    ["Add Accommodation", "Type"],
    ["Add Services", "Provided"],
  ];

  const [register, { isLoading, isError, isSuccess, error }] =
    useRegisterMutation();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const { token } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (!source || source !== "admin") {
      if (token) {
        navigate("/dashboard");
      }
    }
  }, [token, source]);



  useEffect(() => {
    if (isSuccess) {
      toast.success("Registered successfully");
      if (source == "admin") {
        window.location = config.getAdmin();
      } else navigate("/login");
    }

    if (isError) {
      toast.error(error?.data?.message || "Error registering");
    }
  }, [isSuccess, isError, error, navigate]);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // service payload
      const {
        accomodations,
        municipals_docs,
        company_incorporation_docs,
        legal_declaration_docs,
        additional_docs,
        services_provided,
        from_time,
        to_time,
        ...service_payload
      } = values;
      const payload = { ...service_payload };
      payload.center_photo_id = values?.center_photo_id || null;
      // payload for accomodations
      payload.accomodations = accomodations.map((rec) => ({
        ...rec,
      }));
      payload.services_provided = services_provided?.map((rec) => ({
        ...rec,

        pricing: rec?.pricing
          ?.filter((rec) => rec.price != null)
          .map((rec) => ({
            checkin_time: rec?.checkin_time || null,
            checkout_time: rec?.checkout_time || null,
            price: rec?.price,
            price_modal: rec?.price_modal,
          })),
        servicePhotos: rec?.servicePhotos.map((rec) => ({
          file_id: rec?.id,
        })),
      }));
      // payload for municipals_docs
      payload.municipals_docs = municipals_docs?.length
        ? municipals_docs?.map((doc) => ({ file_id: doc.id }))
        : [];
      // payload for company_incorporation_docs
      payload.company_incorporation_docs = company_incorporation_docs?.length
        ? company_incorporation_docs?.map((doc) => ({ file_id: doc.id }))
        : [];
      // payload for legal_declaration_docs
      payload.legal_declaration_docs = legal_declaration_docs?.length
        ? legal_declaration_docs?.map((doc) => ({ file_id: doc.id }))
        : [];
      // payload for additional_docs
      payload.additional_docs = additional_docs?.length
        ? additional_docs?.map((doc) => ({ file_id: doc.id }))
        : [];
      // payload for services_provided

      await register(payload);
      // .then((resp) => {
      // console.log(source == "admin", resp);
      // if (source == "admin") {
      //   window.location = "";
      // }
      // });
    },
  });
  useEffect(() => { }, [formik.errors]);
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DocumentsAndPermissions
            parentformik={formik}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <BoardingCenterTypes
            formik={formik}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <BoardingCenterData
            myformik={formik}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <AccomodationTypes
            myformik={formik}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <ServiceToProvide
            myformik={formik}
            handleregister={formik.handleSubmit}
            handleBack={handleBack}
          />
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div>
        <img
          src={boop}
          alt="logo"
          className="w-40 h-32 md:w-[200px] md:h-[150px]"
        />
      </div>

      <div className="w-full max-w-6xl flex flex-col items-start gap-2.5">
        <div className="w-full max-w-xl flex flex-col items-start gap-4">
          <div className="flex items-start gap-3 md:gap-4">
            <div className="h-10 w-10 rounded-full bg-orange-400 border-2 border-orange-400 flex justify-center items-center">
              <div className="text-white text-xl font-normal font-['Poppins']">
                {activeStep + 1}
              </div>
            </div>
            <div className="text-black text-2xl md:text-3xl font-bold">
              {steps[activeStep]}
            </div>
          </div>
          <div className="md:w-full w-[320px]  opacity-10 rounded-lg border-b-2 border-black mt-2 md:mr-0 mr-9 "></div>
        </div>

        <div className="w-full px-4 md:px-8 ">
          <CustomBreadcrumbs
            steps={BreadcrumbSteps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>

        <div className="w-full flex flex-row items-start gap-1">
          <div className="text-gray-700 text-sm md:text-base font-bold font-['Nunito Sans'] tracking-tight mt-4 md:mt-7">
            Note: If you fill in all the details, you'll get a verified badge
          </div>
          <img
            src={admin}
            alt="admin"
            className="w-4 h-4 mt-5 md:mt-8 md:mr-0 mr-10 "
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="w-full">
          <div className="w-full mt-6 space-y-8 md:space-y-12">
            {renderStepContent(activeStep)}
          </div>

          {/* <div className="flex justify-end w-full mt-8 md:mt-12 space-x-4">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                textTransform: "none",
              }}
              type="button"
            >
              <div className="px-4 py-2 md:px-7 md:py-2 bg-[#FFEEDE] rounded-xl">
                <span className="text-orangeBg text-sm md:text-md">
                  Previous
                </span>
              </div>
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleNext}
                sx={{
                  textTransform: "none",
                }}
              >
                <button
                  className="text-white text-sm md:text-md px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl"
                  type="submit"
                >
                  {isLoading ? "Registering..." : "Register as Pet Boarder"}
                </button>
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                sx={{
                  textTransform: "none",
                }}
              >
                <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
                  <span className="text-white text-sm md:text-md">Next</span>
                </div>
              </Button>
            )}
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default LaptopViewSignup;
