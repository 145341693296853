import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import profilePic from "../../assets/images/profile_pic.png";
import ViewProfileFields from "../../components/Common/ui/ViewProfileFields";
import ViewProfileInfo from "../../components/Common/ui/ViewProfileInfo";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Button from "components/Common/ui/Button";
import {
  useGetOneStaffQuery,
  useUpdateStaffMutation,
} from "store/features/staff/staffApiSlice";
import toast from "react-hot-toast";

const ViewStaffDetails = () => {
  const { id } = useParams();

  const { isLoading, data } = useGetOneStaffQuery(id);

  const [
    updateStaff,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateStaffMutation();

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Staff blocked sucessfully!");
    }

    if (updateError) {
      toast.error("Failed to block staff!");
    }
  }, [updateSuccess, updateError]);

  const navigate = useNavigate();
  return (
    <Container title={"Pet Parent Management"}>
      <InfoPageHeader
        title={"Staff Details"}
        backBtnFunction={() => navigate(-1)}
      />

      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
            <div>
              <img
                src={profilePic}
                alt="profile_pic"
                className="h-[240px] w-[240px] "
              />
            </div>
            <div className="col-span-2">
              <div className="flex flex-col space-y-2">
                <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                  <ViewProfileFields
                    title="First Name"
                    value={data?.data?.first_name || "-"}
                  />
                  <ViewProfileFields
                    title="Last Name"
                    value={data?.data?.last_name || "-"}
                  />
                </div>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                  <ViewProfileFields
                    title="Phone Number"
                    value={data?.data?.phone_number || "-"}
                  />
                  <ViewProfileFields
                    title="Joined Date"
                    value={data?.data?.joined_date || "-"}
                  />
                </div>
                {/* <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                  <ViewProfileFields
                    title="First Name"
                    value={"data?.pet?.name"}
                  />
                  <ViewProfileFields
                    title="Last Name"
                    value={"data?.pet?.type"}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-3">
            <div className="flex flex-col space-y-4 mt-8">
              <p className="font-semibold text-[#D99149] text-[20px]">
                Additional Details
              </p>
              <div className="flex flex-col space-y-2">
                <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                  <ViewProfileFields
                    title="Altername Phone Number"
                    value={data?.data?.alternate_phone_number || "-"}
                  />
                  <ViewProfileFields
                    title="Email"
                    value={data?.data?.email || "-"}
                  />
                  <ViewProfileFields
                    title="Role"
                    value={data?.data?.role || "-"}
                  />
                  <ViewProfileFields
                    title="Shift"
                    value={data?.data?.shift || "-"}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-4 mt-8">
              <p className="font-semibold text-[#D99149] text-[20px]">
                Documents
              </p>
              <div className="flex flex-col space-y-2">
                <div className="grid md:grid-cols-5 grid-cols-1 gap-3">
                  <ViewProfileInfo
                    title={"Document Name"}
                    contents={
                      <div className="flex flex-row justify-between items-center">
                        <p className="mr-2">Document</p>
                        <Button>View</Button>
                      </div>
                    }
                    topActionButton={
                      <button
                        className="bg-faintOrangeBg cursor-pointer text-darkOrangeBg rounded-full flex items-center justify-center h-[30px] w-[30px]"
                        //onClick={() => setAddPetParentModal(true)}
                      >
                        <ModeEditOutlineOutlinedIcon
                          style={{ height: 15, width: 15 }}
                        />
                      </button>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col  space-y-3 lg:col-span-2 justify-start">
              <p className="font-semibold text-[#D99149] text-[20px]">
                Additional Details
              </p>
              <div className="flex space-x-3 items-center">
                <p className="text-lg font-bold">Block Staff</p>
                <button
                  className="bg-red-500 text-white px-3 py-1 rounded"
                  onClick={() => {
                    const isConfirm = window.confirm(
                      "Are you sure you want to block this staff?",
                    );
                    console.log(isConfirm);
                    if (isConfirm)
                      updateStaff({ id, blocked: !data?.data?.blocked });
                  }}
                >
                  {updateLoading
                    ? "Updating..."
                    : data?.data?.blocked
                      ? "Unblock"
                      : "Block"}
                </button>
                {/*  <ToggleSwitch handleChange={(val) => console.log(val, 'yes', 'no')} /> */}
              </div>
            </div>
          </div>

          <div className="flex flex-row space-x-2 items-center min-w-2/5 mt-5">
            <span className="w-[300px]">
              <Button
                onClick={() =>
                  navigate(`/staff-management/profile/booking/${id}`)
                }
              >
                View Related Bookings
              </Button>
            </span>
            <span className="w-[177px]">
              <Button>View Logs</Button>
            </span>
          </div>
        </>
      )}
    </Container>
  );
};

export default ViewStaffDetails;
