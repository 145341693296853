import { apiSlice } from "../apiSlice";
import { setServiceInfo } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (data) => ({
        url: "api/v1/pet-service/auth/send-otp",
        method: "POST",
        body: data,
      }),
    }),
    sendVerifyOtp: builder.mutation({
      query: (data) => ({
        url: "api/v1/pet-service/auth/send-email-verification",
        method: "POST",
        body: data,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (data) => ({
        url: "api/v1/pet-service/auth//verify-email-verification",
        method: "POST",
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/api/v1/pet-service/auth/login",
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/api/v1/pet-service/auth/register",
        method: "POST",
        body: data,
      }),
    }),
    checkUser: builder.mutation({
      query: (data) => ({
        url: "api/v1/pet-service/auth/send-otp",
        method: "POST",
        body: data,
      }),
    }),
    getOneService: builder.query({
      query: () => ({
        url: "/api/v1/pet-service/auth/fetch-info",
        method: "GET",
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          const { data } = response;
          dispatch(setServiceInfo(data?.data));
        });
      },
    }),
  }),
});

export const {
  useVerifyEmailMutation,
  useSendVerifyOtpMutation,
  useSendOtpMutation,
  useLoginMutation,
  useRegisterMutation,
  useCheckUserMutation,
  useGetOneServiceQuery,
} = authApiSlice;
