import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";

const CustomBreadcrumbs = ({ steps, activeStep, setActiveStep }) => {
  return (
    <div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<MdKeyboardArrowRight size={30} />}
      >
        {steps.map((label, index) => (
          <div
            key={index}
            className="flex items-center gap-6 sm:gap-10 md:gap-6 relative"
          >
            <div
              className={`h-[24px] px-[12px] py-[15px] ${
                activeStep === index
                  ? "bg-orange-400 border-2 border-orange-400 text-white"
                  : "bg-white border border-neutral-300 text-gray-700"
              } rounded-full flex items-center justify-center`}
            >
              <div className="text-xs font-normal leading-[18px] font-['Nunito Sans']">
                {index + 1}
              </div>
            </div>
            <Link
              underline="none"
              color={activeStep === index ? "textPrimary" : "inherit"}
              // onClick={() => setActiveStep(index)}
              href="#"
              className={`text-xs leading-[18px] ${
                activeStep === index
                  ? "font-bold text-orange-400"
                  : "font-normal text-gray-700"
              }`}
            >
              {label.map((line, i) => (
                <span key={i} className="block">
                  {line}
                </span>
              ))}
            </Link>
            {activeStep === index && (
              <div className="absolute bottom-[-22px] w-[170px] h-[4px] bg-orange-400 md:block hidden " />
            )}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbs;
