import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valdiate: {
    service: false,
    accommodation: false,
  },
};

const stepFormSlice = createSlice({
  name: "stepform",
  initialState,
  reducers: {
    validateservices: (state, action) => {
      state.valdiate.service = true;
    },
  },
});

export const { validateservices } = stepFormSlice.actions;

const stepformreducer = {
  stepform: stepFormSlice.reducer,
};

export default stepformreducer;
