import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import { IoMan } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import PetsIcon from "@mui/icons-material/Pets";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CiGrid41 } from "react-icons/ci";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Navigate, useLocation } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import Dashboard from "../components/DashBoard/Dashboard";
import BookingReservation from "../components/DashBoard/BookingReservation";
import PetManagement from "../components/DashBoard/PetManagement";
import PetParentManagement from "../components/DashBoard/PetParentManagement";
import StaffManagement from "../components/DashBoard/StaffManagement";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AssignedBookings from "pages/assignedBooking";
const DashBoardSideBar = () => {
  const navigate = useNavigate();

  const isAuthenticated = JSON.parse(
    localStorage.getItem("boop_service_user"),
  )?.token;

  const userFromLocalStorage = localStorage.getItem("boop_service_user")
    ? JSON.parse(localStorage.getItem("boop_service_user"))
    : null;


  // console.log(userFromLocalStorage)

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const menustate = useSelector((state) => state.layoutmenu);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setSelectedItem(menustate);
  }, [menustate]);

  const handleItemClick = (item) => {
    navigate(item?.route);
    if (isSmallScreen) {
      setOpen(false);
    }
  };
  const location = useLocation();

  const sidebarItems = [
    {
      key: "dashboard",
      text: "Dashboard",
      pageTitle: "Dashboard",
      route: "/dashboard",
      icon: <CiGrid41 />,
      component: <Dashboard />,
      auth: ["ADMIN", "MANAGER"]
    },
    {
      key: "booking",
      text: "Booking Reservation",
      pageTitle: "Booking Reservation",
      route: "/booking-reservation",
      icon: <MdOutlineCalendarMonth />,
      component: <BookingReservation />,
      auth: ["ADMIN", "MANAGER"]
    },
    {
      key: "pet",
      text: "Pet Management",
      pageTitle: "Pet Management",
      route: "/pet-management",
      icon: <PetsIcon fontSize="15" />,
      component: <PetManagement />,
      auth: ["ADMIN", "PET_HANDLER", "MANAGER"]
    },
    {
      key: "petparent",
      text: "Pet Parent Management",
      pageTitle: "Pet Parent Management",
      route: "/pet-parent-management",
      icon: <IoMan />,
      component: <PetParentManagement />,
      auth: ["ADMIN", "PET_HANDLER", "MANAGER"]
    },
    {
      key: "staff",
      text: "Staff Management",
      pageTitle: "Staff Management",
      route: "/staff-management",
      icon: <CgProfile />,
      component: <StaffManagement />,
      auth: ["ADMIN"]
    },
    {
      key: "accomodation",
      text: "Accommodation Management",
      pageTitle: "Accomodation Management",
      route: "/accomadation-management",
      icon: <CgProfile />,
      component: <StaffManagement />,
      auth: ["ADMIN"]
    },
    {
      key: "service",
      text: "Service Management",
      pageTitle: "Staff Management",
      route: "/service-management",
      icon: <CgProfile />,
      component: <StaffManagement />,
      auth: ["ADMIN"]
    },
    {
      key: "AssignedBookings",
      text: "Assigned Bookings",
      pageTitle: "Assigned Bookings",
      route: "/assigned-Booking",
      icon: <MdOutlineCalendarMonth />,
      component: <AssignedBookings />,
      auth: ["PET_HANDLER"]
    },
  ].filter((d) => d?.auth?.includes(userFromLocalStorage?.user?.role))

  const getItemStyle = (item) => {
    if (location.pathname.split("/")[1] === item.route.split("/")[1]) {
      return {
        backgroundColor: "#FFD9B4",
        color: "#D99149",
        fontWeight: "bold",
      };
    }
    return {
      color: "#9CA2AA",
      fontWeight: "900",
    };
  };
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <div style={{ display: "flex" }}>

      {isSmallScreen ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className="fixed top-4 left-4 z-10"
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer}
            sx={{ marginTop: "4rem", zIndex: 1000 }}
          >
            <List className="mt-16">
              {sidebarItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleItemClick(item)}
                  style={getItemStyle(item)}
                >
                  <ListItemIcon
                    style={{
                      color:
                        selectedItem && selectedItem === item.key
                          ? "#C05621"
                          : "inherit",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    style={{
                      fontWeight:
                        selectedItem && selectedItem === item.key
                          ? "bold"
                          : "normal",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              marginTop: "4rem",
              zIndex: 1000,
            },
          }}
        >
          <List>
            {sidebarItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleItemClick(item)}
                style={getItemStyle(item)}
              >
                <ListItemIcon
                  style={{
                    color:
                      selectedItem && selectedItem.text === item.text
                        ? "#C05621"
                        : "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  style={{
                    fontWeight:
                      selectedItem && selectedItem.text === item.text
                        ? "bold"
                        : "bold",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
      {/* <main style={{ flexGrow: 1, padding: theme.spacing(3), backgroundColor:"#FFEBEE", zIndex: 0 }}>
                {selectedItem && selectedItem.component}
            </main> */}

      <main className="w-full h-[100vh] overflow-y-scroll  bg-[#F6F6F6] pb-10">
        <Outlet />
      </main>
    </div>
  );
};

export default DashBoardSideBar;
