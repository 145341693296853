import React from "react";

const IconButton = ({ children, onClick, ...props }) => {
  return (
    <button
      onClick={onClick}
      {...props}
      className={`bg-[#FFEEDE] cursor-pointer text-[#D99149] rounded-full flex items-center justify-center h-[30px] w-[30px] ${props.className}`}
    >
      {children}
    </button>
  );
};

export default IconButton;
