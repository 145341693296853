import DashHeader from 'layout/DashHeader'
import React from 'react'

const Container = ({ className, children, title }) => {
  return (
    <>
    <DashHeader title={title} />
    <div className={`${className} px-14 pt-3`}>{children}</div>
    </>
  )
}

export default Container