import React from "react";
import { FiClock } from "react-icons/fi";

const ServiceCard = ({ title, time, petName, tags, price, status }) => {
  return (
    <div className="w-full p-4 bg-white rounded-lg shadow-2xl flex flex-col justify-start items-start gap-2 relative">
      <div className="absolute top-0 left-0 w-24 h-24 rounded-tl-lg bg-orange-200 rounded-br-full"></div>
      <div className="relative flex justify-between  items-center w-full">
        <div>
          <div className="text-black text-lg font-bold">{title}</div>
          <div className="text-gray-700 text-[14px] font-medium">
            Pet Name - <span>{petName}</span>
          </div>
        </div>
        <div className="text-right">
          <div className="text-neutral-800 text-lg font-bold">₹{price}</div>
          <div className="flex items-center gap-1 text-black text-sm">
            <FiClock />
            <div>{time}</div>
          </div>
          <div className="w-32 h-6 flex justify-center items-center relative">
        <div className="w-full h-full absolute opacity-20 bg-teal-500 rounded-md"></div>
        <div className="text-center text-teal-500 text-xs p-1 font-bold">
          {status}
        </div>
      </div>
        </div>
      </div>
   
      {/* <div className="flex flex-col items-start gap-1 w-full">
        <div className="text-black text-xs font-semibold">Tags -</div>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <div
              key={index}
              className="px-2 py-1 bg-orange-300 rounded-lg text-neutral-800 text-xs font-medium"
            >
              {tag}
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ServiceCard;
