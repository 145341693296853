

export const  Dateservices = [
    {
      title: "Boarding",
      time: "10:00 - 11:00 AM",
      petName: "Tommy",
      tags: ["Fever", "Allergic to tablets"],
      price: 499,
      status: "Task Completed",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
    {
      title: "Grooming",
      time: "12:00 - 1:00 PM",
      petName: "Bella",
      tags: ["Haircut", "Nail Trim"],
      price: 799,
      status: "Ongoing",
    },
  
  ];
