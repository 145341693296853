import React from "react";
import "../../../App.css";
import { Button } from "@mui/material";

const BoardingCenterTypes = ({ formik, handleNext, handleBack }) => {
    const { values, handleBlur, handleChange, errors, touched, setValues } =
        formik;
    return (
        <div className="w-full mx-auto bg-white rounded-lg ">
            <h1 className="text-lg font-bold text-orange-400 mb-4">
                Add Classification Type
            </h1>
            <div className="w-[18%] border-b-[1.5px] border-orange-500 opacity-75 mb-4 -mt-2 "></div>
            <div className="mb-6">
                <h2 className="text-xl font-semibold text-neutral-800">
                    Which Classification does your service belong to?
                </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="p-4 border rounded-lg">
                    <h3 className="text-lg font-bold text-neutral-800 mb-2">Inbound</h3>
                    <div className="border-b border-orange-400 mb-4"></div>
                    <div className="space-y-2">
                        <label className="flex items-center custom-radio">
                            <input
                                type="checkbox"
                                name="classification_1"
                                className="form-radio text-orange-400"
                                value={"homeservicing"}
                                checked={values.classification_1 === "homeservicing"}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({ ...values, classification_1: "homeservicing" });
                                    else setValues({ ...values, classification_1: "" });
                                }}
                                onBlur={handleBlur}
                            />
                            <span className="ml-2 text-black">Home Service</span>
                        </label>
                    </div>
                </div>
                <div className="p-4 border rounded-lg">
                    <h3 className="text-lg font-bold text-neutral-800 mb-2">Outbound</h3>
                    <div className="border-b border-orange-400 mb-4"></div>
                    <div className="space-y-2">
                        <label className="flex items-center  custom-radio">
                            <input
                                type="checkbox"
                                name="classification_2"
                                checked={values.classification_2 === "indoorkennel"}
                                onChange={(e) => {
                                    if (
                                        e.target.checked &&
                                        values.classification_2 !== "indoorkennel"
                                    )
                                        setValues({ ...values, classification_2: "indoorkennel" });
                                    else if (values.classification_2 == "indoorkennel")
                                        setValues({ ...values, classification_2: "" });
                                }}
                                onBlur={handleBlur}
                                className="form-radio text-orange-400"
                                value="indoorkennel"
                            />
                            <span className="ml-2 text-black"> Indoor Kennel</span>
                        </label>
                        <label className="flex items-center  custom-radio">
                            <input
                                type="checkbox"
                                name="classification_2"
                                checked={values.classification_2 === "homeboarding"}
                                onChange={(e) => {
                                    if (
                                        e.target.checked &&
                                        values.classification_2 !== "homeboarding"
                                    )
                                        setValues({ ...values, classification_2: "homeboarding" });
                                    else if (values.classification_2 == "homeboarding")
                                        setValues({ ...values, classification_2: "" });
                                }}
                                onBlur={handleBlur}
                                className="form-radio text-orange-400"
                                value="homeboarding"
                            />
                            <span className="ml-2 text-black">Home Boarding</span>
                        </label>
                        <label className="flex items-center  custom-radio">
                            <input
                                type="checkbox"
                                name="classification_2"
                                className="form-radio text-orange-400"
                                value="Individual Pet Sitter"
                                checked={values.classification_2 == "individualsitter"}
                                onChange={(e) => {
                                    if (
                                        e.target.checked &&
                                        values.classification_2 !== "individualsitter"
                                    )
                                        setValues({
                                            ...values,
                                            classification_2: "individualsitter",
                                        });
                                    else if (values.classification_2 == "individualsitter")
                                        setValues({ ...values, classification_2: "" });
                                }}
                                onBlur={handleBlur}
                            />
                            <span className="ml-2 text-black">Individual Pet Sitter</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="w-fit float-right flex flex-row gap-5 mt-5">
                    <Button
                        onClick={handleBack}
                        sx={{
                            textTransform: "none",
                        }}
                        type="button"
                    >
                        <div className="px-4 py-2 md:px-7 md:py-2 bg-[#FFEEDE] rounded-xl">
                            <span className="text-orangeBg text-sm md:text-md">Previous</span>
                        </div>
                    </Button>
                    <Button
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            handleNext();
                        }}
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
                            <span className="text-white text-sm md:text-md">Next</span>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BoardingCenterTypes;
