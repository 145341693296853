import React from "react";

const Textarea = ({
  className,
  label,
  name,
  id,
  rows,
  cols,
  bordered = false,
  ...props
}) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="font-poppins text-base font-semibold text-[#1C2135]"
        >
          {label}
        </label>
      )}
      <textarea
        className={`w-full min-h-12 px-4 py-2 mt-2 border border-[#BDC1CA] rounded-lg focus:outline-none focus:border-[#BDC1CA] bg-[#ffffff] ${className}`}
        id={id}
        name={name}
        rows={rows}
        cols={cols}
        {...props}
      />
    </div>
  );
};

export default Textarea;
