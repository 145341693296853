import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/APIEndpoints";

const baseQuery = fetchBaseQuery({
  baseUrl: config.getEndpoint(),
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.auth?.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "Staff",
    "PetParent",
    "PetProfile",
    "Appointment",
    "Accommodations",
    "Service",
  ],
  endpoints: (builder) => ({}),
});
