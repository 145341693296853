import React from "react";

const StatsCard = ({ bgColor, heading, number }) => {
  return (
    <div
      className={`w-72 h-32 p-4 ${bgColor} rounded-2xl flex flex-col justify-start items-start gap-1`}
    >
      <div className="h-6 pb-1 rounded-lg flex justify-center items-center">
        <div className="flex flex-col justify-center items-start">
          <div className="text-zinc-900 text-sm font-medium leading-6 font-['Poppins']">
            {heading}
          </div>
        </div>
      </div>
      <div className="self-stretch h-11 pl-1 pr-16 pt-1 rounded-lg flex justify-start items-center">
        <div className="text-black text-4xl   font-semibold .interfont">
          {number}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
