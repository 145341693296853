import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const RatingCard = ({ img, ratingNumber }) => {
  return (
    <div className="flex flex-col space-y-3">
      <img alt="dog_rating" src={img} className="h-[70px] w-[65px] rounded" />

      <div className="flex flex-row space-x-3 justify-center items-center text-bold text-[12px] border rounded border-[#D99149] px-[14px] py-[6px]">
        <StarBorderIcon style={{ height: 16, width: 16, color: "#D99149" }} /> {ratingNumber}
      </div>
    </div>
  );
};

export default RatingCard;
