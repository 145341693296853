import React from "react";

const ColumnContainer = ({ className, ySpace = 3, xSpace = 3, children }) => {
  return (
    <div
      className={`flex flex-col space-y-${ySpace} space-x-${xSpace} ${className}`}
    >
      {children}
    </div>
  );
};

export default ColumnContainer;
