import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const InfoPageHeader = ({ className, title, width = 200, backBtnFunction }) => {
  return (
    <div className="flex flex-col items-start mt-4 mb-5">
      <div className="flex flex-row items-center space-x-3">
        <button className="w-[50px] h-[50px] flex items-center justify-center p-[10px] rounded-[6px] bg-[#D99149]" onClick={backBtnFunction}>
          <KeyboardArrowLeftIcon style={{ color: "white", height: 38 }} />
        </button>
        <p className="text-[45px] font-poppins font-bold">{title}</p>
      </div>
      <div className={`w-[${width}px] bg-[#D99149] h-[1px]`}></div>
    </div>
  );
};

export default InfoPageHeader;
