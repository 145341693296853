import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import SingleSelect from "components/Common/ui/SingleSelect";
import DataTable from "components/Common/ui/Table";
import TableContainer from "components/Common/ui/TableContainer";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "components/Common/ui/IconButton";
import Button from "components/Common/ui/Button";

const RelatedBooking = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const columns = () => [
    {
      accessor: "id",
      Header: "Booking ID",
    },
    {
      accessor: "Pet Name",
      Header: "Pet Name",
    },
    {
      accessor: "Check-in-date",
      Header: "Check-In Date",
    },
    {
      accessor: "Check-Out Date",
      Header: "Check-Out Date",
    },
    {
      accessor: "Additional Services",
      Header: "Additional Services",
    },
    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton onClick={() => navigate(`/pet-parent/${d?.id}`)}>
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          //onClick={() => handleEdit(d?.id)}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          //onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "View",
    },
  ];
  return (
    <Container title={"Pet Parent Management"}>
      <InfoPageHeader
        title={"Related Bookings"}
        backBtnFunction={() => navigate(-1)}
      />

      <TableContainer title="Check-in">
        <DataTable
          columns={columns()}
          data={[]}
          /* filterArea={
            <TableFilterContainer>
              <SingleSelect
                showLabel={false}
                className="!bg-white"
                label={"Type"}
                options={[]}
                id={"type"}
                name="type"
                onChange={(e) => {}}
              />

              <SingleSelect
                showLabel={false}
                className="!bg-white"
                label={"Age"}
                options={[]}
                id={"age"}
                name="age"
                onChange={(e) => {}}
              />
            </TableFilterContainer>
          } */
        />
      </TableContainer>

      <div className="flex flex-row space-x-2 items-center min-w-2/5 mt-5">
        <span className="w-[300px]">
          <Button onClick={() => navigate(`/staff-management/profile/${id}`)}>
            Personal Information
          </Button>
        </span>
        <span className="w-[177px]" onClick={() => navigate(`/staff-management/profile/logs/${id}`)}>
          <Button>View Logs</Button>
        </span>
      </div>
    </Container>
  );
};

export default RelatedBooking;
