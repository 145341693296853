import { useEffect, useState } from "react";

const StatsPercentage = ({ percentage, color = "#FF5C5C", label = "" }) => {
  const validPercentage = isNaN(percentage) ? 0 : percentage;
  const [progress, setProgress] = useState(validPercentage);
  const size = 110;
  const circleRadius = size / 2 - 10;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const progressOffset = circleCircumference - (progress / 100) * circleCircumference;

  useEffect(() => {
    setProgress(validPercentage);
  }, [validPercentage]);

  return (
    <div className="relative flex flex-col items-center" style={{ width: size, height: size + 30 }}>
      <svg
        className="w-full h-full"
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={circleRadius}
          fill="none"
          stroke="#ddd"
          strokeWidth="10"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={circleRadius}
          fill="none"
          stroke={color}
          strokeWidth="10"
          strokeLinecap="butt"
          strokeDasharray={circleCircumference}
          strokeDashoffset={progressOffset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill={color}
          className="text-lg"
        >
          {progress}%
        </text>
      </svg>
      {label && <div className="text-center text-black text-[10px] font-semibold mt-2">{label}</div>}
    </div>
  );
};

export default StatsPercentage;
