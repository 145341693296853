import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import RatingCard from "components/Common/ui/RatingCard";
import ViewProfileFields from "components/Common/ui/ViewProfileFields";
import React from "react";
import SatisfiedImg from "assets/images/satisfied.png";
import PawsomeImg from "assets/images/Pawsome.png";
import HappyImg from "assets/images/happy.png";
import DissatisfiedImg from "assets/images/Dissatisfied.png";
import RowContainer from "components/Common/ui/RowContainer";
import Input from "components/Common/ui/Input";
import Textarea from "components/Common/ui/Textarea";
import ColumnContainer from "components/Common/ui/ColumnContainer";
import Button from "components/Common/ui/Button";
import { useNavigate } from "react-router-dom";

const ViewAppointment = () => {
  const navigate = useNavigate();
  return (
    <Container title={"Reservation Booking"}>
      <InfoPageHeader
        title={"Appointment"}
        backBtnFunction={() => navigate(-1)}
      />

      <div className="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-3">
        <ViewProfileFields title={"Booking ID"} value={"1"} />
        <ViewProfileFields title={"Service"} value={"1"} />
        <ViewProfileFields title={"Pet Name"} value={"1"} />
        <ViewProfileFields title={"Pet ID"} value={"1"} />
        <ViewProfileFields title={"Administered Date"} value={"1"} />
        <ViewProfileFields title={"Time"} value={"1"} />
        <ViewProfileFields title={"Location"} value={"1"} />
        <ViewProfileFields
          title={"Grooming Services(if grooming)"}
          value={"1"}
        />
        <ViewProfileFields title={"Room Type (if boarding)"} value={"1"} />
        <div className="flex flex-col space-y-2">
          <span className="text-[16px] font-bold">Status</span>
          <span className="text-center text-[16px] px-[9px] py-[6px] w-full font-semibold rounded-md bg-[#00B69B]/20 text-[#00B69B]">
            Task Completed
          </span>
        </div>
        <ViewProfileFields title={"Booking Value"} value={"1"} />
      </div>

      <div className="flex flex-col space-y-2 mt-5">
        <span className="text-[16px] font-bold">Rating & Feedback</span>
        <span className="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-3 ">
          <div className="p-[22px] flex flex-row space-x-4 overflow-x-auto w-full bg-white rounded">
            <RatingCard img={PawsomeImg} ratingNumber={1} />
            <RatingCard img={PawsomeImg} ratingNumber={2} />
            <RatingCard img={PawsomeImg} ratingNumber={3} />
            <RatingCard img={PawsomeImg} ratingNumber={4} />
            <RatingCard img={PawsomeImg} ratingNumber={5} />
          </div>
          <div className="md:col-span-2">
            <form>
              <ColumnContainer xSpace={0}>
                <Input
                  id={"title"}
                  name="title"
                  placeholder="Enter title"
                  bordered
                  /* value={values?.title}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.title && touched?.title}
                errorText={errors?.title} */
                />

                <Textarea
                  id={"description"}
                  name="description"
                  placeholder="Enter Description"
                  cols={10}
                  rows={4}
                  /*  value={values?.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.description && touched?.description}
                  errorText={errors?.description} */
                />

                <Button type="submit">Submit Review</Button>
              </ColumnContainer>
            </form>
          </div>
        </span>
      </div>

      <div className="flex flex-row space-x-2 md:mt-8 mt-5">
        <span className="md:w-16">
          <Button>Edit</Button>
        </span>

        <span className="">
          <button className="bg-red-600/20 text-red-600 rounded-lg w-full h-[37.023px] flex items-center justify-center px-10">
            Delete Record
          </button>
        </span>
      </div>
    </Container>
  );
};

export default ViewAppointment;
