import { createSlice } from "@reduxjs/toolkit";
const LayoutMenuSlice = createSlice({
  name: "layoutmenu",
  initialState: "",
  reducers: {
    setmenu: (state, action) => {
      return action.payload;
    },
  },
});

export const { setmenu } = LayoutMenuSlice.actions;
const layoutreducer = {
  layoutmenu: LayoutMenuSlice.reducer,
};

export default layoutreducer;
