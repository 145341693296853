import { apiSlice } from "../apiSlice";
import { setPetProfiles } from "./pet_profileSlice";

export const petProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPet: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PetProfile"],
    }),
    getPetProfile: builder.query({
      query: () => ({
        url: "/api/v1/petparent/pets",
        method: "GET",
      }),
      invalidatesTags: ["PetProfile"],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          const { data } = response;
          dispatch(
            setPetProfiles({
              data: data?.pets,
              pagination: { count: data?.pets?.length },
            }),
          );
        });
      },
    }),
    getAllPets: builder.query({
      query: (queryParam) => ({
        url: queryParam
          ? `/api/v1/pet-service/pet-parent/parent/pets?${queryParam}`
          : `/api/v1/pet-service/pet-parent/parent/pets`,
        method: "GET",
      }),
      providesTags: ["PetProfile"],
    }),
    dasboardStat: builder.query({
      query: () => ({
        url: `api/v1/pet-service/service-management/stat/pet-management`,
        method: "GET",
      }),
      providesTags: ["PetProfile"],
    }),
    getOnePet: builder.query({
      query: (id) => ({
        url: `/api/v1/pet-service/pet-parent/pets/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["PetProfile"],
    }),
    deletePet: builder.mutation({
      query: (id) => ({
        url: `/api/v1/pet-service/pet-parent/pets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PetProfile"],
    }),
  }),
});

export const {
  useGetPetProfileQuery,
  useCreatePetMutation,
  useGetOnePetQuery,
  useDasboardStatQuery,
  useDeletePetMutation,
  useGetAllPetsQuery
} = petProfileApiSlice;
