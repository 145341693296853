import React from "react";

const ViewProfileFields = ({ title, value }) => {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-[16px] font-bold">{title}</span>
      <span className="text-[16px] px-[9px] py-[6px] bg-white w-full font-normal rounded">
        {value ? value : "-"}
      </span>
    </div>
  );
};

export default ViewProfileFields;
