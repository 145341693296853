import { apiSlice } from "../apiSlice";

const appointmentApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createAppointment: builder.mutation({
            query: (data) => ({
                url: "/api/v1/pet-service/appointment/book",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Appointment"],
        }),
        filterAppointment: builder.query({
            query: (queryParams) => ({
                url: queryParams ?
                    `/api/v1/pet-service/appointment/filter?${queryParams}` : `/api/v1/pet-service/appointment/filter`,
                method: "GET",
            }),
            providesTags: ["Appointment"],
        }),
        dashboardStat: builder.query({
            query: () => ({
                url: `/api/v1/pet-service/service-management/stat/dashboard-pet-portal`,
                method: "GET",
            }),
            providesTags: ["Appointment"],
        }),
        serviceProvidedAppointment2: builder.query({
            query: () => ({
                url: "/api/v1/pet-service/appointment/filter",
                method: "GET",
            }),
            providesTags: ["Appointment"],
        }),
        serviceProvidedAssignedAppointment: builder.query({
            query: () => ({
                url: "/api/v1/pet-service/appointment/assigned",
                method: "GET",
            }),
            providesTags: ["Appointment"],
        }),
        serviceProvidedAssignAppointments: builder.mutation({
            query: (data) => ({
                url: "/api/v1/pet-service/appointment/assign",
                method: "POST",
                body: data,
            }),
            providesTags: ["Appointment"],
        }),
        changeAppointmentStatus: builder.mutation({
            query: (data) => ({
                url: "/api/v1/pet-service/appointment/status",
                method: "PATCH",
                body: data,
            }),
            providesTags: ["Appointment"],
        }),
    }),
});

export const {
    useCreateAppointmentMutation,
    useFilterAppointmentQuery,
    useDashboardStatQuery,
    useServiceProvidedAppointment2Query,
    useServiceProvidedAssignedAppointmentQuery,
    useServiceProvidedAssignAppointmentsMutation,
    useChangeAppointmentStatusMutation,
} = appointmentApiSlice;