import React, { useEffect, useState } from "react";
import boop from "../../assets/images/boop.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/Common/ui/Input";
import {
  useCheckUserMutation,
  useLoginMutation,
  useSendOtpMutation,
} from "store/features/auth/authApiSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "store/features/auth/authSlice";
import { auth } from "config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const initialValues = {
  emailOrMobile: "",
  otp: "",
};

const validationSchema = Yup.object({
  emailOrMobile: Yup.string().required("Required email/mobile number"),
  otp: Yup.string().required("OTP required"),
});

const LoginPage = () => {
  const [countdown, setCountdown] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);

  const [
    sendOtp,
    {
      isLoading: isOtpLoading,
      isSuccess: isOtpSuccess,
      isError: isOtpError,
      error: otpError,
    },
  ] = useSendOtpMutation();

  const [
    login,
    {
      data,
      isLoading: isLoginLoading,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error,
    },
  ] = useLoginMutation();

  const [
    checkUser,
    {
      isSuccess: checkUserSuccess,
      isError: isCheckUserError,
      error: checkUserError,
    },
  ] = useCheckUserMutation();

  const user = useSelector((state) => state.auth.user);
  const [btnStatus, setBtnStatus] = useState("Send OTP");

  useEffect(() => {
    if (user) {
      if (["ADMIN", "MANAGER"].includes(user.role)) {
        navigate("/dashboard");
      } else {
        navigate("/pet-management");
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isCheckUserError) {
      toast.error(checkUserError?.data?.message || "User not found!");
    }

    if (checkUserSuccess) {
      onSignup();
    }
  }, [isCheckUserError, checkUserError, checkUserSuccess]);

  useEffect(() => {
    if (isOtpSuccess) {
      toast.success("OTP sent successfully!");
    }
    if (isOtpError) {
      toast.error(otpError?.data?.message || "Failed to send OTP");
    }
  }, [isOtpSuccess, isOtpError, otpError]);

  useEffect(() => {
    if (isLoginSuccess) {
      const { token, user } = data;
      dispatch(setCredentials({ token, user: user }));
    }

    if (isLoginError) {
      toast.error(error?.data?.message);
    }
  }, [isLoginSuccess, isLoginError, error, data, dispatch]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const { values, handleSubmit, handleBlur, touched, errors, handleChange } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        const { emailOrMobile, otp } = values;
        const payload = {
          otp: Number(otp),
        };
        payload[emailOrMobile.includes("@") ? "email" : "phone_number"] =
          emailOrMobile;
        if (values.emailOrMobile.includes("@")) {
          //sendOtp({ email: values.emailOrMobile });
          login(payload);
        } else {
          onOTPVerify(values.otp).then(() => {
            login(payload);
          });
        }
      },
    });

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            //onSignup();
          },
          "expired-callback": () => { },
        },
      );
    }
  }

  function onSignup() {
    //setLoading(true);
    setPhoneOtpLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+91" + values.emailOrMobile;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPhoneOtpLoading(false);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setPhoneOtpLoading(false);
      });
  }


  const onOTPVerify = async (otp) => {
    //setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);

      return result;
    } catch (error) {
      toast.error("Failed to send phone OTP!");
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center p-4">
      <div>
        <img
          src={boop}
          alt="logo"
          className="w-40 h-32 md:w-[200px] md:h-[150px]"
        />
      </div>
      <div id="recaptcha-container"></div>
      <div className="w-full max-w-md">
        <div className="w-full bg-white rounded-md p-6 md:p-8 flex flex-col items-center gap-6 md:gap-8">
          <div className="flex flex-col items-center text-center">
            <div className="text-zinc-700 text-2xl md:text-4xl font-bold">
              Welcome
            </div>
            <div className="text-zinc-400 text-xs mt-1">
              Enter your details to get Login to your account.
            </div>
          </div>
          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col gap-1">
              <Input
                id={"emailOrMobile"}
                name="emailOrMobile"
                label={"Email/Mobile Number"}
                placeholder="Enter Email/Mobile Number"
                value={values.emailOrMobile}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.emailOrMobile && touched?.emailOrMobile}
                errorText={errors?.emailOrMobile}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <div className="flex items-center">
                <Input
                  id={"otp"}
                  name="otp"
                  label={"OTP"}
                  placeholder="Enter OTP"
                  value={values?.otp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.otp && touched?.otp}
                  errorText={errors?.otp}
                />
                <button
                  disabled={btnStatus === "OTP Sent" || !values.emailOrMobile || isOtpLoading || phoneOtpLoading}
                  onClick={async () => {
                    // check if email or mobile
                    if (values.emailOrMobile.includes("@")) {
                      setBtnStatus("Sending...");
                      await sendOtp({ email: values.emailOrMobile.trim() });
                      setBtnStatus("OTP Sent");
                    } else {
                      setBtnStatus("Sending...");
                      await checkUser({ phone_number: values.emailOrMobile.trim() });
                      setBtnStatus("OTP Sent");
                    }
                  }}
                  className={`ml-2 md:ml-4 rounded-md px-2 py-1 mt-8  md:px-4 md:py-2 font-semibold ${countdown > 0
                    ? "bg-gray-500 text-white text-sm"
                    : "bg-orangeBg/10 text-orangeBg text-sm"
                    }`}
                  type="button"
                >
                  {/* {countdown > 0 ? `Resend OTP (${countdown})` : "Resend OTP"} */}
                  {btnStatus}
                </button>
              </div>
            </div>

            <button
              className="w-full h-10 px-3 py-2 bg-orangeBg rounded-md flex justify-center items-center gap-2 md:w-[380px] md:h-[40px] md:px-[9px] md:py-[6px]"
              type="submit"
            >
              <div className="text-white text-md font-bold leading-normal">
                Log In
              </div>
            </button>
          </form>
          <div className="flex flex-row items-center gap-4 w-full">
            <div className="flex-grow border-b-2 border-black"></div>
            <div className="text-black text-base font-bold">OR</div>
            <div className="flex-grow border-b-2 border-black"></div>
          </div>
          <Link
            to={"/sign-up"}
            className="w-full h-10 px-3 py-2 bg-orangeBg rounded-md flex justify-center items-center gap-2 md:w-[380px] md:h-[40px] md:px-[9px] md:py-[6px]"
          >
            <div className="text-white text-md font-bold leading-normal">
              Create a pet boarder account
            </div>
          </Link>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 p-4 gap-3 text-xs md:text-sm text-gray-600 flex flex-row items-start">
        <div className="">Copyright ©2023</div>
        <div>Privacy Policy</div>
      </div>
    </div>
  );
};

export default LoginPage;
