import Input from "components/Common/ui/Input";
import React, { useEffect, useState } from "react";
import { TiAttachment } from "react-icons/ti";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import UploadedFilePlaceholder from "components/Common/ui/UploadedFilePlaceholder";
import * as Yup from "yup";
import { Button } from "@mui/material";
import {
  useSendVerifyOtpMutation,
  useVerifyEmailMutation,
} from "store/features/auth/authApiSlice";
import { FaCheck } from "react-icons/fa6";

const DocumentsAndPermissions = ({ parentformik, handleNext }) => {
  const { values, handleBlur, handleChange, errors, touched, setValues } =
    parentformik;

  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();

  const [sendVerifyOtp, sendVerifyOtpState] = useSendVerifyOtpMutation();
  const [verifyemail, verifyemailstate] = useVerifyEmailMutation();

  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
      data: deleteData,
    },
  ] = useDeleteFileMutation();
  const [currentTitle, setCurrentTitle] = useState("");

  const titles = [
    {
      title: "Municipals Permission",
      formFielValue: "municipals_docs",
    },
    {
      title: "Company Incorporation Document",
      formFielValue: "company_incorporation_docs",
    },
    {
      title: "Legal Declarations",
      formFielValue: "legal_declaration_docs",
    },
    {
      title: "Additional Documents",
      formFielValue: "additional_docs",
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      setFileToState(data?.data, currentTitle);
    }

    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }

    if (deleteSuccess) {
      toast.success("File deleted successfully");
      const formdataValue = titles?.find(
        (element) => element?.title === currentTitle,
      )?.formFielValue;
      const updatedFiles = values[formdataValue]?.filter(
        (file) => file?.id !== deleteData?.data?.id,
      );
      setValues((prev) => ({
        ...prev,
        [formdataValue]: updatedFiles,
      }));
    }
  }, [deleteError, deleteSuccess]);

  const handleFileSelect = (event, title) => {
    setCurrentTitle(title);
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    uploadFiles(formData);
  };

  const setFileToState = (fileIds, title) => {
    if (title === "Municipals Permission") {
      setValues((prev) => ({
        ...prev,
        municipals_docs: [...prev.municipals_docs, ...fileIds],
      }));
    } else if (title === "Company Incorporation Document") {
      setValues((prev) => ({
        ...prev,
        company_incorporation_docs: [
          ...prev.company_incorporation_docs,
          ...fileIds,
        ],
      }));
    } else if (title === "Legal Declarations") {
      setValues((prev) => ({
        ...prev,
        legal_declaration_docs: [...prev.legal_declaration_docs, ...fileIds],
      }));
    } else if (title === "Additional Documents") {
      setValues((prev) => ({
        ...prev,
        additional_docs: [...prev.additional_docs, ...fileIds],
      }));
    }
  };
  const validationSchema = Yup.object().shape({
    service_name: Yup.string().required("Service Name is required"),
    service_phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    service_location: Yup.string().required("Location is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    verify: Yup.boolean().required("Please Verify your email"),
  });
  const formik = useFormik({
    initialValues: {
      service_name: values?.service_name,
      service_phone_number: values?.service_phone_number,
      service_location: values?.service_location,
      verify: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (verifysuccess) {
        setValues((prev) => ({ ...prev, ...values }));
        handleNext();
      } else {
        toast.error("please verify email!");
      }
    },
  });
  const [otp, setOtp] = useState("");
  const [lastOtp, setLastOtp] = useState("");
  const [timer, setTimer] = useState(null);
  const [verifysuccess, stateverifysuccess] = useState(false);
  useEffect(() => {
    if (otp !== lastOtp) {
      if (timer) clearTimeout(timer);

      // Set a new timer for 2 seconds
      const timeoutId = setTimeout(async () => {
        const result = await verifyemail({ email: formik.values.email, otp });
        if (result?.data?.status == 200) stateverifysuccess(true);
        setLastOtp(otp);
      }, 2000);

      setTimer(timeoutId);
    }
  }, [otp]);

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);
  return (
    <div className="w-full flex flex-col gap-6 ">
      <div className="flex flex-col gap-4">
        <div className="text-orangeBg text-lg font-bold ">
          Add Profile details
        </div>
        <div className="w-[16%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Input
            id={"service_name"}
            name="service_name"
            label={"Business Name"}
            placeholder="Enter service name"
            value={formik?.values?.service_name}
            onBlur={formik?.handleBlur}
            required={true}
            onChange={formik?.handleChange}
            isError={
              formik?.errors?.service_name && formik?.touched?.service_name
            }
            errorText={formik?.errors?.service_name}
          />
          <Input
            id={"service_phone_number"}
            name="service_phone_number"
            label={"Phone number"}
            placeholder="Enter phone number"
            value={formik?.values?.service_phone_number}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            required={true}
            isError={
              formik?.errors?.service_phone_number &&
              formik?.touched?.service_phone_number
            }
            errorText={formik?.errors?.service_phone_number}
          />
          <Input
            id={"service_location"}
            name="service_location"
            label={"Location"}
            placeholder="Enter location"
            value={formik?.values?.service_location}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            required={true}
            isError={
              formik?.errors?.service_location &&
              formik?.touched?.service_location
            }
            errorText={formik?.errors?.service_location}
          />
          <div className="col-span-2 flex flex-row gap-4">
            <Input
              id={"email"}
              name="email"
              label={"Email"}
              placeholder="Enter Email"
              type={"email"}
              value={formik?.values?.email}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              required={true}
              isError={formik?.errors?.email && formik?.touched?.email}
              errorText={formik?.errors?.email}
            />
            {verifysuccess ? (
              <div className="flex items-end justify-end mb-3">
                <FaCheck className="text-green-500" />
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <label className="font-poppins  text-[#1C2135] text-[1rem] font-bold">
                  OTP
                </label>
                <div className="flex flex-row gap-2">
                  <input
                    className={`w-full h-10 px-4 py-1 mt-2 border rounded-lg focus:outline-none bg-gray-100
         disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:border-gray-300`}
                    placeholder="OTP"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <button
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!formik?.values?.email || formik?.errors?.email) {
                        toast.error("Please check email you filled!");
                        return;
                      }
                      await sendVerifyOtp({
                        email: formik?.values?.email,
                      }).unwrap();
                      toast.success(
                        `please verify otp sent to your email ${formik?.values?.email}`,
                      );
                    }}
                    className="bg-orange-500 text-white p-2 w-full whitespace-nowrap rounded-lg select-none"
                  >
                    {!sendVerifyOtpState?.isLoading
                      ? "Verify Email"
                      : "Sending..."}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="text-orangeBg text-lg font-bold">Add Documents</div>
        <div className="w-[14%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>
        <div className="text-slate-500 italic text-xs">
          {isLoading && "Uploading..."}
          {deleteLoading && "Deleting..."}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {titles.map(({ title, formFielValue }, index) => (
            <div
              key={index}
              className={`flex flex-col ${index === 3 ? "lg:col-span-2  w-[100%] md:w-[360px]" : ""}`}
            >
              <label className="text-gray-700 font-bold">
                {index + 1}. {title}
              </label>
              <div className="flex items-center justify-start gap-8 w-[90%] md:w-full p-1 mt-1 rounded border border-orange-400">
                <label
                  htmlFor={`fileInput-${index}`}
                  className="w-32 h-8 px-4 py-4 bg-orange-100 flex items-center justify-center rounded cursor-pointer"
                >
                  <TiAttachment className="" />
                  <span className="text-black font-semibold text-xs">
                    Select file
                  </span>
                </label>
                <input
                  id={`fileInput-${index}`}
                  type="file"
                  className="hidden"
                  onChange={(event) => handleFileSelect(event, title)}
                  multiple="multiple"
                />
              </div>
              <div className="grid grid-cols-2 gap-2 mt-2">
                {formFielValue && values[formFielValue]?.length ? (
                  values[formFielValue]?.map((file, index) => (
                    <UploadedFilePlaceholder
                      key={index}
                      name={file?.key}
                      link={file?.url}
                      onRemove={() =>
                        deleteFile(new URLSearchParams({ id: file?.id }))
                      }
                    />
                  ))
                ) : (
                  <div className="text-gray-400 text-sm">No files uploaded</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          sx={{
            textTransform: "none",
          }}
          className="w-fit float-right"
        >
          <div className="px-3 py-2 md:px-12 md:py-2 bg-orangeBg rounded-xl">
            <span className="text-white text-sm md:text-md">Next</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default DocumentsAndPermissions;
