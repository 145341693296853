import { apiSlice } from "../apiSlice";

export const staffApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStaff: builder.query({
            query: () => ({
                url: "/api/v1/pet-service/staff",
                method: "GET",
            }),
            providesTags: ["Staff"],
        }),
        createStaff: builder.mutation({
            query: (data) => ({
                url: "/api/v1/pet-service/staff",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Staff"],
        }),
        deleteStaff: builder.mutation({
            query: (id) => ({
                url: `/api/v1/pet-service/staff/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Staff"],
        }),
        getOneStaff: builder.query({
            query: (id) => ({
                url: `/api/v1/pet-service/staff/${id}`,
                method: "GET",
            }),
            providesTags: ["Staff"],
        }),
        updateStaff: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/v1/pet-service/staff/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Staff"],
        }),
    }),
});

export const {
    useCreateStaffMutation,
    useDeleteStaffMutation,
    useGetOneStaffQuery,
    useGetStaffQuery,
    useUpdateStaffMutation,
} = staffApiSlice;