import React, { useEffect, useState } from 'react'
import TableContainer from "components/Common/ui/TableContainer";
import DataTable from "../../components/Common/ui/Table";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import SingleSelect from "components/Common/ui/SingleSelect";
import Container from "components/Common/ui/Container";
import { useChangeAppointmentStatusMutation, useServiceProvidedAssignedAppointmentQuery } from 'store/features/appointment/appointmentApiSlice';
import moment from 'moment';
import BorderColorIcon from "@mui/icons-material/BorderColor";
import toast from 'react-hot-toast';
import Button from 'components/Common/ui/Button';
import Input from 'components/Common/ui/Input';
import { Formik } from 'formik';
import { FiAlertTriangle } from 'react-icons/fi';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import ActionButtonContainer from 'components/Common/ui/ActionButtonContainer';
import { FaUserPlus } from 'react-icons/fa6';
const statusInitial = {
    state: false,
    type: null, // Either CHECKIN or CHECKOUT
    modalType: "Mark", // Either Mark or Edit
    data: {
        id: null,
        status: null,
        date: null
    }
}
function AssignedBookings() {
    const [filters, setFilters] = useState({
        date: "",
    })

    const [statusModal, setStatusModal] = useState(statusInitial);
    const appointmentColumns = () => [
        {
            Header: "Pet Name",
            accessor: (row) => row?.service_appointment_booking?.service_appointment_pets.map((pet) => pet.pet.name).join(", "),
        },
        // {
        //     Header: "Pet parent",
        //     accessor: (row) =>
        //         row?.service_appointment_booking.service_appointment_master.petparent.first_name +
        //         " " +
        //         row?.service_appointment_booking?.service_appointment_master.petparent.last_name,
        // },
        {
            Header: "Check-In Date",
            accessor: (row) => row?.check_in_date ? moment(row.check_in_date).format("DD/MM/YYYY hh:mm a") : "N/A",

        },
        {
            Header: "Check-Out Date",
            accessor: (row) => <span
                onClick={() => {
                    setStatusModal(prev => ({
                        state: true,
                        type: "CHECKOUT",
                        modalType: row?.status === "CHECKOUT" ? "Edit" : "Mark",
                        data: {
                            id: row?.id,
                            status: "",
                            date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
                        }
                    }));
                }}
                className="hover:cursor-pointer ">  <BorderColorIcon className="text-[#D99149] " style={{ width: "15px", height: "15px" }} /> {row?.check_out_date ? moment(row?.check_out_date).format("DD/MM/YYYY hh:mm a") : "N/A"} </span>,

        },
        {
            Header: "start time",
            accessor: (row) => row?.service_appointment_booking.start_time,
        },
        {
            Header: "End time",
            accessor: (row) => row?.service_appointment_booking.end_time,
        },

        {
            Header: "service type",
            accessor: (row) => row?.service_appointment_booking.service?.service_type,
        },

        {
            Header: "Status",
            accessor: (row) => row?.service_appointment_booking.status || "UPCOMING",
        },
        {
            Header: "Action",
            accessor: (row) => (
                <ActionButtonContainer>
                    {!row?.check_in_date && <button
                        className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
                        onClick={() => {
                            setStatusModal(prev => ({
                                state: true,
                                type: "CHECKIN",
                                modalType: row?.status === "CHECKIN" ? "Edit" : "Mark",
                                data: {
                                    id: row?.id,
                                    status: "CHECKIN",
                                    date: null
                                }
                            }));
                        }}
                    >
                        Mark check-In
                    </button>}
                    {row?.check_in_date && !row.check_out_date ? <button
                        className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
                        onClick={() => {
                            setStatusModal(prev => ({
                                state: true,
                                type: "CHECKOUT",
                                modalType: row?.status === "CHECKOUT" ? "Edit" : "Mark",
                                data: {
                                    id: row?.id,
                                    status: "CHECKOUT",

                                    date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
                                }
                            }));
                        }}
                    >
                        Mark Check-Out
                    </button> : ""}

                    {row?.status === "CHECKOUT" ? <button
                        className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
                        onClick={() => {
                            setStatusModal(prev => ({
                                state: true,
                                type: "COMPLETED",
                                modalType: row?.status === "COMPLETED" ? "Edit" : "Mark",
                                data: {
                                    id: row?.id,
                                    status: "COMPLETED",
                                    date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
                                }
                            }));
                        }}
                    >
                        Mark Completed
                    </button> : ""}

                </ActionButtonContainer>
            ),
        },
    ];
    const { isLoading: serviceAppointmentLoading, data: appointmentData } =
        useServiceProvidedAssignedAppointmentQuery();

    const [
        changeAppointmentStatus,
        {
            isLoading: changeStatusLoading,
            isError: changeStatusError,
            isSuccess: changeStatusSuccess,
        }
    ] = useChangeAppointmentStatusMutation();

    useEffect(() => {

        if (changeStatusSuccess) {
            setStatusModal(statusInitial);
            toast.success("Status changed successfully");
        }

        if (changeStatusError) {
            toast.error("Failed to change status");
        }


    }, [changeStatusSuccess, changeStatusError]);

    const changeStatusModal = () => {
        const {
            state,
            type,
            modalType,
            data: formdata
        } = statusModal;
        return (
            <Modal
                state={state}
                sx={{ width: 600, padding: 0 }}
                handleState={() => setStatusModal(statusInitial)}
            >
                <div className="flex flex-col space-y-5">
                    <p className="font-poppins font-semibold text-[28px] capitalize">
                        {type === "CHECKIN" || type === "COMPLETED" ? "" : `${modalType} Check-Out`}
                    </p>
                    {type === "CHECKIN" || type === "COMPLETED" ? (<Box>

                        <div
                            className="flex items-center space-x-4"
                        >
                            <span
                                className="text-orange-500 text-2xl p-2 flex items-center justify-center bg-orange-100 rounded-full self-start"
                            >
                                <FiAlertTriangle />
                            </span>
                            <div
                                className="flex flex-col gap-2"
                            >
                                <h2
                                    className="text-lg font-semibold"
                                >
                                    Are you sure ?
                                </h2>
                                <p
                                    className="text-sm"
                                >
                                    Are you sure you want to mark this appointment as {type === "CHECKIN" ? "Check-In" : "Completed"} ?

                                </p>
                            </div>
                        </div>
                        <div
                            className="flex justify-end mt-4 space-x-4"
                        >
                            <Button
                                onClick={() => setStatusModal(statusInitial)}
                                disabled={changeStatusLoading}
                                className="bg-gray-300 text-gray-800 w-max"
                            >Cancel</Button>
                            <Button
                                className="w-max"
                                disabled={changeStatusLoading}
                                onClick={async () => {
                                    const payload = {
                                        id: formdata?.id,
                                        status: formdata?.status,

                                    }
                                    await changeAppointmentStatus(payload);
                                }}
                            >
                                Mark {type === "CHECKIN" ? "Check-In" : type === "COMPLETED" ? "Completed" : "Check-Out"}
                            </Button>
                        </div>
                    </Box>) : (
                        <Formik
                            initialValues={formdata}
                            enableReinitialize={true}
                            onSubmit={async (values) => {
                                const payload = {
                                    id: values?.id,
                                    status: "CHECKOUT",
                                    check_out_date: values?.date
                                }

                                await changeAppointmentStatus(payload);

                            }}
                        >
                            {({ handleSubmit, handleBlur, handleChange, errors, touched, values }) => (
                                <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                                    <Input
                                        label={"Check-Out Date"}
                                        type="date"
                                        value={values?.date}
                                        name="date"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={true}
                                        isError={errors?.date && touched?.date}
                                        errorText={errors?.date}
                                    />
                                    <div className="flex flex-row justify-end items-center space-x-4">
                                        <div
                                            className="flex justify-end mt-4 space-x-4"
                                        >
                                            <Button
                                                type="button"
                                                disabled={changeStatusLoading}
                                                onClick={() => setStatusModal(statusInitial)}
                                                className="bg-gray-300 text-gray-800 w-max"
                                            >Cancel</Button>
                                            <Button
                                                type="submit"
                                                className="w-max"
                                                disabled={changeStatusLoading}
                                            >
                                                Save {type === "CHECKIN" ? "Check-In" : "Check-Out"}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>

                    )}
                </div>
            </Modal>
        )
    }


    return (
        <Container title={"Assigned Appointments"}>
            {changeStatusModal()}
            {serviceAppointmentLoading ? (
                <div>Loading...</div>
            ) : (
                <TableContainer title="Assigned Appointments">
                    <DataTable
                        columns={appointmentColumns()}
                        data={appointmentData.data?.filter((item) => {
                            if (filters.date) {
                                console.log(item.service_appointment_booking.start_date, filters.date + "T00:00:00.000Z");
                                return item.service_appointment_booking.start_date === filters.date + "T00:00:00.000Z";
                            }
                            return item;
                        })}
                        filterArea={
                            <TableFilterContainer>
                                <div className=" flex flex-col">
                                    <input
                                        label="Date"
                                        id="admin_date"
                                        type="date"
                                        name="start_date"
                                        onChange={(e) => {
                                            setFilters({ ...filters, date: e.target.value });
                                        }}
                                        value={filters.date}
                                        required={true}
                                        className={`block w-max rounded-md border-0 py-2.5 p-2 text-gray-900 shadow-sm ring-0 ring-inset  placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
                  `}
                                    />
                                </div>
                            </TableFilterContainer>
                        }
                    />
                </TableContainer>
            )}
        </Container>


    )
}

export default AssignedBookings