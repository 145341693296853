import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import StatsCard from "../Cards/Dashboard/StatsCard";
import StatsPercentage from "../Cards/Dashboard/StatsPercentage";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { IoIosSearch } from "react-icons/io";
import AddaBooking from "../Modal/AddaBooking";
import { Dateservices } from "../../helpers/Dashboard/DateServices";
import DateModal from "../Modal/DateModal";

const AppointmentCard = ({ title, amount, parent, pet, status }) => {
  return (
    <div className="w-96 h-24 p-4 bg-white rounded-lg shadow-md flex flex-col justify-start items-start gap-2">
      <div className="flex justify-between items-center w-full">
        <div>
          <div className="text-black text-xl font-semibold">{title}</div>
          <div className="flex items-center gap-2 text-gray-500 text-[10px]">
            <div>
              Pet Parent - <span className="font-bold">{parent}</span>
            </div>
            <div className="w-1 h-1 bg-gray-300 rounded-full mx-2"></div>
            <div>
              Pet - <span className="font-bold">{pet}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1">
          ₹<div className="text-neutral-800 text-sm font-bold">{amount}</div>
        </div>
      </div>
      <div className="w-20 h-5 flex justify-center items-center relative">
        <div className="w-full h-full absolute opacity-20 bg-yellow-500 rounded-md"></div>
        <div className="text-center text-yellow-500 text-xs p-1 font-bold">
          {status}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [currentDate, setCurrentDate] = useState('')

  const handlePlusClick = () => {
    setIsOpen(true);
  };

  const handleDateClick = (arg) => {
    setIsDateModalOpen(true);
    setCurrentDate(arg.dateStr)
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsDateModalOpen(false);
  };

  const events = [
    { title: "Event 1", date: "2024-06-21" },
    { title: "Event 2", date: "2024-06-22" },
  ];

  const services = [
    {
      title: "Pet Cleaning Services",
      amount: "2688.00",
      parent: "Vijay",
      pet: "Dog",
      status: "Pending",
    },
  ];

  return (
    <div className="flex flex-col gap-6 w-full p-2"> 
      <div className="flex flex-col text-[36px]">
        <span>
          Hey <strong className="text-orangeBg"> Service Provider </strong>
        </span>
        <span className="text-lg -mt-[8px] ml-[2px]">
          Here is an overview of your dashboard
        </span>
      </div>

      <div className="flex flex-col gap-4">
        <span className="font-semibold">
          Daily Overview
          <div className="border-b-2 border-orangeBg flex-grow w-20"></div>
        </span>

        <div className="flex flex-row space-x-8">
          <StatsCard bgColor="bg-orange-200" heading="Your Pets" number="7,552" />
          <StatsCard bgColor="bg-green-200" heading="New Bookings" number="1,234" />
          <StatsCard bgColor="bg-yellow-200" heading="Total Revenue" number="$10,000" />
          <StatsCard bgColor="bg-green-400" heading="Total Revenue" number="$10,000" />
        </div>
      </div>

      <div className="flex flex-row gap-4">
        <div className="w-[70%]">
          <div className="flex flex-row justify-between w-[45%]">
            <span className="font-semibold">
              Appointments
              <div className="border-b-2 border-orangeBg flex-grow w-12"></div>
            </span>
            <button className="bg-orangeBg px-5 py-1 text-white text-sm rounded-lg">
              View All
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-3">
            {Array.from({ length: 6 }, (_, index) => (
              <AppointmentCard key={index} {...services[0]} />
            ))}
          </div>
        </div>
        <div className="w-[30%]">
          <div className="flex flex-row justify-between ">
            <span className="font-semibold ">
              Order Statistics
              <div className="border-b-2 border-orangeBg flex-grow w-20 "></div>
            </span>
            <span className="flex flex-row">
              <span className="text-sm font-semibold"> Monthly</span>{" "}
              <RiArrowDropDownLine className="mt-1" />
            </span>
          </div>
          <div className="bg-white rounded">
            <div className="grid grid-cols-2 gap-x-6 mt-4 py-3 pl-10">
              <StatsPercentage percentage={75} color="#4CAF50" label="Completed Tasks" />
              <StatsPercentage percentage={45} color="#FFC107" label="Pending Tasks" />
              <StatsPercentage percentage={90} color="#2196F3" label="Ongoing Tasks" />
              <StatsPercentage percentage={90} color="#2196F3" label="Ongoing Tasks" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Your Schedule
            <div className="border-b-2 border-orangeBg flex-grow w-16"></div>
          </div>
          <div className="flex flex-row gap-8">
            <div className="rounded font-semibold mt-2">Filter By</div>

            <div className="bg-white flex flex-row rounded-lg gap-12 p-2 items-center justify-center font-semibold">
              <button className="flex flex-row items-center flex-grow">
                Date{" "}
                <RiArrowDropDownLine className="ml-[2px] mt-[2px]" size={25} />
              </button>
              <div className="border-r-2 border-black h-full opacity-10 "></div>
              <button className="flex flex-row items-center flex-grow">
                Service Type{" "}
                <RiArrowDropDownLine className="ml-[2px] mt-[2px]" size={25} />
              </button>
              <div className="border-l-2 border-black h-full opacity-10"></div>
              <button className="flex flex-row items-center flex-grow">
                Service Status{" "}
                <RiArrowDropDownLine className="ml-[2px] mt-[2px]" size={25} />
              </button>
            </div>

            <div className="flex items-center shadow-slate-300 shadow-2xl rounded-xl overflow-hidden">
              <button className="flex items-center justify-center bg-white py-3 px-4">
                <IoIosSearch size={24} />
              </button>
              <input
                type="search"
                className="px-10 py-3 w-full text-black focus:outline-none"
                placeholder="Search here ..."
              />
            </div>

            <div>
              <button className="bg-orangeBg rounded-lg px-12 py-[11px] font-semibold text-white">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="calendar-hover-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            dateClick={handleDateClick}
            headerToolbar={{
              left: "title",
              center: "",
              right: "prev,next",
            }}
          />
          {hover && (
            <div className="plus-icon-container" onClick={handlePlusClick}>
              <div className="plus-icon text-green-500 text-3xl">+</div>
            </div>
          )}
          {isOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleCloseModal}>
              <div className="bg-white p-4 rounded shadow-lg relative z-60  ml-44" onClick={(e) => e.stopPropagation()}>
                <AddaBooking setIsOpen={setIsOpen} />
              </div>
            </div>
          )}
          {isDateModalOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleCloseModal}>
              <div className="bg-white p-4 rounded shadow-lg relative z-60 ml-auto mr-20" onClick={(e) => e.stopPropagation()}> 
                <DateModal DateServices={Dateservices} date={currentDate} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
