import DashboardStatCard from "components/Common/BookingReservation/DashboardStatCard";
import Container from "components/Common/ui/Container";
import IconButton from "components/Common/ui/IconButton";
import SingleSelect from "components/Common/ui/SingleSelect";
import DataTable from "components/Common/ui/Table";
import TableContainer from "components/Common/ui/TableContainer";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Button from "components/Common/ui/Button";
import * as Yup from "yup";
import Modal from "components/Common/ui/Modal";
import { useFormik } from "formik";
import Input from "components/Common/ui/Input";
import {
  useCreateStaffMutation,
  useDeleteStaffMutation,
  useGetStaffQuery,
  useUpdateStaffMutation,
} from "store/features/staff/staffApiSlice";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setmenu } from "store/StateSlice/layoutSlice";
import ConfirmationModal from "components/Common/ui/ConfirmationModal";
const initialState = {
  state: false,
  edit_id: null,
  data: {
    first_name: "",
    last_name: "",
    phone_number: "",
    joined_date: "",
    alternate_phone_number: "",
    email: "",
    role: "",
    shift: "",
    pet_write_access: false
  },
};

const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().nullable(),
  phone_number: Yup.string().required("Phone number is required"),
  joined_date: Yup.string().required("Joined date is required"),
  alternate_phone_number: Yup.string().nullable(),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  role: Yup.string().required("Role is required"),
  shift: Yup.string().nullable(),
  pet_write_access: Yup.string().optional(),
});

const StaffManagementMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setmenu("staff"));
  }, []);
  const [modalState, setModalState] = useState(initialState);

  const { isLoading: staffLoading, data } = useGetStaffQuery();

  const [deleteAlert, setDeleteAlert] = useState({ id: null, state: false });


  const columns = () => [
    {
      accessor: "first_name",
      Header: "First Name",
    },
    {
      accessor: "last_name",
      Header: "Last Name",
    },
    {
      accessor: "email",
      Header: "email",
    },
    {
      accessor: (row) => {
        return row?.pet_write_access ? (
          <p className="text-green-600">True</p>
        ) : (
          <p className="text-red-600">False</p>
        )
      },

      Header: "Pet Write access",
    },
    {
      accessor: "role",
      Header: "role",
    },
    {
      accessor: "phone_number",
      Header: "Phone",
    },
    {
      accessor: (row) =>
        row?.blocked ? (
          <p className="text-red-600">BLOCKED</p>
        ) : (
          <p className="text-green-600">ACTIVE</p>
        ),
      Header: "Staff Status",
    },
    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton
            onClick={() => navigate(`/staff-management/profile/${d?.id}`)}
          >
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalState({
                state: true,
                edit_id: d?.id,
                data: {
                  first_name: d?.first_name,
                  last_name: d?.last_name,
                  phone_number: d?.phone_number,
                  joined_date: d?.joined_date,
                  alternate_phone_number: d?.alternate_phone_number,
                  email: d?.email,
                  role: d?.role,
                  shift: d?.shift,
                  pet_write_access: d?.pet_write_access
                },
              });
            }}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
            onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];

  const [
    createStaff,
    {
      isLoading: createLoading,
      isError: createStaffError,
      isSuccess: createStaffSuccess,
    },
  ] = useCreateStaffMutation();
  const [
    updateStaff,
    {
      isLoading: updateLoading,
      isError: updateStaffError,
      isSuccess: updateStaffSuccess,
    },
  ] = useUpdateStaffMutation();
  const [
    deleteStaff,
    {
      isLoading: deleteLoading,
      isError: deleteStaffError,
      isSuccess: deleteStaffSuccess,
    },
  ] = useDeleteStaffMutation();


  useEffect(() => {
    if (createStaffSuccess) {
      toast.success("Staff created successfully");
      setModalState(initialState);
    } else if (createStaffError) {
      toast.error("Staff creation failed");
    } else if (updateStaffSuccess) {
      toast.success("Staff updated successfully");
      setModalState(initialState);
    } else if (updateStaffError) {
      toast.error("Staff update failed");
    } else if (deleteStaffSuccess) {
      toast.success("Staff deleted successfully");
    } else if (deleteStaffError) {
      toast.error("Staff deletion failed");
    }
  }, [createStaffSuccess, updateStaffSuccess, createStaffError, updateStaffError, deleteStaffSuccess, deleteStaffError]);

  const { handleChange, handleBlur, touched, errors, handleSubmit, values, setValues } =
    useFormik({
      initialValues: modalState.data,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (payload) => {
        console.log(payload);
        if (values.role !== "PET_HANDLER") {
          payload.pet_write_access = false
        }

        //null those who are empty
        for (let key in payload) {
          if (payload[key] === "") {
            payload[key] = null
          }
        }

        console.log(modalState.edit_id);

        if (modalState.edit_id) {
          updateStaff({ id: modalState.edit_id, ...payload });
        } else {
          createStaff(payload);
        }
      },
    });

  const [filters, setFilters] = useState({
    role: "",
  });

  const staffModal = () => {
    const { edit_id, state } = modalState;
    return (
      <Modal
        state={state}
        handleState={() => setModalState(initialState)}
        width={800}
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {edit_id ? "Update " : "Add "}Staff
          </p>

          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
              <Input
                id={"first_name"}
                name="first_name"
                label={"First Name"}
                placeholder="Enter first name"
                value={values?.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.first_name && touched?.first_name}
                errorText={errors?.first_name}
              />
              <Input
                id={"last_name"}
                name="last_name"
                label={"Last name"}
                placeholder="Enter Last name"
                value={values?.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.last_name && touched?.last_name}
                errorText={errors?.last_name}
              />

              <Input
                id={"phone_number"}
                name="phone_number"
                label={"Phone number"}
                placeholder="Enter phone number"
                value={values?.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.phone_number && touched?.phone_number}
                errorText={errors?.phone_number}
              />
              <Input
                id={"joined_date"}
                name="joined_date"
                label={"Joined date"}
                placeholder="Enter joined date"
                value={values?.joined_date}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.joined_date && touched?.joined_date}
                errorText={errors?.joined_date}
                type="date"
              />
            </div>

            <p className="font-semibold font-poppins text-orangeBg">
              Additional Details
            </p>

            <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
              <Input
                id={"alternate_phone_number"}
                name="alternate_phone_number"
                label={"Alternate phone number"}
                placeholder="Enter Alternate phone number"
                value={values?.alternate_phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={
                  errors?.alternate_phone_number &&
                  touched?.alternate_phone_number
                }
                errorText={errors?.alternate_phone_number}
              />

              <Input
                id={"email"}
                name="email"
                label={"Email"}
                placeholder="Enter Email"
                value={values?.email}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.email && touched?.email}
                errorText={errors?.email}
                type="email"
              />

              <SingleSelect
                label={"Role"}
                options={[
                  { label: "Admin", value: "ADMIN" },
                  { label: "Manger", value: "MANAGER" },
                  { label: "Pet Handler", value: "PET_HANDLER" },
                ]}
                id={"role"}
                name="role"
                value={values?.role}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.role && touched?.role}
                errorText={errors?.role}
              />

              {/* <SingleSelect
                label={"Shift"}
                options={[
                  { label: "Morning", value: "Morning" },
                  { label: "Evening", value: "Evening" },
                  { label: "Night", value: "Night" },
                ]}
                id={"role"}
                name="role"
                value={values?.role}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.role && touched?.role}
                errorText={errors?.role}
              /> */}
              {values.role === "PET_HANDLER" && <div
                className="flex flex-row items-center gap-2"
              >
                <Input type={"checkbox"} id="pet_write_access" onChange={(e) => {
                  setValues(prev => ({
                    ...prev,
                    pet_write_access: e.target.checked
                  }))

                }} name={"pet_write_access"} checked={values.pet_write_access} />
                <label
                  for="countries"
                  htmlFor="pet_write_access"
                  class="font-poppins text-base font-semibold text-[#1C2135]"
                >
                  Pet write access
                </label>

              </div>}
            </div>

            <div className="flex flex-col space-y-2">
              <Button type="submit">
                {createLoading || updateLoading ? "Loading..." : edit_id ? "Update" : "Add"}
              </Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() =>
                  setModalState((prev) => ({ ...prev, state: false }))
                }
              >
                Go Back
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };


  return (
    <Container title={"Staff Management"}>
      <ConfirmationModal
        handleState={() => setDeleteAlert({ id: null, state: false })}
        state={deleteAlert.state}
        onDelete={async () => {
          await deleteStaff(deleteAlert.id);
          setDeleteAlert({ id: null, state: false });
        }}
        loading={deleteLoading}
      />

      {staffModal()}
      {staffLoading ? (
        "Loading..."
      ) : (
        <div className="flex flex-col space-y-16">
          <TableContainer
            title="Staff Details"
            actionButtons={
              <>
                <Button
                  onClick={() =>
                    setModalState((prev) => ({ ...prev, state: true }))
                  }
                >
                  Add Staff
                </Button>
              </>
            }
          >
            <DataTable
              columns={columns()}
              data={data?.data
                ?.filter((item) => filters.role ? item.role === filters.role : true)
              }
              filterArea={
                <TableFilterContainer>
                  <SingleSelect
                    showLabel={false}
                    className="!bg-white"
                    showLabelInOption={false}
                    label={"Check-In Status"}
                    options={[{
                      label: "Select Role",
                      value: ""
                    },
                    ...["Admin", "Manager", "Pet Handler"].map((item) => ({
                      label: item,
                      value: item.includes(" ") ? item.split(" ").join("_").toUpperCase() : item.toUpperCase(),
                    }))]}

                    id={"check_in_status"}
                    name="check_in_status"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        role: e.target.value,
                      }));
                    }}
                  />
                </TableFilterContainer>
              }
            />
          </TableContainer>
        </div>
      )}
    </Container>
  );
};

export default StaffManagementMain;
