import DashboardStatCard from "components/Common/BookingReservation/DashboardStatCard";
import ActionButtonContainer from "components/Common/ui/ActionButtonContainer";
import Container from "components/Common/ui/Container";
import IconButton from "components/Common/ui/IconButton";
import SingleSelect from "components/Common/ui/SingleSelect";
import DataTable from "components/Common/ui/Table";
import TableContainer from "components/Common/ui/TableContainer";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useCreatePetMutation,
  useDasboardStatQuery,
  useGetAllPetsQuery,
  useGetPetProfileQuery,
} from "store/features/pet_profile/petProfileApiSlice";
import toast from "react-hot-toast";
import Button from "components/Common/ui/Button";
import Input from "components/Common/ui/Input";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Modal from "components/Common/ui/Modal";
import { useSelector } from "react-redux";
import { setmenu } from "store/StateSlice/layoutSlice";
import { useDispatch } from "react-redux";
const initialState = {
  state: false,
  edit_id: null,
  data: {
    name: "",
    type: "",
    breed: "",
    gender: "",
    age: "",
    weight: "",
    dob: "",
    neutered_or_spayed: false,
    parent_id: null,
    documents: null,
  },
};

const validationSchema = {
  name: Yup.string().required("Name is required"),
  type: Yup.string().optional(),
  breed: Yup.string().required("Breed is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.number().required("Age is required"),
  weight: Yup.string().optional(),
  dob: Yup.string().required("DOB is required"),
  neutered_or_spayed: Yup.boolean().optional().default(false),
};

const PetManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setmenu("pet"));
  }, []);
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(initialState);

  const { isLoading, data: petsData, refetch } = useGetAllPetsQuery();
  const { isLoading: statLoading, data: stateData, } = useDasboardStatQuery();

  const [
    createPet,
    {
      isLoading: createPetLoading,
      isSuccess: petCreateSuccess,
      isError: petCreateError,
    },
  ] = useCreatePetMutation();

  useEffect(() => {
    if (petCreateSuccess) {
      toast.success("Pet created successfully!");
      setModalState(initialState);
    }

    if (petCreateError) {
      toast.error("Failed to create Pet!");
    }
  }, [petCreateSuccess, petCreateError]);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    setValues,
  } = useFormik({
    initialValues: initialState.data,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values, action) => {
      const formdata = new FormData();
      formdata.append("name", values?.name);
      formdata.append("type", values?.type);
      formdata.append("breed", values?.breed);
      formdata.append("gender", values?.gender);
      formdata.append("weight", values?.weight);
      formdata.append("dob", values?.dob);
      formdata.append("neutered_or_spayed", values?.neutered_or_spayed);
      formdata.append("age", values?.age);
      formdata.append("parent_id", values?.parent_id);
      console.log({ formdata });
      await createPet(formdata);
      if (petCreateSuccess) action.resetForm();


      refetch();
    },
  });

  const petModal = () => {
    const { edit_id, data, state } = modalState;

    return (
      <Modal
        state={state}
        width={700}
        handleState={() =>
          setModalState((prev) => ({ ...initialState }))
        }
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {edit_id ? "Update " : "Add "}pet
          </p>

          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-3">
              <div className="col-span-2">
                <Input
                  id={"name"}
                  name="name"
                  label={"Name"}
                  placeholder="Enter name"
                  value={values?.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.name && touched?.name}
                  errorText={errors?.name}
                />
                <div className="flex flex-row space-x-2">
                  <Input
                    id={"type"}
                    name="type"
                    label={"Type"}
                    className={"mt-2"}
                    placeholder="Enter type"
                    value={values?.type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={errors?.type && touched?.type}
                    errorText={errors?.type}
                  />

                  <Input
                    id={"breed"}
                    name="breed"
                    label={"Breed"}
                    placeholder="Enter breed"
                    className={"mt-2"}
                    value={values?.breed}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={errors?.breed && touched?.breed}
                    errorText={errors?.breed}
                  />
                </div>

                <div className="flex flex-col mt-2">
                  <label
                    for="countries"
                    class="font-poppins text-base font-semibold text-[#1C2135]"
                  >
                    {"Neutered Or Spayed"}
                  </label>

                  <div className="flex flex-row p-1 space-x-1 rounded-md bg-[#F8F8F8]">
                    <div
                      className={`w-full p-1 text-center rounded-md text-black  cursor-pointer ${values.neutered_or_spayed && " bg-orangeBg text-white"}`}
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          neutered_or_spayed: true,
                        }))
                      }
                    >
                      Yes
                    </div>
                    <div
                      className={`w-full p-1 text-center rounded-md text-black  cursor-pointer ${!values.neutered_or_spayed && " bg-orangeBg text-white"}`}
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          neutered_or_spayed: false,
                        }))
                      }
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center">
                  <label className="h-[145px] w-[145px] flex flex-col items-center justify-center space-y-4 rounded-lg border border-darkOrangeBg hover:shadow-lg transition ease-in-out">
                    <input type="file" className="hidden" />
                    <AddPhotoAlternateOutlinedIcon
                      style={{ height: 40, width: 40, color: "#D99149" }}
                    />
                    <p className="text-[13px] font-medium">Add Picture</p>
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-2 ">
              <SingleSelect
                label={"Gender"}
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                id={"gender"}
                name="gender"
                value={values?.gender}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.gender && touched?.gender}
                errorText={errors?.gender}
              />

              <Input
                id={"weight"}
                name="weight"
                label={"Weight (kg)"}
                placeholder="Weight"
                value={values?.weight}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.weight && touched?.weight}
                errorText={errors?.weight}
              />

              <Input
                id={"age"}
                name="age"
                label={"Age (Yrs)"}
                placeholder="Age"
                value={values?.age}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.age && touched?.age}
                errorText={errors?.age}
              />

              <Input
                id={"dob"}
                name="dob"
                label={"DOB"}
                placeholder="DOB"
                type="date"
                value={values?.dob}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.dob && touched?.dob}
                errorText={errors?.dob}
              />
            </div>

            {/* <Input
              id={"documents"}
              name="documents"
              label={"Add Pet Related Documents"}
              placeholder="Add Pet Related Documents"
              type="file"
              value={values?.documents}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.documents && touched?.documents}
              errorText={errors?.documents}
            /> */}

            <Input
              id={"parent_id"}
              name="parent_id"
              label={"Link a Parent"}
              placeholder="Link a Parent"
              value={values?.parent_id}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.parent_id && touched?.parent_id}
              errorText={errors?.parent_id}
            />

            <div className="flex flex-col space-y-2">
              <Button type="submit">
                {createPetLoading ? "Loading..." : edit_id ? "Update" : "Add"}
              </Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() =>
                  setModalState((prev) => ({ ...initialState, state: false }))
                }
              >
                Go Back
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  const { role, pet_write_access } = useSelector((state) => state.auth.user);


  const columns = () => [
    {
      accessor: (row) => row?.pet?.pet_id,
      Header: "Pet ID",
    },
    {
      accessor: (row) => row?.pet?.name,
      Header: "Pet Name",
    },
    {
      accessor: (row) => row?.pet?.age_year,
      Header: "Age",
    },
    {
      accessor: (row) => row?.pet?.weight || "N/A",
      Header: "Weight",
    },
    {
      accessor: (row) => row?.pet?.breed || "N/A",
      Header: "Bread",
    },
    {
      Header: "View",
      accessor: (row) => (
        <ActionButtonContainer>
          <IconButton
            className={"disabled:opacity-50 disabled:cursor-not-allowed"}
            disabled={role === "PET_HANDLER" && !pet_write_access}
            onClick={() => navigate(`/pet-management/profile/${row?.pet?.id}`)}
          >
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          {/* <IconButton>
              <BorderColorIcon style={{ width: "15px", height: "15px" }} />
            </IconButton>
            <IconButton>
              <DeleteIcon style={{ width: "15px", height: "15px" }} />
            </IconButton> */}
        </ActionButtonContainer >
      ),
    }
  ];
  return (
    <Container title={"Pet Management"}>
      <div className="grid grid-cols-4 gap-4 mb-8">
        <DashboardStatCard
          data={{ title: "Total Pets", count: statLoading ? "loading..." : stateData?.data?.totalRegisteredPets }}
        />
        <DashboardStatCard
          data={{ title: "Medical Conditions", count: statLoading ? "loading..." : stateData?.data?.totalPetsWithMedical }}
        />


      </div>
      {petModal()}
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="flex flex-col space-y-16">
          <TableContainer
            title="Pet details"
            actionButtons={
              <Button
                className={"disabled:opacity-50 disabled:cursor-not-allowed"}
                disabled={role === "PET_HANDLER" && !pet_write_access}
                onClick={() => setModalState({ ...initialState, state: true })}
              >
                Add
              </Button>
            }
          >
            <DataTable
              columns={columns()}
              data={petsData?.data?.rows}
              filterArea={
                <TableFilterContainer>
                  <SingleSelect
                    showLabel={false}
                    className="!bg-white"
                    label={"Type"}
                    options={[]}
                    id={"type"}
                    name="type"
                    onChange={(e) => { }}
                  />

                  <SingleSelect
                    showLabel={false}
                    className="!bg-white"
                    label={"Age"}
                    options={[]}
                    id={"age"}
                    name="age"
                    onChange={(e) => { }}
                  />
                </TableFilterContainer>
              }
            />
          </TableContainer>
        </div>
      )}
    </Container>
  );
};

export default PetManagement;
