import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import FileUploadField from "components/Common/AuthPages/FileUploadField";
import Input from "components/Common/ui/Input";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import toast from "react-hot-toast";
import UploadedFilePlaceholder from "components/Common/ui/UploadedFilePlaceholder";
import { MdDeleteOutline } from "react-icons/md";
import CustomToggle from "components/Common/AuthPages/CustomToggle";
import ToggleSwitch from "components/Common/AuthPages/ToggleSwitch";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  useGetAllAccommodationQuery,
  useGetOneAccommodationQuery,
  useUpdateAccommodationMutation,
  useCreateAccommodationMutation,
} from "store/features/accommodation/accommodationApiSlice";
import {
  useGetOneServiceApiQuery,
  useUpdateServiceMutation,
  useCreateServiceMutation,
} from "store/features/services/serviceApiSlice";
import Container from "components/Common/ui/Container";
import Dropdown from "components/Common/ui/Dropdown";
import { useSelector } from "react-redux";
import Confirm from "components/Common/ui/Confirm";
const serviceProviderSchema = Yup.object().shape({
  service_type: Yup.string().required("Service type is required"),
  service_name: Yup.string()
    .required("Service name is required")
    .min(2, "Service name should be at least 2 characters"),
  duration: Yup.string()
    .nullable()
    .when("service_type", {
      is: (service_type) => service_type !== "Boarding",
      then: () => Yup.string().required("Service duration is required"),
    }),
  accomodation_id: Yup.string()
    .nullable()
    .when("service_type", {
      is: (value) => value == "Boarding",
      then: () => Yup.string().required("Accommodation is required"),
    }),
  inbound_outbound: Yup.string().required(
    "Inbound/Outbound selection is required",
  ),
  //   service_pricings: Yup.array().of(
  //     Yup.object().shape({
  //       price_modal: Yup.string().required(),
  //       price: Yup.number().when("price_modal", {
  //         is: (value) => value == "24h",
  //         then: () => Yup.number().min(0, "Price must be positive"),
  //       }),
  //       price: Yup.number().when("price_modal", {
  //         is: (value) => value == "Hourly",
  //         then: () => Yup.number().min(0, "Price must be positive"),
  //       }),
  //       price: Yup.number().when("price_modal", {
  //         is: (value) => value == "Checkin-Checkout",
  //         then: () => Yup.number().min(0, "Price must be positive"),
  //       }),
  //       checkin_time: Yup.string().when("price_modal", {
  //         is: (value) => value == "Checkin-Checkout",
  //         then: () => Yup.string().nullable(),
  //       }),
  //       checkout_time: Yup.string().when("price_modal", {
  //         is: (value) => value == "Checkin-Checkout",
  //         then: () => Yup.string().nullable(),
  //       }),
  //     }),
  //   ),
  service_description: Yup.string()
    .required("Service description is required")
    .min(10, "Description should be at least 10 characters"),
  availibilities: Yup.array()
    .of(
      Yup.object().shape({
        day: Yup.string().required("Day is required"),
      }),
    )
    .min(1, "At least one day must be selected"),
  from_time: Yup.string().required("From time is required"),
  // price: Yup.number()
  //   .required("Price is required")
  //   .when("service_type", {
  //     is: (value) => value != "Boarding",
  //     then: () => Yup.string().required("Price is required"),
  //   }),

  to_time: Yup.string().required("To time is required"),
});

function ServiceEditForm({
  stepform,
  formsubmit,
  deleteservice,
  tempaccommdata,
}) {
  const { id } = useParams();
  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();
  const navigate = useNavigate();
  const { data: single, isLoading: singleloading } = useGetOneServiceApiQuery(
    { id },
    { skip: id == "new" || stepform != null },
  );
  const [updateservice, updateservicestate] = useUpdateServiceMutation();
  const [createservice, createservicestate] = useCreateServiceMutation();

  const { data: accommdata } = useGetAllAccommodationQuery({
    skip: tempaccommdata,
  });
  const [pricingmodel, setpricingmodel] = useState({
    h24: false,
    checkincheckout: false,
    hourly: false,
  });
  const [extra, setextra] = useState(false);
  const options = [
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];
  const {
    setValues,
    values,
    handleBlur,
    setFieldValue,
    isError: formikerror,
    touched,
    errors,
    ...formik
  } = useFormik({
    initialValues: {
      service_type: null,
      service_name: null,
      duration: null,
      accomodation_id: null,
      inbound_outbound: null,
      service_pricings: [
        {
          price_modal: "24h",
          price: null,
        },
        {
          price_modal: "Hourly",
          price: null,
        },
        {
          price_modal: "Checkin-Checkout",
          price: null,
          checkin_time: null,
          checkout_time: null,
        },
        {
          price_modal: "Fixed",
          price: null,
        },
      ],
      service_description: null,
      availibilies: [],
      from_time: null,
      to_time: null,
      services_photos: [],
    },

    validationSchema: serviceProviderSchema,
    onSubmit: (values) => {
      let pricingmodelscheck = values?.service_pricings?.filter(
        (price) => price?.price != null && price?.price != "",
      );
      console.log(pricingmodelscheck);
      if (pricingmodelscheck?.length == 0) {
        toast.error("Pricing is required.");
        return;
      }
      if (id == "new") {
        createservice({
          ...values,
          pricing: values?.service_pricings
            ?.filter((rec) => rec?.price != null && rec?.price != "")
            .map((rec) => ({
              price_modal: rec?.price_modal,
              price: rec?.price ? rec?.price : null,
              checkin_time: rec?.checkin_time ? rec?.checkin_time : null,
              checkout_time: rec?.checkout_time ? rec?.checkout_time : null,
            })),
          availibilies: values?.availibilies?.map((avail) => ({
            ...avail,
            start_time: values.from_time,
            end_time: values.to_time,
          })),

          accomodation_id: values?.accomodation_id || null,
          servicePhotos: values.services_photos.map((rec) => {
            if (!rec?.file) return { file_id: rec?.id };
            else return rec;
          }),
        }).then((resp) => {
          toast.success("Created successful!");

          navigate(-1);
        });
      } else if (formsubmit) {
        formsubmit({
          ...values,
          pricing: values?.service_pricings
            ?.filter((rec) => rec?.price != null && rec?.price != "")
            .map((rec) => ({
              price_modal: rec?.price_modal,
              price: rec?.price ? rec?.price : null,
              checkin_time: rec?.checkin_time ? rec?.checkin_time : null,
              checkout_time: rec?.checkout_time ? rec?.checkout_time : null,
            })),
          availibilies: values?.availibilies?.map((avail) => ({
            ...avail,
            start_time: values.from_time,
            end_time: values.to_time,
          })),

          accomodation_id: values?.accomodation_id || null,
          servicePhotos: values.services_photos.map((rec) => {
            if (!rec?.file) return { file_id: rec?.id };
            else return rec;
          }),
        });
        toast.success("saved!");
      } else {
        updateservice({
          id,
          data: {
            ...values,
            accomodation_id: values?.accomodation_id || null,
            availibilies: values?.availibilies?.map((avail) => ({
              ...avail,
              start_time: values.from_time,
              end_time: values.to_time,
            })),
            pricing: values?.service_pricings
              ?.filter((rec) => rec?.price != null && rec?.price != "")
              .map((rec) => ({
                price_modal: rec?.price_modal,
                price: rec?.price ? rec?.price : null,
                checkin_time: rec?.checkin_time ? rec?.checkin_time : null,
                checkout_time: rec?.checkout_time ? rec?.checkout_time : null,
              })),
            servicePhotos: values.services_photos.map((rec) => {
              if (!rec?.file) return { file_id: rec?.id };
              else return rec;
            }),
          },
        }).then((resp) => {
          toast.success("Updated successful!");
        });
      }
    },
  });
  const [
    deleteFile,
    {
      data: deletedata,
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
    },
  ] = useDeleteFileMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      setFieldValue("services_photos", [
        ...values?.services_photos,
        ...data?.data,
      ]);
    }
    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }
    if (deleteSuccess) {
      toast.success("File deleted successfully");
      // setFieldValue(
      //   "services_photos",
      //   values?.services_photos.filter(
      //     (rec) => rec?.id != deletedata?.data?.id,
      //   ),
      // );
    }
  }, [deleteError, deleteSuccess]);

  const handleFileSelect = async (event) => {
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    await uploadFiles(formData);
  };
  useEffect(() => {
    if (single) {
      console.log(errors);

      Object.entries(errors).forEach(([key, val]) => {
        console.log(val);
      });
    }
  }, [errors, formikerror]);
  const getServicePricingModel = (pricingModel, servicePricings) => {
    return (
      servicePricings.find((rec) => rec.price_modal === pricingModel) || {
        price_modal: pricingModel,
        price: "",
        checkin_time: "",
        checkout_time: "",
      }
    );
  };
  // useEffect(() => {
  //   if (formsubmit) {
  //     formsubmit({
  //       ...values,
  //       pricing: values?.service_pricings
  //         ?.filter((rec) => rec?.price != null && rec?.price != "")
  //         .map((rec) => ({
  //           price_modal: rec?.price_modal,
  //           price: rec?.price ? rec?.price : null,
  //           checkin_time: rec?.checkin_time ? rec?.checkin_time : null,
  //           checkout_time: rec?.checkout_time ? rec?.checkout_time : null,
  //         })),
  //       availibilies: values?.availibilies?.map((avail) => ({
  //         ...avail,
  //         start_time: values.from_time,
  //         end_time: values.to_time,
  //       })),

  //       accomodation_id: values?.accomodation_id || null,
  //       servicePhotos: values.services_photos.map((rec) => {
  //         if (!rec?.file) return { file_id: rec?.id };
  //         else return rec;
  //       }),
  //     });
  //   }
  // }, [values]);
  useEffect(() => {
    if (single?.data) {
      setextra(single.data.exceed_time_charge ? true : false);
      setValues({
        ...single?.data,
        service_pricings: [
          getServicePricingModel("24h", single.data.service_pricings),
          getServicePricingModel("Hourly", single.data.service_pricings),
          getServicePricingModel(
            "Checkin-Checkout",
            single.data.service_pricings,
          ),
          getServicePricingModel("Fixed", single.data.service_pricings),
        ],
        availibilies: single?.data?.service_availibilities,
        from_time: single?.data?.service_availibilities?.[0]?.start_time,
        to_time: single?.data?.service_availibilities?.[0]?.end_time,
      });
    } else if (stepform) {
      setValues({
        ...stepform,
        services_photos: stepform.servicePhotos,
        accomodation_id: stepform.accomodation_temp_id,
        service_pricings: [...stepform?.pricing],
      });
    } else {
      formik.resetForm();
    }
  }, [single]);
  useEffect(() => {
    setpricingmodel({
      h24: values?.service_pricings[0]?.price ? true : false,
      checkincheckout: values?.service_pricings[2]?.price ? true : false,
      hourly: values?.service_pricings[1]?.price ? true : false,
    });
  }, [values]);
  const handlepricechange = (priceindex, value) => {
    let temprpricing = [...values.service_pricings];
    temprpricing[priceindex] = {
      ...temprpricing[priceindex],
      price: value,
    };
    setFieldValue("service_pricings", temprpricing);
  };
  const handleCheckboxChange = (model, checked) => {
    setpricingmodel((prev) => ({ ...prev, [model]: checked }));
    if (!checked) {
      handlepricechange(
        model == "h24"
          ? 0
          : model == "hourly"
            ? 1
            : model == "checkincheckout"
              ? 2
              : "",
      );
    }
  };
  // useEffect(() => {
  //   if (!pricingmodel?.h24) {
  //     handlepricechange(0, null);
  //   }
  //   if (!pricingmodel?.hourly) {
  //     handlepricechange(1, null);
  //   }
  //   if (!pricingmodel?.checkincheckout) {
  //     handlepricechange(2, null);
  //   }
  // }, [pricingmodel]);
  const [confirm, setconfirm] = useState(false);
  return (
    <>
      <Confirm show={confirm} width={40} onClose={() => setconfirm(false)}>
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm(false)}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={deleteservice}
              className="bg-orange-500 text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <div className="flex flex-col justify-start items-start gap-6">
        <div className="text-orangeBg w-full text-lg font-bold flex flex-row items-center">
          <p>Add Service Type</p>
          {deleteservice && (
            <MdDeleteOutline
              style={{ color: "red", height: 25, width: 25 }}
              className="cursor-pointer ml-3"
              onClick={() => setconfirm(true)}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10 w-full mt-4">
        <Dropdown
          formik={formik}
          placeholder={"Service Type"}
          name="service_type"
          myvalue={values?.service_type}
          onBlur={handleBlur}
          errorText={errors.service_type}
          isError={errors?.service_type && touched?.service_type}
          options={[
            { id: "Boarding" },
            { id: "Grooming" },
            { id: "Training" },
            { id: "Day care" },
            { id: "Pet taxi" },
            { id: "Vets" },
          ]}
          label="Service Type"
          variable="id"
          onChange={(e) => {
            setpricingmodel({
              h24: false,
              checkincheckout: false,
              hourly: false,
            });
            setFieldValue("service_pricings", [
              {
                price_modal: "24h",
                price: null,
              },
              {
                price_modal: "Hourly",
                price: null,
              },
              {
                price_modal: "Checkin-Checkout",
                price: null,
                checkin_time: null,
                checkout_time: null,
              },
              {
                price_modal: "Fixed",
                price: null,
              },
            ]);
            setFieldValue("service_type", e);
          }}
        />
        <Input
          id={"service_name"}
          name="service_name"
          label={"Service Name"}
          placeholder="Enter service name"
          errorText={errors.service_name}
          onBlur={handleBlur}
          isError={errors?.service_name && touched?.service_name}
          value={values?.service_name}
          onChange={(e) => setFieldValue("service_name", e.target.value)}
        />

        {values?.service_type != "Boarding" && (
          <Input
            id={"duratiom"}
            name="duratiom"
            label={"Service Duaration"}
            placeholder="Enter Service Duaration"
            value={values?.duration}
            onBlur={handleBlur}
            errorText={errors.duration}
            isError={errors?.duration && touched?.duration}
            onChange={(e) => setFieldValue("duration", e.target.value)}
          />
        )}
        {values?.service_type == "Boarding" && (
          <Dropdown
            formik={formik}
            placeholder={"Select Accommodation"}
            name="accomodation_temp_id"
            myvalue={values?.accomodation_id}
            onBlur={handleBlur}
            errorText={errors.accomodation_id}
            isError={errors?.accomodation_id && touched?.accomodation_id}
            // options={[
            //   { id: "Garden" },
            //   { id: "Grooming" },
            //   { id: "Training" },
            //   { id: "Day care" },
            //   { id: "Pet taxi" },
            //   { id: "Vets" },
            // ]}
            options={
              tempaccommdata
                ? tempaccommdata?.map((rec) => ({
                    id: rec?.id || rec?.temp_id,
                    value: rec?.accomodation_room_type_name,
                  }))
                : accommdata?.data?.rows?.map((rec) => ({
                    id: rec?.id || rec?.temp_id,
                    value: rec?.accomodation_room_type_name,
                  }))
            }
            label="Select Accommodation"
            variable="value"
            mess="id"
            mess2="value"
            onChange={(e) => setFieldValue("accomodation_id", e)}
          />
        )}
      </div>
      <div className="flex flex-row gap-10">
        <div className="mt-8 w-[21.5rem]">
          <Dropdown
            formik={formik}
            placeholder={"Inbound/Outbound"}
            onBlur={handleBlur}
            name="inbound_outbound"
            errorText={errors.inbound_outbound}
            isError={errors?.inbound_outbound && touched?.inbound_outbound}
            myvalue={values?.inbound_outbound}
            options={[{ id: "Inbound" }, { id: "Outbound" }]}
            label="Inbound/Outbound"
            variable="id"
            onChange={(value) => setFieldValue("inbound_outbound", value)}
          />
        </div>
        {values?.service_type != "Boarding" && (
          <div className="mt-8 w-[21.5rem]">
            <Input
              id={"price"}
              name="price"
              label={"Price"}
              onBlur={handleBlur}
              type={"number"}
              placeholder="Enter Price"
              value={values?.service_pricings[3]?.price}
              errorText={errors.service_pricings?.[3]?.price}
              isError={
                !!errors.service_pricings?.[3]?.price &&
                touched?.service_pricings?.[3]?.price
              }
              onChange={(e) => handlepricechange(3, e.target.value)}
            />
          </div>
        )}
        <div className="flex flex-col mt-8 ">
          {values?.service_type === "Boarding" && (
            <>
              <label className="text-[1rem] font-bold">Pricing Model</label>
              <div className="w-full flex flex-col mt-2">
                {values?.service_pricings.map((rec, index) => {
                  const modelName = rec.price_modal
                    .toLowerCase()
                    .replace("-", "");
                  if (rec?.price_modal != "Fixed") {
                    return (
                      <div key={index} className="w-full flex items-center">
                        <input
                          type="checkbox"
                          checked={
                            pricingmodel[modelName == "24h" ? "h24" : modelName]
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              modelName == "24h" ? "h24" : modelName,
                              e.target.checked,
                            )
                          }
                        />
                        <label className="text-black ml-2">
                          {rec.price_modal}
                        </label>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
        <div className="mt-[53px] flex items-center">
          <input
            type="checkbox"
            id={`checkbox-extra`}
            checked={extra}
            onChange={(e) => {
              setextra(e.target.checked);
            }}
          />
          <label htmlFor={`checkbox-extra`} className="text-black ml-2">
            Add Extra Hour Charges
          </label>
        </div>
      </div>
      <div className="mt-8 flex flex-row gap-10">
        {extra && (
          <Input
            id={"exyra_charges"}
            name="hourly_chanrges"
            label={"Extra Hour Charges ₹ (1 Hour)"}
            type={"number"}
            placeholder="Enter Extra Hour Charges"
            value={values?.exceed_time_charge}
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("exceed_time_charge", e.target.value)
            }
          />
        )}
        {pricingmodel?.h24 && (
          <Input
            id={"24h"}
            name="24h"
            label={"24H Charges  ₹"}
            type={"number"}
            placeholder="Enter 24H Charges ₹"
            errorText={errors.service_pricings?.[0]?.price}
            isError={
              !!errors.service_pricings?.[0]?.price &&
              touched?.service_pricings?.[0]?.price
            }
            value={values?.service_pricings[0]?.price}
            onBlur={handleBlur}
            onChange={(e) => handlepricechange(0, e.target.value)}
          />
        )}
        {pricingmodel?.hourly && (
          <Input
            id={"hourly_chanrges"}
            name="hourly_chanrges"
            label={"Hourly Charges ₹ (1 Hour)"}
            placeholder="Enter Hourly Charges"
            errorText={errors.service_pricings?.[1]?.price}
            type={"number"}
            isError={
              !!errors.service_pricings?.[1]?.price &&
              touched?.service_pricings?.[1]?.price
            }
            value={values?.service_pricings[1]?.price}
            onBlur={handleBlur}
            onChange={(e) => handlepricechange(1, e.target.value)}
          />
        )}
      </div>
      <div className=" mt-8 w-full flex flex-row gap-10">
        {pricingmodel?.checkincheckout && (
          <Input
            id={"checkincharges"}
            name="checkincharges"
            label={"CheckIn-CheckOut Charges₹"}
            placeholder="Enter CheckIn CheckOut Charges ₹"
            errorText={errors.service_pricings?.[2]?.price}
            type={"number"}
            isError={
              !!errors.service_pricings?.[2]?.price &&
              touched?.service_pricings?.[2]?.price
            }
            value={values?.service_pricings[2]?.price}
            onBlur={handleBlur}
            onChange={(e) => handlepricechange(2, e.target.value)}
          />
        )}
        {pricingmodel?.checkincheckout && (
          <Input
            id={"checkintime"}
            name="checkincharges"
            label={"CheckIn Time"}
            type={"time"}
            placeholder="CheckIn Time"
            value={values?.service_pricings[2]?.checkin_time}
            onBlur={handleBlur}
            errorText={errors?.service_pricings?.[2]?.checkin_time}
            isError={
              !!errors?.service_pricings?.[2]?.checkin_time &&
              touched?.service_pricings?.[2]?.checkin_time
            }
            onChange={(e) => {
              let temprpricing = values.service_pricings;
              temprpricing[2].checkin_time = e.target.value;
              setFieldValue("service_pricings", temprpricing);
            }}
          />
        )}
        {pricingmodel?.checkincheckout && (
          <Input
            id={"checkouttime"}
            name="checkincharges"
            label={"CheckOut Time"}
            type={"time"}
            placeholder="CheckOut Time"
            value={values?.service_pricings[2]?.checkout_time}
            errorText={errors?.service_pricings?.[2]?.checkout_time}
            isError={
              !!errors?.service_pricings?.[2]?.checkout_time &&
              touched?.service_pricings?.[2]?.checkout_time
            }
            onBlur={handleBlur}
            onChange={(e) => {
              let temprpricing = values.service_pricings;
              temprpricing[2].checkout_time = e.target.value;
              setFieldValue("service_pricings", temprpricing);
            }}
          />
        )}
      </div>
      <div className="mt-8 ">
        <div className="text-gray-700 text-sm font-bold font-poppins">
          Service Description
        </div>
        <div className="bg-white px-0 py-2 rounded-lg mt-2">
          <textarea
            className="w-full h-36 text-sm text-gray-700 bg-gray-100 font-normal font-poppins resize-none rounded-md p-2 focus:outline-none border-none"
            placeholder="Enter service description"
            value={values?.service_description}
            onChange={(e) =>
              setFieldValue("service_description", e.target.value)
            }
          />
          {!!errors?.service_description && (
            <p className="ml-1 font-normal text-sm text-red-500">
              {errors?.service_description}{" "}
            </p>
          )}
        </div>
      </div>

      <div className=" mt-8 w-full flex flex-row gap-10">
        <div className="flex flex-col w-[50%]">
          <label className="font-bold text-[1rem]">
            Select Week Days Service is Available
          </label>
          <Select
            className="mt-2 "
            value={values.availibilies?.map((rec) => ({
              value: rec?.day,
              label: rec?.day,
            }))}
            placeholder={"select Days"}
            isMulti={true}
            // value={selectedOption}
            onChange={(val) => {
              setFieldValue(
                "availibilies",
                val?.map((rec) => ({ day: rec?.value })),
              );
              // setValues({ ...values, services_provided: currentvalues });
            }}
            options={options}
          />
        </div>
        <Input
          id={"from"}
          name="checkincharges"
          label={"From"}
          type={"time"}
          placeholder="From"
          errorText={errors?.from_time}
          isError={!!errors?.from_time && touched?.from_time}
          value={values?.from_time}
          onBlur={handleBlur}
          onChange={(e) => setFieldValue("from_time", e.target.value)}
        />

        <Input
          id={"to"}
          name="checkincharges"
          label={"To"}
          type={"time"}
          placeholder="To"
          errorText={errors?.to_time}
          isError={!!errors?.to_time && touched?.to_time}
          value={values?.to_time}
          onBlur={handleBlur}
          onChange={(e) => setFieldValue("to_time", e.target.value)}
        />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center mt-8">
        <div className=" w-full rounded-lg">
          <div className="bg-white p-4 rounded-md flex w-full items-center justify-between">
            <div className="flex flex-col space-x-2 space-y-2">
              {isLoading && "Uploading..."}
              {deleteLoading && "Deleting..."}
              <FileUploadField
                multiple={true}
                label="Center photo"
                placeholder="No file selected"
                handleChange={(e) => {
                  handleFileSelect(e);
                }}
              />
              <div className="flex flex-row w-full">
                {values?.services_photos &&
                  values?.services_photos?.map((rec) => (
                    <UploadedFilePlaceholder
                      name={rec?.key || rec?.file?.key}
                      link={rec?.url || rec?.file?.url}
                      onRemove={() => {
                        deleteFile(
                          new URLSearchParams({
                            id: rec?.id,
                          }),
                        );
                        setFieldValue(
                          "services_photos",
                          values.services_photos.filter(
                            (myrec) => myrec.id != rec?.id,
                          ),
                        );
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-end justify-end">
        {" "}
        <button
          type="submit"
          onClick={formik.handleSubmit}
          className="p-2 bg-orange-500 rounded-lg text-white"
        >
          {" "}
          Save
        </button>
      </div>
    </>
  );
}
function ServiceEdit() {
  return (
    <Container>
      <div className="mt-5 p-[3rem] bg-white h-full">
        <ServiceEditForm />
      </div>
    </Container>
  );
}
export { ServiceEdit, ServiceEditForm };
