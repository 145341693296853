import { useEffect, useState } from "react";
import DashboardStatCard from "components/Common/BookingReservation/DashboardStatCard";
import Container from "components/Common/ui/Container";
import DataTable from "../../components/Common/ui/Table";
import TableContainer from "components/Common/ui/TableContainer";
import React from "react";
import ActionButtonContainer from "components/Common/ui/ActionButtonContainer";
import IconButton from "components/Common/ui/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import SingleSelect from "components/Common/ui/SingleSelect";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFilterAppointmentQuery,
  useServiceProvidedAppointment2Query,
  useServiceProvidedAssignAppointmentsMutation,
  useChangeAppointmentStatusMutation,
} from "store/features/appointment/appointmentApiSlice";
import { useDispatch } from "react-redux";
import { setmenu } from "store/StateSlice/layoutSlice";
import Modal from "components/Common/ui/Modal";
import { Formik } from "formik";
import { FaUserPlus } from "react-icons/fa6";
import {
  useGetStaffQuery,
} from "store/features/staff/staffApiSlice";
import toast from "react-hot-toast";
import MuiModal from "@mui/material/Modal";
import { FiAlertTriangle } from "react-icons/fi";
import { Box } from "@mui/material";
import Button from "components/Common/ui/Button";
import Input from "components/Common/ui/Input";
import moment from "moment";

const statusInitial = {
  state: false,
  type: null, // Either CHECKIN or CHECKOUT
  modalType: "Mark", // Either Mark or Edit
  data: {
    id: null,
    status: null,
    date: new Date().toISOString().split("T")[0]
  }
}

const BookingReservation = () => {
  const [statusModal, setStatusModal] = useState(statusInitial);
  const location = useLocation();
  const paramValue = new URLSearchParams(location.search).get("query");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setmenu("booking"));
  }, []);
  const navigate = useNavigate();
  const { isLoading: serviceAppointmentLoading, data: appointmentData } =
    useServiceProvidedAppointment2Query();
  const [tab, settab] = useState("checkin");

  useEffect(() => {
    if (paramValue) {
      settab(paramValue);
    }
  }, [paramValue]);

  const columnsCheckIn = () => [
    {
      Header: "Pet Name",
      accessor: (row) => row?.pet?.pet?.name,
    },
    {
      Header: "Pet parent",
      accessor: (row) =>
        row?.service_appointment_master.petparent.first_name +
        " " +
        row?.service_appointment_master.petparent.last_name,
    },
    {
      Header: "Check-In Date",
      accessor: (row) => row?.check_in_date ? moment(row.check_in_date).format("DD/MM/YYYY hh:mm a") : "N/A",
    },
    // {
    //   Header: "service name",
    //   accessor: (row) => row?.service_user?.service_name,
    // },
    {
      Header: "service type",
      accessor: (row) => row?.service?.service_type,
    },
    {
      Header: "Booking Value",
      accessor: (row) => row?.cost || 0,
    },

    {
      Header: "Status",
      accessor: (row) => row?.status || "UPCOMING",
    },
    {
      Header: "View",
      accessor: (row) => (
        <ActionButtonContainer>
          <IconButton
            onClick={() => navigate("/booking-reservation/appointment/1")}
          >
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          {/* <IconButton>
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton>
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
        </ActionButtonContainer>
      ),
    },
  ];

  const columnsCheckOut = () => [
    {
      Header: "Pet Name",
      accessor: (row) => row?.pet?.pet?.name,
    },
    {
      Header: "Pet parent",
      accessor: (row) =>
        row?.service_appointment_master.petparent.first_name +
        " " +
        row?.service_appointment_master.petparent.last_name,
    },
    /* {
      Header: "Check-In Date",
      accessor: (row) => row?.check_in_date || "N/A",
    }, */
    {
      Header: "Check-Out Date",
      accessor: (row) => <span
        onClick={() => {
          setStatusModal(prev => ({
            state: true,
            type: "CHECKOUT",
            modalType: row?.status === "CHECKOUT" ? "Edit" : "Mark",
            data: {
              id: row?.id,
              status: "",
              date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
            }
          }));
        }}
        className="hover:cursor-pointer ">  <BorderColorIcon className="text-[#D99149] " style={{ width: "15px", height: "15px" }} /> {row?.check_out_date ? moment(row?.check_out_date).format("DD/MM/YYYY hh:mm a") : "N/A"} </span>,

    },
    {
      Header: "Status",
      accessor: (row) => row?.status || "UPCOMING",
    },

    {
      Header: "service type",
      accessor: (row) => row?.service?.service_type,
    },
    {
      Header: "View",
      accessor: (row) => (
        <ActionButtonContainer>
          <IconButton
            onClick={() => navigate("/booking-reservation/appointment/1")}
          >
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          {/* <IconButton>
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
          {/* <IconButton>
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
        </ActionButtonContainer>
      ),
    },
  ];
  const { isLoading: staffLoading, data } = useGetStaffQuery();
  const [modal, setModal] = useState({
    state: false,
    appointment_id: null,
    staff_id: null
  });
  const [
    serviceProvidedAssignAppointments,
    {
      isLoading: assingLoading,
      isError: assignError,
      isSuccess: assignSuccess,
    },
  ] = useServiceProvidedAssignAppointmentsMutation();

  const [
    changeAppointmentStatus,
    {
      isLoading: changeStatusLoading,
      isError: changeStatusError,
      isSuccess: changeStatusSuccess,
    }
  ] = useChangeAppointmentStatusMutation();

  useEffect(() => {
    if (assignSuccess) {
      setModal({
        state: false,
        appointment_id: null,
        staff_id: null
      });

      toast.success("Staff assigned successfully");
    }

    if (assignError) {
      toast.error("Failed to assign staff");
    }

    if (changeStatusSuccess) {
      setStatusModal(statusInitial);
      toast.success("Status changed successfully");
    }

    if (changeStatusError) {
      toast.error("Failed to change status");
    }


  }, [assignSuccess, assignError, changeStatusSuccess, changeStatusError]);
  const assignUserModal = () => {
    return (
      <Modal
        state={modal.state}
        handleState={() => setModal({
          state: false,
          appointment_id: null,
          staff_id: null
        })}
        width={500}
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            Assign Staff
          </p>
          <Formik
            initialValues={{
              staff_id: modal.staff_id,
              appointment_id: modal.appointment_id
            }}
            onSubmit={async (values) => {
              await serviceProvidedAssignAppointments(values);
            }}
          >
            {({ handleSubmit, handleBlur, handleChange, errors, touched, values }) => (
              <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                <SingleSelect
                  label={"Select Staff"}
                  options={data?.data
                    ?.filter((item) => item?.role === "PET_HANDLER")
                    ?.map((item) => ({
                      label: item?.first_name + " " + item?.last_name,
                      value: item?.id,
                    }))}

                  id={"staff_id"}
                  name="staff_id"
                  value={values?.staff_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.staff_id && touched?.staff_id}
                  errorText={errors?.staff_id}
                />
                <div className="flex flex-row justify-center items-center space-x-4">
                  <button
                    disabled={assingLoading}
                    type="submit"
                    className="bg-[#FF6317] text-white w-full font-semibold text-[14px] rounded-lg p-2 w-[100px] h-[40px] flex justify-center items-center"
                  >
                    {assingLoading ? "Assigning..." : "Assign"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    )
  }
  const changeStatusModal = () => {
    const {
      state,
      type,
      modalType,
      data: formdata
    } = statusModal;
    return (
      <Modal
        state={state}
        sx={{ width: 600, padding: 0 }}
        handleState={() => setStatusModal(statusInitial)}
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {type === "CHECKIN" || type === "COMPLETED" ? "" : `${modalType} Check-Out`}
          </p>
          {type === "CHECKIN" || type === "COMPLETED" ? (<Box>

            <div
              className="flex items-center space-x-4"
            >
              <span
                className="text-orange-500 text-2xl p-2 flex items-center justify-center bg-orange-100 rounded-full self-start"
              >
                <FiAlertTriangle />
              </span>
              <div
                className="flex flex-col gap-2"
              >
                <h2
                  className="text-lg font-semibold"
                >
                  Are you sure ?
                </h2>
                <p
                  className="text-sm"
                >
                  Are you sure you want to mark this appointment as {type === "CHECKIN" ? "Check-In" : "Completed"} ?

                </p>
              </div>
            </div>
            <div
              className="flex justify-end mt-4 space-x-4"
            >
              <Button
                onClick={() => setStatusModal(statusInitial)}
                disabled={changeStatusLoading}
                className="bg-gray-300 text-gray-800 w-max"
              >Cancel</Button>
              <Button
                className="w-max"
                disabled={changeStatusLoading}
                onClick={async () => {
                  const payload = {
                    id: formdata?.id,
                    status: formdata?.status,

                  }
                  await changeAppointmentStatus(payload);
                }}
              >
                Mark {type === "CHECKIN" ? "Check-In" : type === "COMPLETED" ? "Completed" : "Check-Out"}

              </Button>
            </div>
          </Box>) : (
            <Formik
              initialValues={formdata}
              enableReinitialize={true}
              onSubmit={async (values) => {
                const payload = {
                  id: values?.id,
                  status: "CHECKOUT",
                  check_out_date: values?.date
                }

                await changeAppointmentStatus(payload);

              }}
            >
              {({ handleSubmit, handleBlur, handleChange, errors, touched, values }) => (
                <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                  <Input
                    label={"Check-Out Date"}
                    type="date"
                    value={values?.date}
                    name="date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={true}
                    isError={errors?.date && touched?.date}
                    errorText={errors?.date}
                  />
                  <div className="flex flex-row justify-end items-center space-x-4">
                    <div
                      className="flex justify-end mt-4 space-x-4"
                    >
                      <Button
                        type="button"
                        disabled={changeStatusLoading}
                        onClick={() => setStatusModal(statusInitial)}
                        className="bg-gray-300 text-gray-800 w-max"
                      >Cancel</Button>
                      <Button
                        type="submit"
                        className="w-max"
                        disabled={changeStatusLoading}
                      >
                        Save {type === "CHECKIN" ? "Check-In" : "Check-Out"}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>

          )}
        </div>
      </Modal>
    )
  }

  const appointmentColumns = () => [
    {
      Header: "Pet Name",
      accessor: (row) => row?.pet?.pet?.name,
    },
    {
      Header: "Pet parent",
      accessor: (row) =>
        row?.service_appointment_master.petparent.first_name +
        " " +
        row?.service_appointment_master.petparent.last_name,
    },
    {
      Header: "start date",
      accessor: (row) => moment(row?.start_date).format("DD/MM/YYYY hh:mm a"),
    },
    {
      Header: "end date",
      accessor: (row) => moment(row?.end_date).format("DD/MM/YYYY hh:mm a"),
    },
    // {
    //   Header: "start time",
    //   accessor: (row) => row?.start_time,
    // },
    // {
    //   Header: "End time",
    //   accessor: (row) => row?.end_time,
    // },


    {
      Header: "service type",
      accessor: (row) => row?.service?.service_type,
    },
    {
      Header: "Booking Value",
      accessor: (row) => row?.cost || 0,
    },
    {
      Header: "Status",
      accessor: (row) => row?.status || "UPCOMING",
    },
    {
      Header: "Assigned Staff",
      accessor: (row) => row?.service_assigned_appointments?.map((item) => item?.staff?.first_name + " " + item?.staff?.last_name).join(", "),
    },
    {
      Header: "Action",
      accessor: (row) => (
        <ActionButtonContainer>
          {!row?.check_in_date && <button
            className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
            onClick={() => {
              setStatusModal(prev => ({
                state: true,
                type: "CHECKIN",
                modalType: row?.status === "CHECKIN" ? "Edit" : "Mark",
                data: {
                  id: row?.id,
                  status: "CHECKIN",
                  date: null
                }
              }));
            }}
          >
            Mark check-In
          </button>}
          {row?.check_in_date && !row.check_out_date ? <button
            className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
            onClick={() => {
              setStatusModal(prev => ({
                state: true,
                type: "CHECKOUT",
                modalType: row?.status === "CHECKOUT" ? "Edit" : "Mark",
                data: {
                  id: row?.id,
                  status: "CHECKOUT",

                  date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
                }
              }));
            }}
          >
            Mark Check-Out
          </button> : ""}

          {row?.status === "CHECKOUT" ? <button
            className="bg-[#FFEEDE] cursor-pointer text-[#D99149] text-xs px-2 py-1 font-bold rounded-md"
            onClick={() => {
              setStatusModal(prev => ({
                state: true,
                type: "COMPLETED",
                modalType: row?.status === "COMPLETED" ? "Edit" : "Mark",
                data: {
                  id: row?.id,
                  status: "COMPLETED",
                  date: row.check_out_date ? moment(row.check_out_date).format("YYYY-MM-DD") : null
                }
              }));
            }}
          >
            Mark Completed
          </button> : ""}
          {row.status !== "COMPLETED" && row.status !== "CHECKOUT" && <IconButton

            onClick={() => {
              setModal(prev => ({
                ...prev,
                state: true,
                appointment_id: row?.id,
              }));
            }}
          >
            <FaUserPlus style={{ width: "15px", height: "15px" }} />
          </IconButton>}
        </ActionButtonContainer>
      ),
    },
  ];

  const [filters, setFilters] = useState({
    date: "",
    status: ""
  });

  return (
    <Container title={"Booking Reservation"}>
      {assignUserModal()}
      {changeStatusModal()}
      <div className="grid grid-cols-3 gap-4 mb-8">
        {/* <DashboardStatCard data={{ title: "Total Bookings", count: "7,552" }} />
        <DashboardStatCard
          data={{
            title: "Upcoming reservations",
            count: "7,552",
          }}
        />
        <DashboardStatCard
          data={{ title: "Booking revenue generated", count: "7,552" }}
        /> */}
      </div>

      <div className="flex flex-col space-y-16">
        {serviceAppointmentLoading ? (
          <div>Loading...</div>
        ) : appointmentData?.data?.length > 0 ? (
          <div className="w-full flex-col">
            <div className="flex flex-row w-[50%] cursor-pointer rounded-lg select-none">
              <span
                onClick={() => settab("checkin")}
                className={`${tab == "checkin" ? "bg-orangeBg text-white" : "bg-white text-black"} pl-5 pr-5 p-2  text-black font-bold transition ease-in-out rounded-l-lg`}
              >
                {" "}
                CheckIn
              </span>{" "}
              <span
                onClick={() => settab("checkout")}
                className={`${tab == "checkout" ? "bg-orangeBg text-white" : "bg-white text-black"} pl-5 pr-5 p-2  text-black font-bold transition ease-in-out`}
              >
                {" "}
                CheckOut
              </span>{" "}
              <span
                onClick={() => settab("appointment")}
                className={`${tab == "appointment" ? "bg-orangeBg text-white" : "bg-white text-black"} pl-5 pr-5 p-2  text-black font-bold transition ease-in-out  rounded-r-lg`}
              >
                {" "}
                Appointment
              </span>
            </div>
            {tab == "checkin" ? (
              <TableContainer title="Check-In">
                <DataTable
                  columns={columnsCheckIn()}
                  data={appointmentData?.data
                    ?.filter((rec) => rec?.status === "CHECKIN")
                    ?.filter(
                      (rec) => new Date() > new Date(rec?.start_date),
                    ) || []}
                  filterArea={
                    <TableFilterContainer>
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Check-In Date"}
                        options={[]}
                        id={"check_in_date"}
                        name="check_in_date"
                        onChange={(e) => { }}
                      />

                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Check-Out Date"}
                        options={[]}
                        id={"check_out_date"}
                        name="check_out_date"
                        onChange={(e) => { }}
                      />

                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Status"}
                        options={[]}
                        id={"status"}
                        name="status"
                        onChange={(e) => { }}
                      />
                    </TableFilterContainer>
                  }
                />
              </TableContainer>
            ) : tab == "checkout" ? (
              <TableContainer title="Check-Out">
                <DataTable
                  columns={columnsCheckOut()}
                  data={appointmentData?.data?.filter((rec) => rec?.status === "CHECKOUT") || []}
                  filterArea={
                    <TableFilterContainer>
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Check-In Date"}
                        options={[]}
                        id={"check_in_date"}
                        name="check_in_date"
                        onChange={(e) => { }}
                      />

                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Check-Out Date"}
                        options={[]}
                        id={"check_out_date"}
                        name="check_out_date"
                        onChange={(e) => { }}
                      />

                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Status"}
                        options={[]}
                        id={"status"}
                        name="status"
                        onChange={(e) => { }}
                      />
                    </TableFilterContainer>
                  }
                />
              </TableContainer>
            ) : tab == "appointment" ? (
              <TableContainer title="Appointments">
                <DataTable
                  columns={appointmentColumns()}
                  data={appointmentData?.data?.filter((item) => {
                    if (filters.date) {
                      return item?.start_date === filters?.date + "T00:00:00.000Z";
                    }
                    return item;
                  })
                    ?.filter((item) => {
                      if (filters.status) {
                        return item?.status === filters?.status;
                      }
                      return item;
                    }
                    )

                    || []}
                  filterArea={
                    <TableFilterContainer>
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Booking Value"}
                        options={[]}
                        id={"booking_value"}
                        name="booking_value"
                        onChange={(e) => { }}
                      />

                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Service Status"}
                        options={[
                          { label: "UPCOMING", value: "UPCOMING" },
                          { label: "CHECK IN", value: "CHECKIN" },
                          { label: "CHECK OUT", value: "CHECKOUT" },
                          { label: "ASSIGNED", value: "ASSIGNED" },
                          { label: "COMPLETED", value: "COMPLETED" },
                          { label: "CANCELLED", value: "CANCELLED" },
                        ]}
                        id={"service_status"}
                        name="service_status"
                        value={filters.status}
                        onChange={(e) => {
                          setFilters({ ...filters, status: e.target.value });
                        }}
                      />
                      <div className=" flex flex-row  items-end justify-end">
                        <input
                          label="Date"
                          id="admin_date"
                          type="date"
                          name="start_date"
                          onChange={(e) => {
                            setFilters({ ...filters, date: e.target.value });
                          }}
                          value={filters.date}
                          required={true}
                          className={`block w-max rounded-md border-0 py-1.5 p-2 text-gray-900 shadow-sm ring-0 ring-inset  placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
                  `}
                        />
                      </div>
                    </TableFilterContainer>
                  }
                />
              </TableContainer>
            ) : (
              ""
            )}
          </div>
        ) : (
          <h1>NO Data to show</h1>
        )}
      </div>
    </Container>
  );
};

export default BookingReservation;
