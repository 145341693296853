import React from "react";

const TableContainer = ({
  className,
  title,
  width = 100,
  children,
  actionButtons,
}) => {
  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-[25px] font-poppins font-semibold">{title}</p>
          <div className={`w-[${width}px] bg-[#D99149] h-[1px]`}></div>
        </div>
        <div className="flex flex-row">{actionButtons}</div>
      </div>
      <div className="w-full mt-4">{children}</div>
    </div>
  );
};

export default TableContainer;
