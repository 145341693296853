/* eslint-disable no-unused-expressions */
import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllAccommodationQuery } from "store/features/accommodation/accommodationApiSlice";
import IconButton from "components/Common/ui/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import TableContainer from "components/Common/ui/TableContainer";
import Button from "components/Common/ui/Button";
import SingleSelect from "components/Common/ui/SingleSelect";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import DataTable from "components/Common/ui/Table";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { setmenu } from "store/StateSlice/layoutSlice";
import { useDeleteAccommodationMutation } from "store/features/accommodation/accommodationApiSlice";
const AccommodationManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteaccom, deleteaccomstate] = useDeleteAccommodationMutation();

  const { id } = useParams();
  const { data, isLoading } = useGetAllAccommodationQuery();
  const [accommodationdata, setaccommodationdata] = useState();
  const [filters, setFilters] = useState({
    ac_or_nonAc: "",
    outdoor_or_indoor: "",
    single_or_group: "",
    standard_or_medical_or_special: "",
  });
  useEffect(() => {
    dispatch(setmenu("accomodation"));
  }, []);
  const columns = () => [
    {
      accessor: (d) => d?.accomodation_room_type_name,
      Header: "Type",
    },
    {
      accessor: (row) => `${row?.accomodation_number_of_rooms}`,
      Header: "Number of Rooms",
    },
    {
      accessor: (row) => row?.single_or_group,
      Header: "Single Group",
    },
    {
      accessor: (row) => row?.ac_or_nonAc,
      Header: "Ac/NonAC",
    },
    {
      accessor: (row) => row?.outdoor_or_indoor,
      Header: "Outdoor or Indoor",
    },

    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          {/* <IconButton
            onClick={() => navigate(`/accomadation-management/${d?.id}`)}
          >
            <Visibility style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
          <IconButton
            onClick={() => {
              navigate(`/accomadation-management/${d?.id}`);
            }}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          // onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];
  useEffect(() => {
    if (data) {
      const filteredData = data.data.rows
      setaccommodationdata(filteredData);
    }
  }, [data, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <Container title={"Accommodation Management"}>
      <InfoPageHeader
        title={"Accommodation Management"}
        backBtnFunction={() => navigate(-1)}
      />
      {isLoading
        ? "Loading..."
        : accommodationdata && (
          <div className="flex flex-col space-y-16">
            <TableContainer
              title="Accommodation details"
              actionButtons={
                <Button
                  onClick={() => navigate(`/accomadation-management/new`)}
                >
                  Add
                </Button>
              }
            >

              <DataTable
                columns={columns()}
                data={accommodationdata
                  ?.filter((item) => {
                    return filters.ac_or_nonAc ? item.ac_or_nonAc === filters.ac_or_nonAc : true;
                  })
                  ?.filter((item) => {
                    return filters.outdoor_or_indoor ? item.outdoor_or_indoor === filters.outdoor_or_indoor : true;
                  })
                  ?.filter((item) => {
                    return filters.single_or_group ? item.single_or_group === filters.single_or_group : true;
                  })
                  ?.filter((item) => {
                    return filters.standard_or_medical_or_special ? item.special_medical_care === filters.standard_or_medical_or_special : true;
                  })
                }
                filterArea={
                  <TableFilterContainer>
                    <SingleSelect
                      showLabel={false}
                      showLabelInOption={false}
                      className="!bg-white"
                      label={"Ac/NonAC"}
                      options={[
                        { label: "Select AC/Non-AC", value: "" },
                        { label: "AC", value: "AC" },
                        { label: "NonAC", value: "NonAC" },
                      ]}
                      id={"ac_or_nonAc"}
                      name="ac_or_nonAc"
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ac_or_nonAc: e.target.value,
                        }));
                      }}
                    />
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Outdoor/Indoor"}
                      showLabelInOption={false}
                      options={[
                        { label: "Select Outdoor/Indoor", value: "" },
                        { label: "Outdoor", value: "Outdoor" },
                        { label: "Indoor", value: "Indoor" },
                      ]}
                      id={"outdoor_or_indoor"}
                      name="outdoor_or_indoor"
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          outdoor_or_indoor: e.target.value,
                        }));
                      }}
                    />
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      showLabelInOption={false}
                      label={"Single/Group"}
                      options={[
                        { label: "Select Single/Group", value: "" },
                        { label: "Single", value: "Single" },
                        { label: "Group", value: "Group" },
                      ]}
                      id={"single_or_group"}
                      name="single_or_group"
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          single_or_group: e.target.value,
                        }));
                      }}
                    />
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      showLabelInOption={false}
                      label={"Standard/Medical/Special"}
                      options={[
                        { label: "Select Standard/Medical/Special", value: "" },
                        { label: "Standard", value: "Standard" },
                        { label: "Special", value: "Special" },
                        { label: "Medical", value: "Medical" },
                      ]}
                      id={"single_or_group"}
                      name="single_or_group"
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          standard_or_medical_or_special: e.target.value,
                        }));
                      }}
                    />
                  </TableFilterContainer>
                }
              />
            </TableContainer>
          </div>
        )}
    </Container>
  );
};

export default AccommodationManagement;
