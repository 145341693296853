import Container from "components/Common/ui/Container";
import InfoPageHeader from "components/Common/ui/InfoPageHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllAccommodationQuery } from "store/features/accommodation/accommodationApiSlice";
import IconButton from "components/Common/ui/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import TableContainer from "components/Common/ui/TableContainer";
import Button from "components/Common/ui/Button";
import SingleSelect from "components/Common/ui/SingleSelect";
import TableFilterContainer from "components/Common/ui/TableFilterContainer";
import DataTable from "components/Common/ui/Table";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { setmenu } from "store/StateSlice/layoutSlice";
import { useDeleteAccommodationMutation } from "store/features/accommodation/accommodationApiSlice";
import { useGetAllServicesQuery } from "store/features/services/serviceApiSlice";
const ServiceManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteaccom, deleteaccomstate] = useDeleteAccommodationMutation();

  const { id } = useParams();
  const { data, isLoading } = useGetAllAccommodationQuery();
  const { data: servicedataapi, isLoading: serviceloading } =
    useGetAllServicesQuery();

  const [servicedata, setservicedata] = useState();
  const [filters, setFilters] = useState({
    service_type: "",
    pricing_model: "",
  });
  useEffect(() => {
    dispatch(setmenu("service"));
  }, []);
  const columns = () => [
    {
      accessor: (d) => d?.service_type,
      Header: "Type",
    },
    {
      accessor: (row) => `${row?.service_name}`,
      Header: "Name",
    },
    {
      accessor: (row) => (
        <div className="flex flex-row gap-3 items-center text-center justify-center">
          {row?.service_pricings?.map((rec) => (
            <span className="bg-orange-100 text-black flex p-1 rounded-lg">
              {rec?.price_modal}
            </span>
          ))}
        </div>
      ),
      Header: "Pricing Models",
    },

    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          {/* <IconButton
            onClick={() => navigate(`/accomadation-management/${d?.id}`)}
          >
            <Visibility style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
          <IconButton
            onClick={() => {
              console.log(d);
              navigate(`/service-management/${d?.id}`);
            }}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          // onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];
  useEffect(() => {
    if (servicedataapi) {
      //   const filteredData = data.data.rows.filter((item) => {
      //     return (
      //       (filters.ac_or_nonAc === "Ac/NonAC" ||
      //         item.ac_or_nonAc === filters.ac_or_nonAc) &&
      //       (filters.outdoor_or_indoor === "Outdoor/Indoor" ||
      //         item.outdoor_or_indoor === filters.outdoor_or_indoor) &&
      //       (filters.single_or_group === "Single/Group" ||
      //         item.single_or_group === filters.single_or_group)
      //     );
      //   });
      setservicedata(servicedataapi.data.rows);
    }
  }, [servicedataapi, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  console.log(servicedata);

  return (
    <Container title={"Service  Management"} className=" h-full">
      <InfoPageHeader
        title={"Service Management"}
        backBtnFunction={() => navigate(-1)}
      />
      {serviceloading
        ? "Loading..."
        : servicedata && (
          <div className="flex flex-col space-y-16">
            <TableContainer
              title="Service details"
              actionButtons={
                <Button onClick={() => navigate(`/service-management/new`)}>
                  Add
                </Button>
              }
            >
              {console.log(filters)}
              <DataTable
                columns={columns()}
                data={servicedata
                  ?.filter((item) => {
                    return filters.service_type ? item.service_type === filters.service_type : true

                  })
                  ?.filter((item) => {
                    return filters.pricing_model ? item.service_pricings.find((rec) => rec.price_modal === filters.pricing_model) : true
                  })}
                filterArea={
                  <TableFilterContainer>
                    <SingleSelect
                      showLabel={false}
                      showLabelInOption={false}
                      className="!bg-white"
                      label={"Service Type"}
                      options={
                        [{
                          label: "Service Type",
                          value: ""
                        }, ...["Boarding", "Grooming", "Training", "Daycare", "Pet taxi", "Vets"].map((item) => ({
                          label: item,
                          value: item,
                        }))]
                      }
                      value={filters.service_type}
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          service_type: e.target.value,
                        }));
                      }}
                      id={"ac_or_nonAc"}
                      name="ac_or_nonAc"
                    />
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Pricing Model"}
                      showLabelInOption={false}
                      options={[
                        { label: "Pricing Modal", value: "" },
                        { label: "Hourly", value: "Hourly" },
                        { label: "Fixed", value: "Fixed" },
                      ]}
                      id={"ac_or_nonAc"}
                      value={filters.pricing_model}
                      name="ac_or_nonAc"
                      onChange={(e) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          pricing_model: e.target.value,
                        }))
                      }}
                    />

                  </TableFilterContainer>
                }
              />
            </TableContainer>
          </div>
        )}
    </Container>
  );
};

export default ServiceManagement;
