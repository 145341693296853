import React from "react";
import ServiceCard from "../Cards/Dashboard/ServiceCard";

const DateModal = ({ DateServices, date }) => {
  return (
    <div className="w-full  p-2 flex justify-start">
      <div className="flex flex-col"> 
        <div className="mb-4">
          <span className="text-2xl font-semibold">{date}</span>
        </div>
        <div className="grid grid-cols-3 gap-4 h-[450px] overflow-y-auto p-2 ">
          {DateServices.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              time={service.time}
              petName={service.petName}
              tags={service.tags}
              price={service.price}
              status={service.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DateModal;
