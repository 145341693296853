import React from "react";

const TableFilterContainer = ({ children }) => {
  return (
    <div className="flex flex-col">
      <span className="text-sm font-semibold">Filter By</span>
      <div className="flex flex-row space-x-2">{children}</div>
    </div>
  );
};

export default TableFilterContainer;
