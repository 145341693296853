import React from "react";
import InputField from "../Common/AuthPages/InputField";

const AddaBooking = ({ setIsOpen }) => {
    const handleCloseModal = () => {
        setIsOpen(false);
      };
  return (
    <div className="max-w-4xl mx-auto py-1 px-12 bg-white flex flex-col gap-6 rounded-full">
      <div className="flex flex-col items-center space-y-2">
        <div className="text-zinc-700 text-4xl font-bold">Add a booking</div>
        <div className="border-b-2 border-orangeBg flex-grow w-60"></div>
      </div>
      <div className="w-full mx-auto space-y-8">
        <div className="grid grid-cols-2 gap-x-4">
          <InputField label="Pet Name" placeholder="Rocky" />
          <InputField label="Mobile Number" placeholder="+91 72XXX XXXXX" />
          <div className="">
            <span className="text-gray-700 text-sm font-bold mb-1">Pet Type</span>
            <div className="h-10 bg-gray-300 rounded">
              <select className="w-[300px] md:w-[350px] p-2 bg-zinc-100 rounded-lg">
                <option className="text-gray-400" value="dog">
                  Dog
                </option>
                <option className="text-gray-400" value="cat">
                  Cat
                </option>
                <option className="text-gray-400" value="other">
                  Other
                </option>
              </select>
            </div>
          </div>
          <InputField label="Pet Parent Name" placeholder="Vijay" />
          <div className="">
            <span className="text-gray-700 text-sm font-bold mb-1">Service Type</span>
            <div className="h-10 bg-gray-300 rounded">
              <select className="w-[300px] md:w-[350px] p-2 bg-zinc-100 rounded-lg">
                <option className="text-gray-400" value="grooming">
                  Grooming
                </option>
                <option className="text-gray-400" value="boarding">
                  Boarding
                </option>
                <option className="text-gray-400" value="training">
                  Training
                </option>
              </select>
            </div>
          </div>

          <InputField label="Email" placeholder="Email" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div className="relative bg-white rounded border-2 border-orange-400 flex items-center w-1/3 h-10 px-2">
              <input type="time" className="text-black text-lg w-full" />
            </div>
            <div className="relative bg-white rounded border-2 border-orange-400 flex items-center w-72 h-10 px-2">
              <input type="date" className="text-black text-lg w-full" />
            </div>
          </div>
          <div className="relative bg-white rounded border-2 border-orange-400 flex items-center px-4 w-80 h-10">
            <span className="mr-20">Select Services</span>
            <select className="text-orange-400 font-semibold text-md">
              <option className="text-gray-400" value="grooming">
                Grooming
              </option>
              <option className="text-gray-400" value="boarding">
                Boarding
              </option>
              <option className="text-gray-400" value="training">
                Training
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-6 mt-6">
        <button className="w-28 h-11 bg-orange-400 rounded-xl text-white text-md font-semibold" onClick={handleCloseModal}>
          Book Now
        </button>
        <button className="w-28 h-11 bg-orange-100 rounded-xl text-orange-400 text-md font-semibold" onClick={handleCloseModal}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddaBooking;
