import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { setmenu } from "store/StateSlice/layoutSlice";
// import FileUploadField from "../../Common/AuthPages/FileUploadField";
// import ToggleSwitch from "../../Common/AuthPages/ToggleSwitch";
// import CustomToggle from "../../Common/AuthPages/CustomToggle";
import FileUploadField from "components/Common/AuthPages/FileUploadField";
import Input from "components/Common/ui/Input";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import toast from "react-hot-toast";
import UploadedFilePlaceholder from "components/Common/ui/UploadedFilePlaceholder";
import { MdDeleteOutline } from "react-icons/md";
import CustomToggle from "components/Common/AuthPages/CustomToggle";
import ToggleSwitch from "components/Common/AuthPages/ToggleSwitch";
import { useDispatch } from "react-redux";
import {
  useGetOneAccommodationQuery,
  useUpdateAccommodationMutation,
  useCreateAccommodationMutation,
} from "store/features/accommodation/accommodationApiSlice";
import Container from "components/Common/ui/Container";
import { useGetAllAccommodationQuery } from "store/features/accommodation/accommodationApiSlice";
import Confirm from "components/Common/ui/Confirm";
function AccommodationEditForm({
  stepform,
  formsubmit,
  deleteaccomm,
  allaccom,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();
  const [updateaccom, updateaccomstate] = useUpdateAccommodationMutation();
  const [createaccom, createaccomstate] = useCreateAccommodationMutation();
  const selectvalidate = useSelector((state) => state.stepform.valdiate);
  const { data: allaccomm, isLoading: allaccommloading } =
    useGetAllAccommodationQuery(
      { id },
      { skip: id == "new" || stepform != null },
    );
  const [currentIndex, setCurrentIndex] = useState(null);
  const { data: single, isLoading: singleloading } =
    useGetOneAccommodationQuery(
      { id },
      { skip: id == "new" || stepform != null },
    );

  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
    },
  ] = useDeleteFileMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Files uploaded successfully");
      setFieldValue("service_accomodation_photos", [
        ...values?.service_accomodation_photos,
        ...data?.data,
      ]);
    }
    if (isError) {
      toast.error("Error uploading files");
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (deleteError) {
      toast.error("Error deleting file");
    }

    if (deleteSuccess) {
      toast.success("File deleted successfully");
      //   setValues((prev) => ({
      //     ...prev,
      //     accomodations: { ...prev.accomodations, picture_id: "" },
      //   }));
    }
  }, [deleteError, deleteSuccess]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setmenu("accomodation"));
  }, []);
  const [existingRoomTypes, setexistingRoomTypes] = useState(
    allaccom ? allaccom?.map((rec) => rec?.accomodation_room_type_name) : [],
  );
  const AccommodationSchema = Yup.object().shape({
    accomodation_room_type_name: Yup.string().required(
      "Room type name is required",
    ),

    accomodation_number_of_rooms: Yup.number().required(
      "Number of rooms is required",
    ),
    sqft: Yup.number().required("SFT is required"),
    accomodation_description: Yup.string().required("Description is required"),
    accomodation_max_capacity_room: Yup.number().when("single_or_group", {
      is: (value) => value === "Group",
      then: () =>
        Yup.number().required(
          "Maximum capacity of each room is required when Group is selected",
        ),
    }),
    area_per_pet_sqft: Yup.number()
      .nullable()
      .when("single_or_group", {
        is: (value) => value === "Group",
        then: () =>
          Yup.number().required(
            "SFT per Pet is required when Group is selected",
          ),
      }),
    accomodation_amenities_list: Yup.string().required(
      "Amenities List is required",
    ),

    disable_booking_on_inventory_full: Yup.boolean().default(false),
    outdoor_or_indoor: Yup.string().required("Please select Indoor or Outdoor"),
    ac_or_nonAc: Yup.string().required("Please select AC or Non-AC"),
    single_or_group: Yup.string().required("Please select Single or Group"),
    special_medical_care: Yup.string().required(
      "Please select Special, Medical, or Standard",
    ),
  });
  const {
    setValues,
    values,
    handleBlur,
    setFieldValue,
    isError: formikerror,
    touched,
    errors,
    ...formik
  } = useFormik({
    initialValues: {
      accomodation_room_type_name: "",
      accomodation_number_of_rooms: 0,
      accomodation_max_capacity_room: 0,
      accomodation_price: "",
      disable_booking_on_inventory_full: false,
      service_accomodation_photos: [],
      sqft: "",
      area_per_pet_sqft: "",
      accomodation_amenities_list: "",
      accomodation_description: "",
      outdoor_or_indoor: "Indoor",
      ac_or_nonAc: "AC",
      single_or_group: "Single",
      special_medical_care: "Standard",
    },
    validationSchema: AccommodationSchema,

    onSubmit: (values) => {
      // Handle form submission here
      if (id == "new")
        createaccom({
          ...values,
          accomodationPhotos: values?.service_accomodation_photos?.map(
            (rec) => ({ file_id: rec?.id }),
          ),
        }).then((resp) => {
          toast.success("created Successfull!");
          navigate(-1);
        });
      if (stepform) {
        if (existingRoomTypes?.includes(values?.accomodation_room_type_name)) {
          toast.error("room type already exist!");
          return;
        }
        formsubmit(values);
        toast.success("saved!");
      } else
        updateaccom({
          id,
          data: {
            ...values,
            accomodationPhotos: values.service_accomodation_photos.map(
              (rec) => {
                if (!rec?.file) return { file_id: rec?.id };
                else return rec;
              },
            ),
          },
        }).then((resp) => toast.success("updated Successfull!"));
    },
  });
  useEffect(() => {
    if (single?.data) {
      console.log(single?.data, "singledata");

      setValues(single?.data);
      setexistingRoomTypes(single?.data);
    }
    if (stepform) {
      setValues({
        ...stepform,
        special_medical_standart: stepform.special_medical_care,
        service_accomodation_photos: stepform.accomodationPhotos,
      });
    }
  }, [single, stepform]);
  const handleFileSelect = async (event) => {
    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    await uploadFiles(formData);
  };
  useEffect(() => {
    if (single) {
      Object.entries(errors).forEach(([key, val]) => {
        toast.error(val);
      });
    }
  }, [errors, formikerror]);

  const [confirm, setconfirm] = useState(false);
  useEffect(() => {
    if (values?.sqft && values?.accomodation_max_capacity_room) {
      setFieldValue(
        "area_per_pet_sqft",
        parseInt(values?.sqft / values?.accomodation_max_capacity_room),
      );
    }
  }, [values]);
  return (
    <form className="flex flex-col w-full h-full justify-start items-start gap-7">
      <Confirm show={confirm} width={40} onClose={() => setconfirm(false)}>
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm(false)}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={deleteaccomm}
              className="bg-orange-500 text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <div className="flex flex-col justify-start items-start gap-6">
        <div className="text-orange-400 w-full text-lg font-bold flex flex-row gap-3 items-center ">
          {id == "new" ? "Add" : "Edit"} Accommodation Details{" "}
          {deleteaccomm && (
            <MdDeleteOutline
              style={{ color: "red", height: 25, width: 25 }}
              className="cursor-pointer ml-3"
              onClick={() => setconfirm(true)}
            />
          )}
        </div>

        <div className="w-[23%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10 w-full">
          <Input
            id={"accomodation_room_type_name"}
            name="accomodation_room_type_name"
            label={"Room type name"}
            placeholder="Room type name"
            value={values?.accomodation_room_type_name}
            errorText={errors.accomodation_room_type_name}
            isError={
              errors?.accomodation_room_type_name &&
              touched?.accomodation_room_type_name
            }
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("accomodation_room_type_name", e.target.value)
            }
          />

          <Input
            id={"accomodation_number_of_rooms"}
            name="accomodation_number_of_rooms"
            label={"Number of Rooms"}
            placeholder="Enter number of rooms"
            value={values?.accomodation_number_of_rooms}
            errorText={errors.accomodation_number_of_rooms}
            isError={
              errors?.accomodation_number_of_rooms &&
              touched?.accomodation_number_of_rooms
            }
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("accomodation_number_of_rooms", e.target.value)
            }
          />

          {values?.single_or_group == "Group" && (
            <Input
              id={"accomodation_max_capacity_room"}
              name="accomodation_max_capacity_room"
              label={"Maximum capacity of each room"}
              placeholder="Enter maximum capacity of each room"
              value={values?.accomodation_max_capacity_room}
              errorText={errors.accomodation_max_capacity_room}
              isError={
                errors?.accomodation_max_capacity_room &&
                touched?.accomodation_max_capacity_room
              }
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue("accomodation_max_capacity_room", e.target.value)
              }
            />
          )}

          <Input
            id={"sqft"}
            name="sqft"
            label={"SFT"}
            placeholder="Enter SFT"
            type="number"
            value={values?.sqft}
            errorText={errors.sqft}
            isError={errors?.sqft && touched?.sqft}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue("sqft", e.target.value)}
          />

          {values?.single_or_group == "Group" && (
            <Input
              id={"area_per_pet_sqft"}
              name="area_per_pet_sqft"
              label={"SFT per Pet (If Group)"}
              placeholder="Enter SFT per Pet (If Group)"
              value={values?.area_per_pet_sqft}
              errorText={errors.area_per_pet_sqft}
              isError={errors?.area_per_pet_sqft && touched?.area_per_pet_sqft}
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue("area_per_pet_sqft", e.target.value)
              }
            />
          )}

          <Input
            id={"accomodation_amenities_list"}
            name="accomodation_amenities_list"
            label={"Amenities List"}
            placeholder="Enter Amenities List"
            value={values?.accomodation_amenities_list}
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("accomodation_amenities_list", e.target.value)
            }
          />

          <div className="flex flex-col space-x-2 space-y-2">
            {isLoading && "Uploading..."}
            {deleteLoading && "Deleting..."}
            <FileUploadField
              multiple={true}
              label="Center photo"
              placeholder="No file selected"
              handleChange={(e) => {
                handleFileSelect(e);
              }}
            />
            <div className="flex flex-wrap w-full">
              {values?.service_accomodation_photos &&
                values?.service_accomodation_photos?.map((rec) => (
                  <UploadedFilePlaceholder
                    name={rec?.file?.key || rec?.key}
                    link={rec?.file?.url || rec?.url}
                    onRemove={() => {
                      deleteFile(
                        new URLSearchParams({
                          id: rec?.id,
                        }),
                      );
                      setFieldValue(
                        "service_accomodation_photos",
                        values.service_accomodation_photos.filter(
                          (myrec) => myrec.id != rec?.id,
                        ),
                      );
                    }}
                  />
                ))}
            </div>
          </div>

          <Input
            id={"accomodations.accomodation_description"}
            name="accomodations.accomodation_description"
            label={"Description"}
            placeholder="Enter Description"
            value={values?.accomodation_description}
            errorText={errors.accomodation_description}
            isError={
              errors?.accomodation_description &&
              touched?.accomodation_description
            }
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("accomodation_description", e.target.value)
            }
          />

          <div className="flex items-center gap-3 col-span-1 lg:col-span-2">
            <div className="text-gray-700 text-md font-bold  leading-relaxed">
              Disable Booking on Inventory Full
            </div>
            <ToggleSwitch
              handleChange={(val) =>
                setFieldValue("disable_booking_on_inventory_full", val)
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-start gap-5 lg:gap-7">
        <div className="text-orange-400 text-lg font-bold ">
          Select The Room Type
        </div>
        <div className="w-[28%] border-b-[1.5px] border-orange-500 opacity-75 -mt-2 "></div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 md:gap-5">
          <CustomToggle
            label1="Indoor"
            label2="Outdoor"
            errorText={errors.outdoor_or_indoor}
            isError={errors?.outdoor_or_indoor}
            value={values?.outdoor_or_indoor}
            handleChange={(val) => setFieldValue("outdoor_or_indoor", val)}
          />
          <CustomToggle
            label1="AC"
            label2="Non AC"
            errorText={errors.ac_or_nonAc}
            isError={errors?.ac_or_nonAc}
            value={values?.ac_or_nonAc}
            handleChange={(val) => setFieldValue("ac_or_nonAc", val)}
          />
          <CustomToggle
            label1="Single"
            label2="Group"
            errorText={errors.single_or_group}
            isError={errors?.single_or_group}
            value={values?.single_or_group}
            handleChange={(val) => {
              setFieldValue("single_or_group", val);
            }}
          />
          <CustomToggle
            errorText={errors.special_medical_care}
            isError={errors?.special_medical_care}
            onBlur={handleBlur}
            label1="Special"
            label2="Standard"
            label3="Medical"
            value={values?.special_medical_care}
            handleChange={(val) => setFieldValue("special_medical_care", val)}
          />
        </div>
      </div>

      <div className="w-full flex items-end justify-end">
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          className="mt-5 bg-orange-400 p-3 rounded-lg text-white "
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
}
function AccommodationEdit() {
  return (
    <Container>
      <div className="flex flex-col w-full justify-start items-start gap-5 p-[3rem]  bg-white">
        <AccommodationEditForm />
      </div>
    </Container>
  );
}
export { AccommodationEdit, AccommodationEditForm };
