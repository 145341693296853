import React from "react";

const SingleSelect = ({
  className,
  label,
  name,
  id,
  onChange,
  isError,
  onBlur,
  errorText,
  options,
  value,
  showLabelInOption = true,
  showLabel = true,
  ...props
}) => {
  return (
    <div>
      {showLabel && (
        <label
          for="countries"
          class="font-poppins text-base font-semibold text-[#1C2135]"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        className={`w-full h-10 px-2 text-sm py-1 mt-2 border rounded-lg focus:outline-none bg-[#F8F8F8] ${className} border-[#F8F8F8] focus:border-[#F8F8F8] disabled:bg-gray-200 disabled:cursor-not-allowed font-medium disabled:opacity-50 disabled:border-gray-300 `}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      >
        {showLabelInOption && <option selected value={null} >
          {label}
        </option>}
        {options?.map((option, index) => (
          <option value={option?.value}>{option?.label}</option>
        ))}
      </select>
      {isError && (
        <p className="ml-1 font-normal text-sm text-red-500">{errorText} </p>
      )}
    </div>
  );
};

export default SingleSelect;
