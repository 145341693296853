import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

function RoleProtectedRoutes({ allowedRoles }) {


    const user = JSON.parse(localStorage.getItem('boop_service_user'));
    console.log({
        allowedRoles,
        role: user?.user?.role
    })
    return allowedRoles.includes(user?.user?.role) ? (
        <Outlet />
    ) : (
        <Navigate to="/unauthorized" />
    );
}

export default RoleProtectedRoutes